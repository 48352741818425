import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { PondManagerServices } from "api/pondManagerServices";
import { useDispatch } from "react-redux";
import { ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import _ from "lodash";
import { dialogReducerActions } from "redux/dialog";
import currencies from "json/currencies.json";
import Text, { getText } from "components/text/Text";
import { GOOGLE_MAP_KEY } from "secrets";
import GoogleMapReact from "google-map-react";
import { SearchIcon } from "components/Icons/MaterialIcons";
import palette from "themes/palette";
import { ShrimplIconOnlySvg } from "components/Images";
import { useNavigate } from "react-router-dom";

const formValidationSchema = Yup.object().shape({
  label: Yup.string().required("Required"),
  settings: Yup.object({
    currency: Yup.string().required("You need to set up your local currency."),
  }),
});

const optionsForCurrencies = _.values(currencies).map((ele) => ({
  label: `${ele.code} - ${ele.name} (${ele.symbol_native})`,
  value: ele.code,
}));

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.HYBRID,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const FarmMarker = () => {
  return (
    <Box
      sx={{
        color: palette.secondary.main,
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        bgcolor: palette.secondary.light,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: "0.9",
      }}
    >
      <img src={ShrimplIconOnlySvg} alt="" width={25} />
    </Box>
  );
};

const FarmProfileForm = ({ farm }) => {
  const isNewFarm = !farm;

  const [mapCenter, setMapCenter] = useState(isNewFarm ? [27.1808, -110.2909] : [farm?.lat, farm?.lon]);
  const [mapZoom, setMapZoom] = useState(15);
  const [farmCenter, setFarmCenter] = useState(mapCenter);
  const [loading, setLoading] = useState(false);

  const mapRef = useRef(null);
  const googleMapSearchBoxRef = useRef(null);
  const latInputRef = useRef(null);
  const lngInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (mapRef?.current?.map_) {
      setTimeout(() => {
        mapRef.current.map_.panTo(new mapRef.current.maps_.LatLng(farmCenter[0], farmCenter[1]));
      }, 100);
    }
  }, [farmCenter]);

  const Actions = {
    onLatLngInputChange: (newPosition) => {
      setFarmCenter(newPosition);
    },
    onSubmitForm: async (values) => {
      if (isNewFarm) {
        // create a new farm
        const formData = {
          label: values.label,
          lon: farmCenter[1],
          lat: farmCenter[0],
          contact_name: values.contact_name,
          contact_number: values.contact_number,
          contact_email: values.contact_email,
          settings: values.settings || {},
        };
        setLoading(true);
        const rsp = await PondManagerServices.createFarm(formData);
        if (rsp?.payload?.status === "ok") {
          dialogReducerActions.openDialog(dispatch, {
            content: "Farm is created!",
          });
          navigate(ROUTE_POND_MANAGER_HOME_PAGE);
        } else {
          setLoading(false);
        }
      } else {
        // update farm
        const formData = {
          farm_id: farm.farm_id,
          label: values.label,
          lon: farmCenter[1],
          lat: farmCenter[0],
          contact_name: values.contact_name,
          contact_number: values.contact_number,
          contact_email: values.contact_email,
          settings: values.settings || {},
        };
        setLoading(true);
        const rsp = await PondManagerServices.updateFarm(formData);
        if (rsp?.payload?.status === "ok") {
          dialogReducerActions.openDialog(dispatch, {
            content: "Farm details have been updated!",
          });
          navigate(ROUTE_POND_MANAGER_HOME_PAGE);
        } else {
          setLoading(false);
        }
      }
    },
  };

  const handleApiLoaded = async (map, maps) => {
    const placesLib = await maps.importLibrary("places");
    let searchBox = new placesLib.SearchBox(googleMapSearchBoxRef.current);
    let markers = [];
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }
      const place = _.first(places);
      if (place) {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        setFarmCenter([place.geometry.location.lat(), place.geometry.location.lng()]);
      }
    });

    maps.event.addListener(map, "click", function (e) {
      setFarmCenter([e.latLng.lat(), e.latLng.lng()]);
    });
  };

  return (
    <Box>
      <Formik
        initialValues={
          farm || {
            label: "",
          }
        }
        validationSchema={formValidationSchema}
        onSubmit={Actions.onSubmitForm}
      >
        {({ values, errors, handleChange, setFieldValue, setErrors, ...props }) => {
          return (
            <Form>
              <Box my={0}>
                <Typography variant="sectionTitle">{isNewFarm ? <Text>interface.actions.create-a-new-farm</Text> : <Text>interface.actions.update</Text>}</Typography>
              </Box>
              <Stack spacing={3} py={2}>
                <Box>
                  <TextField id="label" label={<Text>interface.general.farm-label</Text>} onChange={handleChange} value={values.label} size="large" fullWidth></TextField>
                  {errors.label && (
                    <Box my={1}>
                      <Alert color="error">{errors.label}</Alert>
                    </Box>
                  )}
                </Box>

                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField name="contact_name" label={<Text>interface.general.contact-name</Text>} value={values.contact_name} onChange={handleChange} fullWidth></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField name="contact_email" label={<Text>interface.general.contact-email</Text>} type="email" value={values.contact_email} onChange={handleChange} fullWidth></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField name="contact_number" label={<Text>interface.general.contact-number</Text>} value={values.contact_number} onChange={handleChange} fullWidth></TextField>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box>
                    <Typography variant="body1">
                      <Text>description.map-farm-edit-description</Text>
                    </Typography>
                  </Box>

                  <Grid container spacing={1} my={1}>
                    <Grid item xs={4}>
                      <TextField type="number" inputRef={latInputRef} defaultValue={farmCenter[0]} fullWidth label="Lat" size="small"></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField type="number" inputRef={lngInputRef} defaultValue={farmCenter[1]} fullWidth label="Lon" size="small"></TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => {
                          if (latInputRef.current.value >= -90 && latInputRef.current.value <= 90 && lngInputRef.current.value >= -180 && lngInputRef.current.value <= 180) {
                            Actions.onLatLngInputChange([latInputRef.current.value, lngInputRef.current.value]);
                          } else {
                            window.alert("Incorrect Geolocation");
                          }
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      {/* <input type="text" ref={googleMapSearchBoxRef} /> */}
                      <TextField inputRef={googleMapSearchBoxRef} fullWidth label={getText("interface.actions.search", "Search")} size="small"></TextField>
                    </Grid>
                  </Grid>

                  <Box borderRadius={1} sx={{ height: 500, width: "100%", overflow: "hidden" }}>
                    <GoogleMapReact
                      ref={mapRef}
                      bootstrapURLKeys={{
                        key: GOOGLE_MAP_KEY,
                        libraries: "drawing",
                      }}
                      center={mapCenter}
                      defaultZoom={mapZoom}
                      options={createMapOptions}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                      <FarmMarker lat={farmCenter[0]} lng={farmCenter[1]} />
                    </GoogleMapReact>
                  </Box>

                  <Box mt={1}>
                    <Stack spacing={1} direction="row">
                      <Chip label={`(${farmCenter[0]}, ${farmCenter[1]})`}></Chip>
                    </Stack>
                  </Box>
                </Box>

                {/* :: Currency Setting :: */}
                <Box>
                  <Autocomplete
                    disablePortal
                    id="currency"
                    value={values.settings?.currency}
                    onChange={(e, newVal) => {
                      if (newVal) {
                        setFieldValue("settings.currency", newVal?.value);
                      }
                    }}
                    options={optionsForCurrencies}
                    renderInput={(params) => <TextField {...params} label={<Text>interface.general.local-currency</Text>} />}
                  />
                  {errors?.settings?.currency && (
                    <Box my={1}>
                      <Alert color="error">{errors?.settings?.currency}</Alert>
                    </Box>
                  )}
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="number"
                    label={<Text suffix={" (days)"}>interface.general.forecast-range</Text>}
                    value={values.settings?.["forecast_range"]}
                    onChange={(e) => setFieldValue("settings.forecast_range", e.target.value ? Number(e.target.value) : null)}
                    defaultValue={240}
                  />
                  {errors?.settings?.currency && (
                    <Box my={1}>
                      <Alert color="error">{errors?.settings?.currency}</Alert>
                    </Box>
                  )}
                </Box>

                <Box my={2}>
                  <Stack direction={"row"} alignItems="center" spacing={1}>
                    <Button variant="contained" type="submit" size="large">
                      <Text>interface.actions.submit</Text>
                    </Button>
                    {loading && <CircularProgress size={20} />}
                  </Stack>
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default FarmProfileForm;
