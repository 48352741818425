import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import LoadingBox from "components/Loading/LoadingBox";
import ColorIconSelect from "components/Select/ColorIconSelect";
import Text, { getText } from "components/text/Text";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AlertColorDefault, AlertColorOptions, defaultAlertRules } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import WidgetCard from "ui/Card/WidgetCard";

const FarmAlertRuleControl = ({ title, field, value, handleChange }) => {
  const Actions = {
    onHandleMinChange: (e) => {
      const value = Number(e.target.value);
      handleChange({ field: field, field_item: "min", value });
    },
    onHanldeMaxChange: (e) => {
      const value = Number(e.target.value);
      handleChange({ field: field, field_item: "max", value });
    },
    onHandleLowColorChange: (e) => {
      const value = e.target.value;
      handleChange({ field: field, field_item: "lowColor", value });
    },
    onHandleMidColorChange: (e) => {
      const value = e.target.value;
      handleChange({ field: field, field_item: "midColor", value });
    },
    onHandleHighColorChange: (e) => {
      const value = e.target.value;
      handleChange({ field: field, field_item: "highColor", value });
    },
    onNormalWhenChange: (e) => {
      const value = e.target.value;
      handleChange({ field: field, field_item: "normalWhen", value });
    },
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <WidgetCard title={title}>
        <Stack alignItems={"center"}>
          <Stack direction={"row"} spacing={2}>
            <TextField type="number" name={`${field}.min`} variant="standard" label="Low" onChange={Actions.onHandleMinChange} value={value?.min} />
            <TextField type="number" name={`${field}.max`} variant="standard" label="High" onChange={Actions.onHanldeMaxChange} value={value?.max} />
          </Stack>
          <Stack direction={"row"} spacing={3} mt={1} fullWidth>
            <ColorIconSelect name="lowColor" label="< LOW" value={value?.lowColor || AlertColorDefault.lowColor} options={AlertColorOptions} onChange={Actions.onHandleLowColorChange} />
            <ColorIconSelect name="midColor" label="MID" value={value?.midColor || AlertColorDefault.midColor} options={AlertColorOptions} onChange={Actions.onHandleMidColorChange} />
            <ColorIconSelect name="highColor" label="> HIGH" value={value?.highColor || AlertColorDefault.highColor} options={AlertColorOptions} onChange={Actions.onHandleHighColorChange} />
            <Box>
              <FormControl sx={{ minWidth: 150 }} variant="standard">
                <InputLabel id="normal_at_l">Normal When</InputLabel>
                <Select size="small" name={`${field}.normalAt`} label="Normal When" value={value?.normalWhen} onChange={Actions.onNormalWhenChange}>
                  <MenuItem value={-1}>{"< LOW"}</MenuItem>
                  <MenuItem value={0}>LOW to HIGH</MenuItem>
                  <MenuItem value={1}>{"> HIGH"}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>
      </WidgetCard>
    </Grid>
  );
};

const FarmAlertsRulesForm = ({ farm, postSubmit }) => {
  const [rules, setRules] = useState({});

  useEffect(() => {
    const rules = farm?.settings?.alerts || defaultAlertRules;
    setRules(rules);
  }, [farm]);

  const Actions = {
    onFormSubmit: async (rules) => {
      await PondManagerServices.updateFarmAlertRules(farm.farm_id, rules);
      postSubmit && postSubmit();
    },
  };

  const formik = useFormik({
    initialValues: rules,
    onSubmit: (values) => {
      Actions.onFormSubmit(values);
    },
    enableReinitialize: true,
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const handleControlChange = ({ field, field_item, value }) => {
    const originalFieldValue = values?.[field];
    const newFieldVlaue = {
      ...originalFieldValue,
      [field_item]: value,
    };
    setFieldValue(field, newFieldVlaue);
  };

  if (_.isEmpty(values)) return <LoadingBox />;

  return (
    <Box>
      <Typography variant="sectionTitle">
        <Text>interface.general.alert-rules</Text>
      </Typography>
      <Box my={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <FarmAlertRuleControl handleChange={handleControlChange} field={"alkalinity"} title={getText("fields.alkalinity")} value={values.alkalinity} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"ammonia"} title={getText("fields.ammonia")} value={values.ammonia} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"dissolved_oxygen"} title={getText("fields.dissolved_oxygen")} value={values.dissolved_oxygen} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"nitrate"} title={getText("fields.nitrate")} value={values.nitrate} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"nitrite"} title={getText("fields.nitrite")} value={values.nitrite} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"ph"} title={getText("fields.ph")} value={values.ph} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"salinity"} title={getText("fields.salinity")} value={values.salinity} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"survival_rate"} title={getText("fields.survival_rate")} value={values.survival_rate} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"turbidity"} title={getText("fields.turbidity")} value={values.turbidity} />
            <FarmAlertRuleControl handleChange={handleControlChange} field={"water_temp"} title={getText("fields.water_temp")} value={values.water_temp} />
            <Grid item xs={12}>
              <Button size="large" type="submit" variant="contained">
                {getText("interface.actions.confirm")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default FarmAlertsRulesForm;
