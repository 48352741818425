import { Box } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import React, { useState } from "react";

const dashboardIndex = {
  // El Caracol
  "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a": {
    biomass: "https://www.arcgis.com/apps/dashboards/d377db77aee64656b88f4cb535f17774",
    do: "https://www.arcgis.com/apps/dashboards/636b7f05e87241bfb082b1662bea689a",
    temp: "https://www.arcgis.com/apps/dashboards/64eb48260967447bb7b5f6be86fbf446",
  },
  // VN2401 Pilot - GBVN
  "a1468cbc-4ae7-435f-acfd-29d12daa241a": {
    biomass: "https://www.arcgis.com/apps/dashboards/42eeb5edfe2949d98369cf60b5063691",
    do: "https://www.arcgis.com/apps/dashboards/ad698c12157047f0a3c0b589fc04863e",
    temp: "https://www.arcgis.com/apps/dashboards/3b2f9bcdcd0549558b5fe6c7fbc5ab59",
  },
  // Oman Farm #1
  "91b6d486-43aa-4eca-8e91-f10631f9ce35": {
    biomass: "https://www.arcgis.com/apps/dashboards/aabb85313e554a4f828396c5e5809c66",
    do: "https://www.arcgis.com/apps/dashboards/c18ec1e8cf1f45efb6bc9359afdf5dd0",
    temp: "https://www.arcgis.com/apps/dashboards/7e4e2ba31fdc41dbaab14ad10ee02906",
  },
  // Darren Lee
  "f98ccece-d81f-4607-8ceb-f17c4fae64a4": {
    // biomass: "https://www.arcgis.com/apps/dashboards/aabb85313e554a4f828396c5e5809c66",
    do: "https://www.arcgis.com/apps/dashboards/8053501a0cf447aa8e737043062fed3c",
    temp: "https://www.arcgis.com/apps/dashboards/0d81c085836348ea9701a2604a310a12",
  },
};

const ArcGisDashboard = ({ farm, defaultView = "biomass" }) => {
  const [view, setView] = useState(defaultView);

  const dashboardReady = dashboardIndex?.[farm.farm_id];
  const biomassDashboardLink = dashboardIndex?.[farm.farm_id]?.biomass;
  const doDashboardLink = dashboardIndex?.[farm.farm_id]?.do;
  const tempDashboardLink = dashboardIndex?.[farm.farm_id]?.temp;

  if (!dashboardReady) {
    return <VerticalAlertBoxCollections.NothingShow />;
  }

  return (
    <Box
      p={0}
      sx={{
        iframe: {
          height: "calc(100vh - 200px)",
          width: "100%",
        },
      }}
    >
      {view === "biomass" && biomassDashboardLink && <iframe src={biomassDashboardLink} frameborder="0"></iframe>}
      {view === "do" && doDashboardLink && <iframe src={doDashboardLink} frameborder="0"></iframe>}
      {view === "temp" && tempDashboardLink && <iframe src={tempDashboardLink} frameborder="0"></iframe>}
    </Box>
  );
};

export default ArcGisDashboard;
