import { Box, Button, IconButton, Stack, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { RefreshIcon } from "components/Icons/MaterialIcons";
import LoadingBox from "components/Loading/LoadingBox";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const valueColor = (val) => {
  if (val > 0.7) return colors.blue[800];
  if (val > 0.5) return colors.blue[600];
  if (val > 0.3) return colors.blue[400];
  if (val > 0.2) return colors.blue[300];
  if (val > 0.2) return colors.blue[200];
  if (val >= 0) return colors.blue[100];
  if (val >= -0.3) return colors.orange[500];
  return colors.red[500];
};

const TheChart = ({ data }) => {
  const chartConfigs = {
    chart: {
      height: 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      max: 1,
      min: -1,
    },
    series: [
      {
        name: "NDWI mean",
        type: "column",
        data: data.map((ele) => ({
          x: ele.date,
          y: ele.mean,
          color: valueColor(ele.mean),
        })),
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };
  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const SeasonalChart = ({ data }) => {
  const grouped = _.groupBy(data, "year");
  const years = _.keys(grouped);

  const chartConfigs = {
    chart: {
      height: 300,
      type: "line",
      marginTop: 40,
      marginBottom: 40,
      plotBorderWidth: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        // don't display the year
        month: "%b",
        year: "%b",
      },
    },
    yAxis: {
      title: null,
      min: -1,
      max: 1,
    },
    series: years.map((ele) => ({
      name: ele,
      data: grouped[ele].map((ele_) => ({
        x: DateTime.fromMillis(ele_.date).set({ year: 1970 }).valueOf(),
        y: ele_.mean,
      })),
    })),
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          fillColor: "#FFF",
          enabled: true,
          radius: 2.5,
          lineWidth: 1,
          lineColor: null,
        },
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: "{point.x:%e. %b}: {point.y:.2f}",
    },
  };
  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const MonthlySeasonalChart = ({ data }) => {
  const grouped = _.mapValues(_.groupBy(data, "year"), (e) => _.mapValues(_.groupBy(e, "month"), (item) => _.meanBy(item, "mean")));
  const years = _.keys(grouped);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const groupedFlattend = _.flatten(_.keys(grouped).map((k, idx) => _.keys(grouped[k]).map((k_, idx_) => [Number.parseInt(k_) - 1, idx, grouped[k][k_]])));

  const chartConfigs = {
    chart: {
      height: 300,
      type: "heatmap",
      marginTop: 50,
      marginBottom: 40,
      plotBorderWidth: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: months,
    },
    yAxis: {
      categories: years,
      title: null,
      reversed: true,
      gridLineWidth: 0,
    },
    colorAxis: {
      min: 0,
      max: 1,
      minColor: "#FFF",
      maxColor: colors.blue[700],
    },
    series: [
      {
        name: "Mean",
        data: "NDWI mean",
        borderWidth: 0,
        borderColor: "#EEE",
        data: groupedFlattend,
      },
    ],
    plotOptions: {
      series: {
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
  };
  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const WeeklySeasonalChart = ({ data }) => {
  const grouped = _.mapValues(_.groupBy(data, "year"), (e) => _.mapValues(_.groupBy(e, "week"), (item) => _.meanBy(item, "mean")));
  const years = _.keys(grouped);
  const weeks = [...Array(53)].map((_, idx) => idx + 1);
  const groupedFlattend = _.flatten(_.keys(grouped).map((k, idx) => _.keys(grouped[k]).map((k_, idx_) => [Number.parseInt(k_) - 1, idx, grouped[k][k_]])));

  const chartConfigs = {
    chart: {
      height: 300,
      type: "heatmap",
      marginTop: 50,
      marginBottom: 40,
      plotBorderWidth: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: weeks,
    },
    yAxis: {
      categories: years,
      title: null,
      reversed: true,
      gridLineWidth: 0,
    },
    colorAxis: {
      min: 0,
      max: 1,
      minColor: "#FFF",
      maxColor: colors.blue[700],
    },
    series: [
      {
        name: "Mean",
        data: "NDWI mean",
        borderWidth: 0,
        borderColor: "#EEE",
        data: groupedFlattend,
      },
    ],
    plotOptions: {
      series: {
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
  };
  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
  return "";
};

const TheTable = ({ data }) => {
  const colDef = [
    {
      field: "date",
      headerName: "Timestamp",
      valueFormatter: ({ value }) => DateTime.fromMillis(value).toFormat("yyyy-MM-dd"),
    },
    {
      field: "mean",
      headerName: "Avg",
      cellRenderer: NumericFormatters.format,
    },
    {
      field: "mean",
      cellRenderer: ({ value }) => {
        if (value > 0) {
          return (
            <Box
              sx={{
                width: `${100 * value}%`,
                height: 15,
                bgcolor: valueColor(value),
                borderRadius: 0.3,
              }}
            ></Box>
          );
        }
        return "";
      },
    },
  ];
  const rowData = _.orderBy(data, "date", "desc");
  return (
    <Box
      className="ag-theme-clean"
      sx={{
        width: "100%",
        height: "300px",
      }}
    >
      <AgGridReact
        defaultColDef={{
          cellStyle: { display: "flex", alignItems: "center" },
          flex: 1,
        }}
        rowData={rowData}
        columnDefs={colDef}
        animateRows={false}
        headerHeight={20}
        rowHeight={20}
      />
    </Box>
  );
};

const NDWIView = ({ feature }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  // useEffect(() => {
  //   setData(null);
  // }, [feature]);

  const Actions = {
    loadData: async () => {
      if (feature) {
        setLoading(true);
        const result = await PondManagerServices.fetcheNDWIInsights({ feature });
        setData(result);
        setLoading(false);
      }
    },
  };

  if (loading) {
    return <LoadingBox />;
  }

  if (!data) {
    return (
      <Box>
        <Stack direction={"row"} justifyContent={"center"}>
          <Button
            variant="contained"
            size="large"
            sx={{
              p: 2,
            }}
            onClick={Actions.loadData}
          >
            Get NDWI Insights
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <MuiTabs
          value={pageIndex}
          theme={MuiTabsThemes.chip}
          options={[
            { label: "Historical", value: 0 },
            { label: "Seasonal", value: 1 },
            { label: "Seasonal (Monthly)", value: 2 },
            { label: "Seasonal (Weekly)", value: 3 },
            { label: "Table", value: 4 },
          ]}
          onNewValue={setPageIndex}
        />
        <MuiBorderIconButton icon={<RefreshIcon />} onClick={Actions.loadData} />
      </Stack>
      <Box>{pageIndex === 0 && <TheChart data={data} />}</Box>
      <Box>{pageIndex === 1 && <SeasonalChart data={data} />}</Box>
      <Box>{pageIndex === 2 && <MonthlySeasonalChart data={data} />}</Box>
      <Box>{pageIndex === 3 && <WeeklySeasonalChart data={data} />}</Box>
      <Box>{pageIndex === 4 && <TheTable data={data} />}</Box>
    </Box>
  );
};

export default NDWIView;
