import { ApiDelete, ApiGet, ApiPost, ApiPostForm, ApiPut, ApiPutForm } from "api/apiUtils";
import axios, { Axios } from "axios";
import _ from "lodash";

export const PondManagerServices = {

  dashboard: {
    getPondBiologicalDashboardData: ({ pond }) => ApiGet(`/aqua/dashboard/pond/biological?farm_id=${pond?.farm_id}&pond_id=${pond?.id}`).then(res => res.data.payload).catch(error => error.response.data),
  },

  /** =========== O R G A N I S A T I O N S =========== */

  // get user's organisation list as Owner
  getOrgs: () => ApiGet("/aqua/orgs").then((res) => res.data.payload),

  // create an organisation
  createOrg: (payload) => ApiPost("/aqua/orgs", payload).then(res => res.data),
  
  // get member list in the organisation
  getMembers: (orgId) => ApiGet("/aqua/org-members/" + orgId).then(res => res.data.payload),

  // add a new member into the organisation
  addMember: (orgId, email) => ApiPost("/aqua/org-members/" + orgId, { email }).then(res => res.data.payload).catch(error => error.response.data),

  // delete a member from the organisation
  deleteMember: (orgId, memberId) => ApiDelete("/aqua/org-members/" + orgId + "?member_id=" + memberId).then(res => res.data.payload).catch(error => error.response.data),
  
  // get list of groups in the organisation
  getGroups: (orgId) => ApiGet("/aqua/org-groups/" + orgId).then(res => res.data.payload).catch(error => error.response.data),

  // create a new group in the organisation
  addGroup: (orgId, group) => ApiPost("/aqua/org-groups/" + orgId, group).then(res => res.data.payload).catch(error => error.response.data),

  // update group details
  updateOrgGroup: (orgId, group) => ApiPost("/aqua/org-groups/" + orgId, group).then(res => res.data.payload).catch(error => error.response.data),

  // remove a group
  removeOrgGroup: (orgId, groupId) => ApiDelete("/aqua/org-groups/" + orgId + "?group_id=" + groupId).then(res => res.data.payload).catch(error => error.response.data),
  
  // fetch members in the group
  getGroupMembers: (orgId, groupId) => ApiGet(`/aqua/org-group-members/${orgId}?group_id=${groupId}`).then(res => res.data.payload).catch(error => error.response.data),

  // add member in the group
  addGroupMember: (orgId, groupId, memberId) => ApiPost(`/aqua/org-group-members/${orgId}`, {  "group_id": groupId, "member_id": memberId }).then(res => res.data.payload).catch(error => error.response.data),

  // 
  removeGroupMember: (orgId, groupId, memberId) => ApiDelete(`/aqua/org-group-members/${orgId}?group_id=${groupId}&member_id=${memberId}`).then(res => res.data.payload).catch(error => error.response.data),

  getOrgFarms: (orgId) => ApiGet(`/aqua/org-farms/${orgId}`).then(res => res.data.payload).catch(error => error.response.data),
  confirmOrgFarm: (orgId, farmId) => ApiPost(`/aqua/org-farms/${orgId}`, { action: "confirm-farm", farm_id: farmId }).then(res => res.data.payload).catch(error => error.response.data),
  removeOrgFarm: (orgId, farmId) => ApiDelete(`/aqua/org-farms/${orgId}?farm_id=${farmId}`).then(res => res.data.payload).catch(error => error.response.data),

  // ~~~~~ By Org Owner ~~~~~
  getUserOrgs: () => ApiGet(`/aqua/org-user?target=orgs`).then(res => res.data.payload).catch(error => error.response.data),
  getUserOrgFarms: (orgId) => ApiGet(`/aqua/org-user?target=farms&orgId=${orgId}`).then(res => res.data.payload).catch(error => error.response.data),
  acceptOrgInvite: (orgId) => ApiPost(`/aqua/org-user`, { action: "accept", orgId: orgId }).then(res => res.data.payload).catch(error => error.response.data),
  rejectOrgInvite: (orgId) => ApiPost(`/aqua/org-user`, { action: "reject", orgId: orgId }).then(res => res.data.payload).catch(error => error.response.data),

  // ~~~~~ Public ~~~~~~
  getPublicOrgProfile: (orgId) => ApiGet("/public/org/"+orgId).then(res => res.data.payload).catch(error => error.response.data),

  // ~~~~~ OrgConnect ~~~~~
  searchOrgs: ({ keyword }) => ApiGet(`/aqua/org-connect/search?keyword=${keyword}`).then(res => res.data.payload).catch(error => error.response.data),
  getConnectedOrgs: ({ farmid }) => ApiGet(`/aqua/org-connect/orgs?farmid=${farmid}`).then(res => res.data.payload).catch(error => error.response.data),
  sendConnectionRequestToOrg: ({ orgid, farmid }) => ApiPost(`/aqua/org-connect/orgs`, { farmid: farmid, orgid: orgid }).then(res => res.data.payload).catch(error => error.response.data),
  removeConnectionToOrg: ({ orgid, farmid }) => ApiDelete(`/aqua/org-connect/orgs?farmid=${farmid}&orgid=${orgid}`).then(res => res.data.payload).catch(error => error.response.data),
  acceptConnectionToOrg: ({ orgid, farmid }) => ApiPost(`/aqua/org-connect/orgs-accept`, { farmid: farmid, orgid: orgid }).then(res => res.data.payload).catch(error => error.response.data),

  /** =========== F A R M S =========== */

  getFarms: () => ApiGet("/aqua/farms").then((res) => res.data.payload.rows),
  createFarm: (payload) => ApiPost("/aqua/farms", payload).then((res) => res.data),
  updateFarm: (payload) => ApiPut("/aqua/farms", payload).then((res) => res.data),
  deleteFarm: (farmId) => ApiDelete(`/aqua/farms/${farmId}`).then((res) => res.data),
  
  getFarmAccessControl: (farmId) => ApiGet(`/aqua/farm-access/${farmId}`).then(res => res.data.payload),
  createNewUserFarmAccess: (email, farmId, accessLevelId) => ApiPost(`/aqua/farm-access/${farmId}`, { email, accessLevelId }).then((res) => res.data.payload).catch(error => error.response.data),
  updateUserFarmAccess: (userId, farmId, accessLevelId) => ApiPut(`/aqua/farm-access/${farmId}`, { userId, accessLevelId }).then((res) => res.data.payload),
  deleteUserFarmAccess: (userId, farmId) => ApiDelete(`/aqua/farm-access/${farmId}?userId=${userId}`).then((res) => res.data.payload),

  getFarmImages: (farmId) => ApiGet(`/aqua/farms/${farmId}/images`).then(res => res.data),
  uploadFarmImages: (farmId, payload) => ApiPostForm(`/aqua/farms/${farmId}/images`, payload).then((res) => res.data),
  featureFarmImage: (farmId, image_id) => ApiPut(`/aqua/farms/${farmId}/images`, { "action": "feature", "image_id": image_id }).then((res) => res.data),
  deleteFarmImage: (farmId, image_id) =>  ApiPut(`/aqua/farms/${farmId}/images`, { "action": "delete",  "image_id": image_id }).then((res) => res.data),

  getFarmDocuments: (farmId) => ApiGet(`/aqua/farm-documents/${farmId}`).then(res => res.data),
  downloadFarmDocument: (farmId, docId) => ApiPost(`/aqua/farm-documents/${farmId}`, { "action": "download", "docid": docId }).then(res => res.data),
  createFarmDocument: (farmId, payload) => ApiPost(`/aqua/farm-documents/${farmId}`, payload).then(res => res.data),
  deleteFarmDocument: (farmId, docId) => ApiPost(`/aqua/farm-documents/${farmId}`,  { "action": "delete", "docid": docId }).then(res => res.data),
  updateFarmDocument: (farmId, payload) => ApiPost(`/aqua/farm-documents/${farmId}`,  { "action": "update", ...payload }).then(res => res.data),

  getFarmSettings: (farmId) => ApiGet(`/aqua/farms/${farmId}/settings`).then(res => res.data),
  updateFarmSetting: (farmId, key, value) => ApiPut(`/aqua/farms/${farmId}/settings`, { key, value }).then(res => res.data),
  updateFarmAlertRules: (farmId, rules) => ApiPut(`/aqua/farms/${farmId}/settings`, { key: "alerts", value: rules }).then(res => res.data),


  // farm shrimp unit prices
  getFarmShrimpUnitPrices: (farmId) =>  ApiGet(`/aqua/shrimp-unit-prices?farmid=${farmId}`).then(res => res.data.payload),
  addFarmShrimpUnitPrices: (farmId, prices) =>  ApiPost(`/aqua/shrimp-unit-prices`, { farmId, prices }).then(res => res.data),

  /** =========== F I E L D =========== */

  /** Get list of available fields */
  getFields: () => ApiGet("/aqua/fields").then((res) => res.data.payload),

  /** Get list of fields that are allowed for data upload */
  getUploadEnabledFields: () =>
    ApiGet("/aqua/field/__all_upload_enabled").then((res) => res.data.payload),

  /** =========== P O N D =========== */

  listFarms: () => {
    return ApiGet("/aqua/ponds/_farms").then((res) => res.data.payload.farms);
  },
  /** Get list of ponds */
  listPonds: (farmId) => ApiGet(`/aqua/ponds` + (farmId ? `?farm_id=${farmId}` : "")).then((res) => res.data.list),

  /** Get a pond details by Pond ID */
  getPond: (pondId) => ApiGet(`/aqua/pond/${pondId}`).then((res) => res.data),
  
  /** Get ponds in GEOJSON format */
  getPondsGeoJson: (farmId) => ApiGet("/aqua/ponds/_geojson" + (farmId ? `?farm_id=${farmId}` : "")).then(r => r.data.geojson),
  
  /** Save Pond Details */
  savePond: (data) => ApiPut("/aqua/ponds", data).then((res) => res.data).catch((error) => error.response.data),
  
  /** Save multiple pond details */
  updatePondBatch: ({ pond_ids, ...params }) => ApiPut("/aqua/ponds/batch", { pond_ids, ...params }).then(r => r.data.payload),

  /** Remove a Pond */
  deletePond: (pondId) => ApiDelete(`/aqua/pond/${pondId}`).then((res) => res.data),

  /** =========== D A T A =========== */

  /** Get Data Overview of one pond */
  getDataOverview: (pondId) => ApiGet(`/aqua/data/_overview?pond_id=${pondId}`).then((res) => res.data.payload),
  
  getTimeseriesData: (pondId, fieldIds, from, to) => ApiGet(`/aqua/data/${pondId}?fields=${fieldIds.join(",")}${from  ? "&from=" + from : "" }${to ? "&to=" + to : ""}`).then((res) => res.data.payload),
  
  getTimeseiresDataByCycles: async (pondId, fieldIds, cycles) => {
    const responses = await axios.all(cycles.map((c) => ApiGet(`/aqua/data/${pondId}?fields=${fieldIds.join(",")}&from=${c?.start_date}&to=${c?.end_date}`).catch(error => error)));
    const data = responses.map((rsp, idx) => {
      if (rsp instanceof Error) {
        // Handle failed request
        console.log('Request failed:', rsp.message);
        return null; // Or handle error in another way
      } else {
        // Handle successful request
        return ({
          ...rsp.data.payload,
          cycle: cycles[idx]
        });
      }
    }).filter(ele => ele)

    return data;

    // return axios.all(cycles.map((c) => ApiGet(`/aqua/data/${pondId}?fields=${fieldIds.join(",")}&from=${c?.start_date}&to=${c?.end_date}`).then(
    //   (res) => ({ ...res.data.payload, cycle: c})
    // )))
  },
  /**
   * 
   * Fetch data for the Data Explorer summary
   * 
   * @param {*} param0 
   * @returns 
   */
  getDataexploreSummaryData: async ({ pond, cycle }) => {
    const pondId = pond?.id;
    const from = cycle.start_date;
    const to = cycle.end_date;
    const requiredFields = [
      "weight",
      "current_density",
      "organisms",
      "biomass",
      "survival_rate",
      "harvest_biomass_accumulated",
      "feed_accumulated",
      "fcr_full_cycle"
    ]
    const result = await ApiGet(`/aqua/data/${pondId}?fields=${requiredFields.join(",")}${from  ? "&from=" + from : "" }${to ? "&to=" + to : ""}`).then((res) => res.data.payload);
    const formatted = {
      pond: pond,
      cycle: cycle,
      data: {
        seeded_weight: _.first(result.raw.filter(ele => ele.field_id === "weight")),
        weight: _.last(result.raw.filter(ele => ele.field_id === "weight")),
        seeded_density: _.first(result.raw.filter(ele => ele.field_id === "current_density")),
        current_density: _.last(result.raw.filter(ele => ele.field_id === "current_density")),
        organisms: _.last(result.raw.filter(ele => ele.field_id === "organisms")),
        biomass: _.last(result.raw.filter(ele => ele.field_id === "biomass")),
        survival_rate: _.last(result.raw.filter(ele => ele.field_id === "survival_rate")),
        harvest_biomass_accumulated: _.last(result.raw.filter(ele => ele.field_id === "harvest_biomass_accumulated")),
        feed_accumulated: _.last(result.raw.filter(ele => ele.field_id === "feed_accumulated")),
        fcr_full_cycle: _.last(result.raw.filter(ele => ele.field_id === "fcr_full_cycle")),
      }
    }
    return formatted
  },

  getFarmTimeseriesData: (farmId, fieldIds, from, to) =>     
    ApiGet(`/aqua/farmdata/${farmId}?fields=${fieldIds.join(",")}${from  ? "&from=" + from : "" }${to ? "&to=" + to : ""}&is_farm=1`).then(
    (res) => res.data.payload
  ),
  getDashboardData: (pondId, cycleid) =>
    ApiGet(`/aqua/data/_dashboard?pond_id=${pondId}&cid=${cycleid}`).then(
      (res) => res.data.payload
    ),
  getFarmDashboardData: ({farmId, date, group}) =>
    ApiGet(`/aqua/data/_farm_dashboard?farm_id=${farmId}&date=${date}${group ? `&group=${group}` : ""}`).then(
      (res) => res.data.payload
    ),
  /** get water quality dashboard data */ 
  getPondWaterQualityDashboardData: ({ pondid, cycleid }) => {
    return ApiGet(`/aqua/data/_water_quality_dashboard?pond_id=${pondid}&cid=${cycleid}`).then(res => res.data.payload.data);
  },

  getFarmBulkUploadTemplate: ({ farmid }) => {
    return ApiGet(`/aqua/data/_bulk_upload_template?farm_id=${farmid}`, {
      responseType: 'blob', // Important to handle the binary data
    })
  },

  /** Upload Pond Data */
  putData: (data) => ApiPut("/aqua/data", data).then((res) => res.data),
  /** Upload Farm Data */
  putFarmData: (data) => ApiPut("/aqua/farmdata", data).then((res) => res.data),

  /** =========== C Y C L E S =========== */

  loadAllCyclesByFarmId: (farmId) => ApiGet(`/aqua/cycles?farmId=${farmId}`).then(res => res.data.payload),
  createCycle: (formData) => ApiPost(`/aqua/cycles`, formData).then(res => res.data.payload),
  updateCycle: (formData) => ApiPut(`/aqua/cycles`, formData).then(res => res.data.payload),
  deleteCycle: (pondId, cycleId) => ApiDelete(`/aqua/cycles?pondId=${pondId}&cycleId=${cycleId}`).then(res => res.data.payload),
  deleteCycles: (cycles) => axios.all(
    cycles.map(c => {
      return ApiDelete(`/aqua/cycles?pondId=${c.pond_id}&cycleId=${c.id}`).then(res => res.data.payload)
    })
  ),

  /** =========== F O R E C A S T =========== */

  loadModelForecastData: (
    modelId,
    pondId,
    { dataParameters, modelParameters }
  ) =>
    ApiPost(`/aqua/model/${modelId}?pond_id=${pondId}`, {
      data_params: dataParameters,
      model_params: modelParameters,
    }).then((res) => res.data.payload),
  
  fetchBiologicalParametricModelResult: ({
    pondId,
    dateFrom,
    dateTo
  }) => ApiGet(`/aqua/model/biological-parametric-model?pond_id=${pondId}&from=${dateFrom}&to=${dateTo}`)
  .then(res => res.data.payload)
  .catch(res => res.response.data),

  fetchBiologicalParametricModelAtFarmLevelResult: ({
    farmId,
    date,
    group
  }) => ApiGet(`/aqua/model/biological-parametric-model?farm_id=${farmId}${date ? "&date="+date : ""}${group ? "&group="+group : ""}`)
  .then((res) => res.data.payload)
  .catch(res => {
    return res.response.data
  }),

  // fetch foreacst model (biological parametric model V2) result
  fetchBiologicalParametricModelResultV2: ({
    pondId,
    dateFrom,
    dateTo
  }) => ApiGet(`/aqua/model/biological-parametric-model-v2?pond_id=${pondId}&from=${dateFrom}&to=${dateTo}`)
  .then((res) => res.data.payload)
  .catch(res => {
    return res.response.data
  }),

  // fetch scenario builder result
  fetchScenarioResult: (bulkData) => ApiPost(`/aqua/model/biological-scenario`, { data: bulkData }).then(res => res.data.payload).catch(res => res.response.data),
  
  /** =========== P O C =========== */
  // fetch POC template
  loadPocTemplate: () => ApiGet("/aqua/poc", { responseType: 'blob' }).then(res => res),
  
  /** =========== D I S E A S E S =========== */
  // fetch list of diseases
  fetchDiseases: async () => ApiGet("/aqua/pathology/diseases").then(res => res.data.payload),

  // fetch list of labs
  fetchLabs: async () => ApiGet("/aqua/pathology/labs").then(res => res.data.payload),
  
  // fetch test results
  fetchTestSamples: async ({ pondId, dateFrom, dateTo }) => ApiGet(`/aqua/pathology/data?pond_id=${pondId}&date_from=${dateFrom}&date_to=${dateTo}`).then(res => res.data.payload),
  
  // submit test sample data
  submitTestSample: async (payload) => ApiPost(`/aqua/pathology/data`, payload).then(res => res.data),

  /** =========== I M A G E S =========== */
  
  // fetch images for pond
  fetchPondImages: async ({ pondId, dateFrom, dateTo, label, tag }) => ApiGet(`/aqua/pond/${pondId}/images?from=${dateFrom}&to=${dateTo}&label=${label}&tag=${tag}`).then(r => r.data.payload),

  // upload a pond image
  uploadPondImage: async ({ pondId, formData }) => ApiPostForm(`/aqua/pond/${pondId}/images`, formData).then(r => r.data.payload),

  // update a pond image
  updatePondImage: async ({ pondId, formData }) => ApiPutForm(`/aqua/pond/${pondId}/images`, formData).then(r => r.data.payload),

  // delet a pond image
  deletePondImage: async ({pondId, imageId}) => ApiDelete(`/aqua/pond/${pondId}/images?image_id=${imageId}`).then(r => r.data.payload),

  /** =========== S C E N A R I O   B U I L D E R =========== */

  // --- FARMERS
  // fetch list of farm scenario
  fetchFarmScenarios: async ({ farmid }) => ApiGet(`/aqua/scenarios?farmid=${farmid}`).then(r => r.data.payload),

  // create a new farm scenario
  createFarmScenario: async ({ farmid, form }) => ApiPost("/aqua/scenarios", { farmid, ...form, action: "CREATE_NEW_SCENARIO" }).then(r => r.data.payload),

  // update a farm scenario
  updateFarmScenario: async ({ farmid, form }) => ApiPost("/aqua/scenarios", { farmid, ...form, action: "UPDATE_SCENARIO" }).then(r => r.data.payload),

  // remove a farm scenario
  removeFarmScenario: async ({ farmid, id }) => ApiPost("/aqua/scenarios", { farmid, id, action: "DELETE_SCENARIO" }).then(r => r.data.payload),

  // fetch scenario snapshots
  fetchFarmScenarioSnapshots: async ({ farmid, scenarioid }) => ApiPost("/aqua/scenarios", { farmid, scenarioid, action: "FETCH_SNAPSHOTS" }).then(r => r.data.payload),

  // save a new scenario snapshot
  saveFarmScenarioSnapshot: async ({ farmid, scenarioid, form }) => ApiPost("/aqua/scenarios", { farmid, scenarioid, payload: JSON.stringify(form), action: "SAVE_SNAPSHOT" }).then(r => r.data.payload),

  // delete a farm scenario
  deleteFarmSceanrioSnapshot: async ({ farmid, scenarioid, snapshotid }) => ApiPost("/aqua/scenarios", { farmid, scenarioid, snapshotid, action: "DELETE_SNAPSHOT" }).then(r => r.data.payload),

  /** =========== O R G A N I S A T I O N =========== */

  // fetch list of org scenarios
  fetchOrgScenarios: async ({ farmid, orgid }) => ApiGet(`/aqua/org-scenarios?farmid=${farmid}&orgid=${orgid}`).then(r => r.data.payload),

  // create a new org scenario
  createOrgScenario: async ({ farmid, orgid, form }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, ...form, action: "CREATE_NEW_SCENARIO" }).then(r => r.data.payload),

  // update an org scenario
  updateOrgScenario: async ({ farmid, orgid, form }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, ...form, action: "UPDATE_SCENARIO" }).then(r => r.data.payload),

  // remove an org scenario
  removeOrgScenario: async ({ farmid, orgid, id }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, id, action: "DELETE_SCENARIO" }).then(r => r.data.payload),

  // fetch org scenario snapshots
  fetchOrgScenarioSnapshots: async ({ farmid, orgid, scenarioid }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, scenarioid, action: "FETCH_SNAPSHOTS" }).then(r => r.data.payload),

  // save a new org scenario snapshot
  saveOrgScenarioSnapshot: async ({ farmid,  orgid,scenarioid, form }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, scenarioid, payload: JSON.stringify(form), action: "SAVE_SNAPSHOT" }).then(r => r.data.payload),

  // delete a org scenario
  deleteOrgSceanrioSnapshot: async ({ farmid, orgid, scenarioid, snapshotid }) => ApiPost("/aqua/org-scenarios", { farmid, orgid, scenarioid, snapshotid, action: "DELETE_SNAPSHOT" }).then(r => r.data.payload),
  

  /** =========== FARMING PRODUCTS =========== */

  // find product
  findFarmingProducts: async ({ query, productType }) => ApiGet(`/aqua/farming-products?action=FIND&query=${query}${productType ? `&product_type=${productType}` : ""}`).then(r => r.data.payload),

  // fetch list of registered products
  fetchRegisteredProducts: async ({ farmid }) => ApiGet(`/aqua/farming-products?action=REGISTERED&farmid=${farmid}`).then(r => r.data.payload),

  // register a product
  registerFarmingProducts: async ({ farmid, productid }) => ApiPostForm(`/aqua/farming-products`, { action: "REGISTER_PRODUCT", farmid, productid }).then(r => r.data.payload).catch(e => e.response.data),

  // deregister a product
  deregisterFarmingProducts: async ({ farmid, productid }) => ApiPostForm(`/aqua/farming-products`, { action: "DEREGISTER_PRODUCT", farmid, productid }).then(r => r.data.payload).catch(e => e.response.data),

  // fetch price
  fetchFarmingProductPrice: async ({ farmid, productid }) => ApiGet(`/aqua/farming-products?action=PRICE&farmid=${farmid}&productid=${productid}`).then(r => r.data.payload),

  // record price
  recordFarmingProductPrice: async ({ farmid, productid, datetime, unitprice }) => ApiPostForm(`/aqua/farming-products`, { action: "RECORD_PRICE", farmid, productid, datetime, unitprice }).then(r => r.data.payload).catch(e => e.response.data),
  
  // remove price
  removeFarmingProductPrice: async ({ farmid, productid, datetime }) => ApiPostForm(`/aqua/farming-products`, { action: "REMOVE_PRICE", farmid, productid, datetime }).then(r => r.data.payload).catch(e => e.response.data),


  /** =========== F I N A N C I A L =========== */

  // fetch pond-level financial data
  fetchPondFinancialData: async ({ pondid, year, month }) => ApiGet(`/aqua/pond-financials?pond_id=${pondid}&year=${year}&month=${month}`).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  // fetch farm-level financial data
  fetchFarmFinancialData: async ({ farmid, group, year, month }) => ApiGet(`/aqua/farm-financials?farm_id=${farmid}&year=${year}&month=${month}`).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  /** =========== AQUA EXPLORER =========== */
  fetchAquaExplorerResult: async ({ bbox, zoom }) => ApiGet(`/aqua/explorer?bbox=${bbox.join(",")}&zoom=${zoom}`).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  createAquaExplorerFeature: async (feature) => ApiPost(`/aqua/explorer/admin`, feature).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  updateAquaExplorerFeature: async (feature) => ApiPut(`/aqua/explorer/admin`, feature).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  deleteAquaExplorerFeature: async ({ pondid }) => ApiDelete(`/aqua/explorer/admin?id=${pondid}`).then(e => e.data.payload).catch(e => ({ error: "data issue" })),

  fetcheNDWIInsights: async ({ feature }) => ApiPost(`/api/copernicus`, { aoi: feature }).then(e => e.data.payload).catch(e => ({ error: "data issue" })),
  

};

