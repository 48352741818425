import { MenuItem, Select } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { MuiBigTextFieldWrapper } from "components/input/MuiBigTextField";
import Text from "components/text/Text";
import FarmingProductTypes from "constants/FarmingProductTypes";
import React, { useEffect, useState } from "react";
import ProductCard, { ProductCardVariants } from "screens/Aquaculture/components/FarmingProduct/components/ProductCard";

const FarmingProductSelector = ({ farmid, productType, ...props }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    Actions.onFetchRegisteredProducts();
  }, [farmid]);

  const Actions = {
    onFetchRegisteredProducts: async () => {
      const result = await PondManagerServices.fetchRegisteredProducts({ farmid: farmid });
      const products = _.orderBy(result.rows, "producer_id").filter((ele) => (productType ? ele.product_type === productType : true));
      setItems(products);
    },
  };

  return (
    <MuiBigTextFieldWrapper label={<Text>interface.actions.select-a-product</Text>}>
      <Select
        label="Registered"
        variant="standard"
        fullWidth
        placeholder="123"
        sx={{
          mt: 2,
          height: 60,
        }}
        {...props}
      >
        {items.map((ele) => {
          return (
            <MenuItem value={ele.id}>
              <ProductCard product={ele} variant={ProductCardVariants.menuItem} />
            </MenuItem>
          );
        })}
      </Select>
    </MuiBigTextFieldWrapper>
  );
};

export default FarmingProductSelector;
