import { Box, Button, Container, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, Typography, colors } from "@mui/material";
import { AddCircleOutlineIcon, AppsIcon, HelpOutlineIcon } from "components/Icons/MaterialIcons";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import PondManagerMapView from "screens/Aquaculture/components/PondManagerMapView";

import { PondManagerServices } from "api/pondManagerServices";
import CommonModal from "components/Modal/CommonModal";
import Text, { getText } from "components/text/Text";
import { getColorsBasedOnListOfText } from "helpers/ColorHelpers";
import { GiSettingsKnobs } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { modalIds, modalReducersActions } from "redux/modal";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import { pathologyManagementActions } from "redux/pondManagement/pathologyManagement";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import { ROUTE_POND_MANAGER_FARM_EDIT, ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import ClimateWidget from "screens/Aquaculture/components/Climate/ClimateWidget";
import OrgConnectButton from "screens/Aquaculture/components/OrgConnect/OrgConnectButton";
import PondEdit from "screens/Aquaculture/components/PondEdit";
import FarmAccessControlButton from "./components/common/FarmAccessControlButton";
import GroupSelection from "screens/Aquaculture/components/common/GroupSelection";

/**
 *
 * Ponds Management System Main
 *
 * @returns
 */

const PondManager = () => {
  const navigate = useNavigate();

  // ~~~ Redux ~~~
  const pondManagementStore = AppSelectors.pondManagementStore();
  const farms = pondManagementStore.farms;
  const fields = pondManagementStore.fields;
  const selectedGroup = pondManagementStore.selectedGroup;

  const dispatch = useDispatch();

  // fetch farmid and other parameters from the URL
  const { farmid } = useParams();

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedPondData, setPondData] = useState(null);
  const [ponds, setPonds] = useState([]);
  const [pondsGeoJson, setPondsGeoJson] = useState(null);
  const [loading, setLoading] = useState(false);

  // tigger for redux
  useEffect(() => {
    const run = async () => {
      if (farmid) {
        // load all farm
        await pondManagementActions.loadAllFarms(dispatch);

        // select a farm to view
        await pondManagementActions.selectFarm(dispatch, { farmId: farmid });

        // init unit prices data
        await pondManagementActions.loadShrmipUnitPrices(dispatch, farmid);

        // init Pathology data
        await pathologyManagementActions.initData(dispatch);

        // fetch registered products
        await pondManagementActions.fetchRegistgeredProducts(dispatch, farmid);
      }
    };

    run();
  }, [dispatch]);

  useEffect(() => {
    // validate farm ownership
    if (farms.length === 0) return;
    if (farmid) {
      const farm = _.find(farms, { farm_id: farmid });
      if (farm && farms) {
        setSelectedFarm(farm);
        return;
      } else {
        navigate(ROUTE_POND_MANAGER_HOME_PAGE);
      }
    } else {
      navigate(ROUTE_POND_MANAGER_HOME_PAGE);
    }
  }, [farms]);

  /** Call when new farm is selected */
  useEffect(() => {
    const run = async () => {
      await getPonds(selectedFarm);
      await cycleManagementActions.loadAllCyclesByFarmId(dispatch, selectedFarm.farm_id);
    };
    selectedFarm && run();
  }, [JSON.stringify(selectedFarm)]);

  /** Trigger when pondData is update */
  useEffect(() => {
    selectedPondData && modalReducersActions.openModal(dispatch, { id: modalIds.POND_EDIT_MODAL });
  }, [selectedPondData]);

  const getPonds = async (farm) => {
    const farmId = farm ? farm?.farm_id : selectedFarm.farm_id;
    if (!farmId) return;
    setLoading(true);
    // if farm is selected
    // TODO: update below to redux
    const pondsResult = await PondManagerServices.listPonds(farmId);
    const geojsonResult = await PondManagerServices.getPondsGeoJson(farmId);
    setPonds(
      pondsResult.sort((a, b) =>
        a?.label.localeCompare(b?.label, undefined, {
          numeric: true,
        })
      )
    );
    setPondsGeoJson(geojsonResult);
    // automatically open pond creation page when there's no ponds created
    if (pondsResult && pondsResult.length < 1 && farm.access_level_write) {
      Actions.pond.onCreatePond();
    }
    setLoading(false);
  };

  const filteredPonds = ponds.filter((e) => (selectedGroup ? e.group === selectedGroup : true));

  const filteredPondsAsGeoJson = pondsGeoJson && {
    ...pondsGeoJson,
    features: pondsGeoJson.features.filter((e) => (selectedGroup ? e.properties.group === selectedGroup : true)),
  };

  const Actions = {
    pond: {
      onCreatePond: async () => {
        setPondData({
          label: "New Pond",
          category: "Shrimp",
          farm_id: selectedFarm?.farm_id,
          lat: selectedFarm?.lat,
          lon: selectedFarm?.lon,
        });
      },
      onEditPond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        setPondData(data);
      },
      onClonePond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        const { id, ...dataWithoutId } = data;
        setPondData(dataWithoutId);
      },
      onDeletePond: async (pondId) => {
        await PondManagerServices.deletePond(pondId);
        const pondsAfter = ponds.filter((ele) => ele.id !== pondId);
        const pondsGeojsonAfter = {
          ...pondsGeoJson,
          features: pondsGeoJson.features.filter((ele) => ele.properties.id !== pondId),
        };
        setPonds(pondsAfter);
        setPondsGeoJson(pondsGeojsonAfter);
        alertsActions.addInfo(dispatch, {
          content: "Pond has been removed",
        });
      },
    },
    group: {
      onSelectGroup: async (group) => {
        setGroup(group);
      },
    },
  };

  // ============
  // START RENDER
  // ============

  /** The summary data in the header */
  const HeaderPondSummary = () => {
    const statsToDisplayList = [
      {
        label: getText("interface.general.farm"),
        value: selectedFarm ? selectedFarm.label : "All",
      },
      {
        label: selectedFarm?.country,
        value: selectedFarm?.region,
      },
    ];

    return (
      <Stack direction="row">
        {statsToDisplayList.map((item, idx) => (
          <Box
            key={idx}
            px={1}
            sx={{
              borderRight: idx !== statsToDisplayList.length - 1 && "1px solid #eee",
            }}
          >
            <Typography fontSize={10}>{item.label}</Typography>
            <Typography fontWeight="bold">{item.value}</Typography>
          </Box>
        ))}
      </Stack>
    );
  };

  const pondGroupColorMapping = getColorsBasedOnListOfText(ponds.filter((ele) => ele.group).map((ele) => ele.group));

  return (
    <Box>
      <CommonModal size="large" id={modalIds.POND_EDIT_MODAL}>
        <PondEdit
          farm={selectedFarm}
          farms={farms}
          ponds={ponds}
          pondData={selectedPondData}
          pondsGeoJson={filteredPondsAsGeoJson}
          style={{
            colorMapping: pondGroupColorMapping,
          }}
          handleClose={() => {
            modalReducersActions.closeModal(dispatch, { id: modalIds.POND_EDIT_MODAL });
            getPonds(selectedFarm);
          }}
        />
      </CommonModal>
      <Box sx={{ height: "3px" }}>{pondManagementStore.loading && <LinearProgress sx={{ height: "100%" }} />}</Box>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={1}
        sx={{
          bgcolor: "#fff",
        }}
      >
        {/* Top Bar */}
        <Stack direction="row" alignItems="center" spacing={1} height="100%">
          {/* Top Bar > Filters */}
          {selectedFarm && (
            <>
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Button variant="text" startIcon={<AppsIcon />} component={Link} to={ROUTE_POND_MANAGER_HOME_PAGE}>
                  <Text>interface.general.farms</Text>
                </Button>
                <Divider orientation="vertical" flexItem />
                <Button variant="text" startIcon={<GiSettingsKnobs />} component={Link} to={`${ROUTE_POND_MANAGER_FARM_EDIT}/${farmid}`}>
                  <Text>interface.general.settings</Text>
                </Button>
                {selectedFarm?.access_level_write && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Button
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={() => {
                        Actions.pond.onCreatePond();
                      }}
                    >
                      <Text>interface.general.pond</Text>
                    </Button>
                  </>
                )}
                <GroupSelection />
              </Stack>
            </>
          )}
        </Stack>
        {/* TopBar > Summary */}
        <Stack direction="row" spacing={1}>
          <ClimateWidget farm={selectedFarm} />
          <HeaderPondSummary />
          <FarmAccessControlButton farm={selectedFarm} />
          <OrgConnectButton farm={selectedFarm} />
        </Stack>
      </Stack>
      <Box sx={{ height: "1px" }}>{loading && <LinearProgress color="secondary" sx={{ height: "100%" }} />}</Box>
      {filteredPondsAsGeoJson &&
        (filteredPondsAsGeoJson.features.length > 0 ? (
          <PondManagerMapView
            fields={fields}
            ponds={filteredPonds}
            pondsGeoJson={filteredPondsAsGeoJson}
            selectedFarm={selectedFarm}
            selectedGroup={selectedGroup}
            style={{
              colorMapping: pondGroupColorMapping,
            }}
            handleEditAction={Actions.pond.onEditPond}
            handleDeleteAction={Actions.pond.onDeletePond}
            handleCloneAction={Actions.pond.onClonePond}
            handleSelectGroupAction={Actions.group.onSelectGroup}
            getPonds={() => {
              getPonds();
              pondManagementActions.loadAllFarms(dispatch);
            }}
          />
        ) : (
          <Container>
            <Stack alignItems={"center"} p={4}>
              <HelpOutlineIcon sx={{ fontSize: "10rem", color: colors.grey[300] }} />
              <Typography sx={{ fontSize: "3rem", color: colors.grey[300] }}>
                <Text>interface.general.no-pond-warning</Text>
              </Typography>
            </Stack>
          </Container>
        ))}
    </Box>
  );
};

export default PondManager;
