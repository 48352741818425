import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import { theme } from "App";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { NoCycleAlertBox } from "components/Alerts/AlertBox";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import MuiBigTextField from "components/input/MuiBigTextField";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import DataLogs from "screens/Aquaculture/components/DataManager/components/DataLogs";
import StatsBar from "screens/Aquaculture/components/common/StatsBar";
import FarmingProductSelector from "components/input/FarmingProductSelector";
import FarmingProductTypes from "constants/FarmingProductTypes";
import FarmingProductTimeseriesChart from "screens/Aquaculture/components/DataManager/components/FarmingProductTimeseriesChart";
import NumericFormatters from "helpers/NumericFormatters";
import { fetchPondFarmingProductData } from "screens/Aquaculture/components/Dashboard/utils/ farmingProductHelpers";
import { alertsActions } from "redux/alerts";
import { useDispatch } from "react-redux";

/**
 * Feed Uploading Panel
 *
 * @param {*} param0
 * @returns
 */
const FeedUploader = ({ farm, pond }) => {
  const [data, setData] = useState({
    raw: [],
    aggregated: [],
    rows: [],
  });

  const cycleManagementStore = AppSelectors.cycleStore();
  const pondManagementStore = AppSelectors.pondManagementStore();

  const selectedCycle = cycleManagementStore?.selectedCycle;
  const isCycleSelected = _.isEmpty(selectedCycle);

  const { registeredProducts } = pondManagementStore;

  const dispatch = useDispatch();

  useEffect(() => {
    if (pond && !_.isEmpty(selectedCycle)) {
      Actions.onLoadData();
    }
  }, [pond, selectedCycle]);

  const Actions = {
    onLoadData: async () => {
      const response = await fetchPondFarmingProductData({
        pondid: pond?.id,
        cycle: selectedCycle,
        productIdField: "feed_product",
        productUsageField: "feed_daily",
        registeredProducts: registeredProducts,
      });
      setData(response);
    },
    submitData: async (requestData) => {
      if (requestData.length > 0) {
        const response = await PondManagerServices.putData({
          rows: requestData,
        });
        Actions.onLoadData();
        alertsActions.addInfo(dispatch, {
          content: getText("interface.alert.alert-success"),
        });
      }
    },
  };

  if (!pond) {
    return (
      <Box p={2}>
        <AlertBox icon={<HelpOutlineIcon />}>
          <Text>interface.alert.please-select-a-pond</Text>!
        </AlertBox>
      </Box>
    );
  }

  if (isCycleSelected)
    return (
      <Box p={2}>
        <NoCycleAlertBox pondId={pond?.id} />
      </Box>
    );

  const stats = {
    fcr: _.last(data?.raw?.filter((ele) => ele.field_id === "fcr_full_cycle"))?.value,
    total_harvest_biomass: _.sumBy(data?.rows?.filter((ele) => ele["harvest_biomass"]) || [], "harvest_biomass"),
    current_biommass: _.last(data?.rows?.filter((ele) => ele["biomass"]) || [])?.biomass,
    total_feed_used: _.last(data?.rows?.filter((ele) => ele["feed_accumulated"]) || [])?.feed_accumulated,
  };

  const dataLogsProps = {
    rows: _.orderBy(
      data.raw.filter((ele) => ele.field_id === "feed_daily" || ele.field_id === "feed_product"),
      "datetime",
      "desc"
    ),
    columns: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: "datetime",
        headerName: "Timestamp",
      },
      {
        field: "feed_product",
        headerName: "Product",
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellRenderer: (params) => {
          const option = _.find(registeredProducts, { id: params.value }) || {
            id: null,
            name: "",
          };
          return <span>{option.name}</span>;
        },
        cellEditorParams: {
          cellRenderer: (params) => {
            const option = _.find(registeredProducts, { id: params.value }) || {
              id: null,
              name: "",
            };
            if (!option.id) {
              return <span>--</span>;
            }
            return (
              <span>
                #{option.id} - {option.product_type} - {option.name}
              </span>
            );
          },
          values: [null, ...registeredProducts.filter((ele) => ele.product_type === FarmingProductTypes.FEED.value).map((ele) => ele.id)],
        },
      },
      {
        field: "feed_daily",
        headerName: "Usage",
        editable: true,
      },
    ],
    onCellEdit: (changedRows) => {
      if (changedRows.length > 0) {
        const form = changedRows.map((ele) => ({
          ...ele,
          pond_id: pond.id,
        }));
        // console.log(form);
        Actions.submitData(form);
      }
    },
  };

  return (
    <Box p={2}>
      <Box p={2} bgcolor={theme.palette.primary.light} borderRadius={2}>
        <Typography variant="cardtitle" color="primary">
          <Text>interface.actions.record-new-data</Text>
        </Typography>
        <Box mt={2}>
          <Formik
            initialValues={{
              created: moment(),
            }}
            onSubmit={(values) => {
              if (values.feed_daily) {
                const requestData = ["feed_daily", "feed_product"]
                  .filter((f) => values[f])
                  .map((f) => ({
                    datetime: values.created.format("YYYY-MM-DD hh:mm:ss"),
                    value: values[f],
                    pond_id: pond.id,
                    field_id: f,
                  }));
                Actions.submitData(requestData);
              }
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Stack spacing={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={4}>
                        <MuiDatetimePicker
                          value={values.created}
                          onAccept={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          onChange={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FarmingProductSelector
                          productType={FarmingProductTypes.FEED.value}
                          name="feed_product"
                          onChange={(e) => {
                            setFieldValue("feed_product", e.target.value);
                          }}
                          farmid={pond?.farm_id}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MuiBigTextField name="feed_daily" onChange={handleChange} label={getText("fields.feed_daily") + " (kg)"} type="number" fullWidth required />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Button variant="contained" type="submit" fullWidth>
                          <Text>interface.actions.submit</Text>
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardtitle" color="primary">
          <Text>interface.general.current-data</Text>
        </Typography>
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <StatsBar
                list={[
                  {
                    label: <Text>interface.general.total-feed-used</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.total_feed_used }),
                  },
                  {
                    label: <Text>interface.general.fcr_full_cycle</Text>,
                    value: NumericFormatters.format({ value: stats.fcr }),
                  },
                  {
                    label: <Text>interface.general.total-harvested-biomass</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.total_harvest_biomass }),
                  },
                  {
                    label: <Text>interface.general.current-biomass-in-pond</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.current_biommass }),
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Box>
            <FarmingProductTimeseriesChart data={data.aggregated} cycle={selectedCycle} />
          </Box>
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardtitle" color="primary">
          <Text>interface.general.data-logs</Text>
        </Typography>
        <Box mt={2}>
          <DataLogs {...dataLogsProps} />
        </Box>
      </Box>
    </Box>
  );
};

export default FeedUploader;
