import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import React from "react";

const OutlinedTag = ({ icon, color, borderColor, text, tooltip }) => {
  const defaultColor = colors.grey[800];
  const defaultBorderColor = colors.grey[400];

  return (
    <Tooltip title={tooltip} placement="top">
      <Box
        sx={{
          display: "inline-block",
          borderRadius: 2,
          border: `1px solid ${borderColor || defaultBorderColor}`,
          transition: "all .1s",
        }}
      >
        <Stack direction="row" alignItems={"center"}>
          {icon && (
            <Box
              sx={{
                pl: 0.3,
                width: 15,
                height: 15,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                svg: {
                  fontSize: 11,
                  color: color || colors.grey[600],
                },
              }}
            >
              {icon}
            </Box>
          )}

          <Box pl={icon ? 0 : 0.8} pr={0.8}>
            <Typography fontSize={12} fontWeight={800} color={color || defaultColor}>
              {text}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export const OutlinedTagCollections = {
  CycleActiveTag: () => <OutlinedTag icon={<CircleIcon />} color={colors.green[700]} text="ACTIVE" />,
  CycleInactiveTag: () => <OutlinedTag icon={<CircleIcon />} color={colors.grey[500]} text="ENDED" />,
};

export default OutlinedTag;
