import { useCallback, useMemo, useRef, useState } from "react";
import { useEffect } from "react";

import { Autocomplete, Box, Button, ButtonGroup, Chip, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { AppSelectors } from "redux/AppReducers";
import _ from "lodash";
import { PondManagerServices } from "api/pondManagerServices";
import moment from "moment";
import { AddCircleOutlineIcon, DownloadIcon, RefreshIcon, RemoveIcon, SaveIcon } from "components/Icons/MaterialIcons";
import { dialogReducerActions } from "redux/dialog";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { AgGridReact } from "ag-grid-react";
import { theme } from "App";
import Text, { getText } from "components/text/Text";
import { Download } from "@mui/icons-material";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import palette from "themes/palette";
import NumericFormatters from "helpers/NumericFormatters";
import numeral from "numeral";

const Utils = {
  /**
   *
   * Validate New Changes
   *
   * @param {*} newRows
   * @returns
   */
  validateChanges: (newRows) => {
    const keyId = "datetime";
    const validateKeyFunc = (key) => moment(key, "YYYY-MM-DD HH:mm:ss", true).isValid();
    const allValues = _.flatten(newRows.map((r) => _.values(_.omit(r, [keyId]))));
    // -- check all values(numeric) in valid format
    const hasInvalidValues = _.includes(
      allValues.map((item) => isNaN(item)),
      true
    );
    if (hasInvalidValues)
      return {
        valid: false,
        error: "You have row(s) that have invalid timestamp format (correct format: YYYY-MM-DD hh:mm:ss)",
      };
    const allKeys = newRows.map((r) => r[keyId]);
    // -- check if all keys are in valid format
    const hasInvalidKeys = !_.every(allKeys.map(validateKeyFunc));
    if (hasInvalidKeys)
      return {
        valid: false,
        error: `You have row(s) that have invalid timestamp format (correct format: YYYY-MM-DD hh:mm:ss)`,
      };
    // -- check if duplicate keys
    const hasDuplicateKeys = _.uniq(allKeys.map((k) => moment(k, "YYYY-MM-DD HH:mm:ss", true).format())).length !== allKeys.length;
    if (hasDuplicateKeys)
      return {
        valid: false,
        error: `You have row(s) that have duplicate timestamp`,
      };
    return {
      valid: true,
    };
  },
  /**
   *
   * Process changes to reformat for API
   *
   * @param {*} oldRows
   * @param {*} newRows
   * @param {*} commonObjectToInsert
   * @returns
   */
  processChanges: (oldRows, newRows, commonObjectToInsert) => {
    const keyId = "datetime";
    const rowsToBeRemoved = oldRows.filter(
      (r) =>
        !_.includes(
          newRows.map((ele) => ele[keyId]),
          r[keyId]
        )
    );

    const oldRowsReformatted = _.flatten(
      oldRows.map((r) =>
        _.keys(r)
          .filter((k) => k !== keyId)
          .map((k) => ({ [keyId]: r[keyId], field_id: k, value: r[k] }))
      )
    );

    let reformatted = [];
    [...rowsToBeRemoved, ...newRows].forEach((ele) => {
      _.keys(ele)
        .filter((k) => k !== keyId)
        .forEach((k) => {
          const cellNotChanged = _.find(oldRowsReformatted, {
            [keyId]: ele[keyId],
            field_id: k,
            value: ele[k],
          });
          const cellToRemove = _.includes(
            rowsToBeRemoved.map((r) => r[keyId]),
            ele[keyId]
          );
          if (cellToRemove) {
            reformatted.push({
              [keyId]: ele[keyId],
              field_id: k,
              value: ele[k],
              _toDelete: true,
              ...commonObjectToInsert,
            });
          } else if (!cellNotChanged) {
            if (ele[k] !== "") {
              // not allowed empty string value due to copy paste
              const isDeletedCell = ele[k] === null ? true : false;
              reformatted.push({
                [keyId]: ele[keyId],
                field_id: k,
                value: ele[k],
                _toDelete: isDeletedCell,
                ...commonObjectToInsert,
              });
            }
          }
        });
    });

    return {
      toSubmit: reformatted,
      rowsToBeRemoved,
    };
  },
};

/**
 *
 * AG-grid based data uploading
 *
 * @param {*} param0
 * @returns
 */
const DataBulkUploader = ({ farm, pond, fields, startDate, endDate }) => {
  const gridRef = useRef();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [orignalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  useEffect(() => {
    serverActions.loadData();
  }, [farm, pond, fields, startDate, endDate]);

  const rowData = data;

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: getText("interface.time.timestamp"),
      field: "datetime",
      sortable: true,
      pinned: "left",
      editable: true,
      width: 180,
    },
    ..._.map(_.groupBy(fields, "field_group"), (flds, g) => ({
      headerName: getText(`fields.group.${g}`),
      children: _.map(_.groupBy(flds, "field_sub_group"), (flds_, sg) => ({
        headerName: `${sg === "null" ? "-" : getText(`fields.subgroup.${sg}`)}`,
        children: flds_.map((f, idx) => {
          return {
            headerName: `${getText(`fields.${f.field_id}`)} (${f.field_unit})`,
            field: f.field_id,
            editable: true,
            cellStyle: { textAlign: "right" },
            cellRenderer: ({ value }) => (value ? numeral(value).format("0,0.00") : ""),
          };
        }),
      })),
    })),
  ];

  const serverActions = {
    loadData: async () => {
      // setLoading(true);
      // farm or pond?
      const result = farm
        ? await PondManagerServices.getFarmTimeseriesData(
            farm.farm_id,
            fields.map((ele) => ele.field_id)
          )
        : await PondManagerServices.getTimeseriesData(
            pond.id,
            fields.map((ele) => ele.field_id),
            startDate,
            endDate
          );
      setData(result.rows);
      setOriginalData(structuredClone(result.rows));
      // setLoading(false);
    },
  };

  const agGridActions = {
    addNewRowAt: useCallback((addIndex, addBy) => {
      let currentData = [];
      gridRef.current?.api.forEachNode((ele) => currentData.push(ele.data));
      const maxDateOfCurrentDate = _.maxBy(currentData, "datetime")?.datetime;
      let newDate = null;
      if (addBy === "1d") {
        newDate = moment(maxDateOfCurrentDate).add(1, "d");
      } else if (addBy === "1w") {
        newDate = moment(maxDateOfCurrentDate).add(1, "w");
      } else if (addBy === "1m") {
        newDate = moment(maxDateOfCurrentDate).add(1, "M");
      } else {
        newDate = moment();
      }
      gridRef.current?.api.applyTransaction({
        add: [{ datetime: newDate.format("YYYY-MM-DD HH:mm:ss") }],
        addIndex: addIndex,
      });
    }),
    removeRowAt: useCallback(() => {
      const selectedRows = gridRef.current?.api.getSelectedRows();
      gridRef.current?.api.applyTransaction({ remove: selectedRows });
    }),
    commitChanges: useCallback(() => {
      let newRows = [];
      gridRef.current?.api.forEachNode((ele) => newRows.push(ele.data));
      const validated = Utils.validateChanges(newRows);
      if (!validated.valid) {
        alertsActions.addError(dispatch, {
          content: `Changes are invalid: ${validated.error}`,
        });
        return;
      }
      const { toSubmit, rowsToBeRemoved } = Utils.processChanges(orignalData, newRows, farm ? { farm_id: farm.farm_id } : { pond_id: pond.id });
      if (toSubmit.length === 0) {
        alertsActions.addInfo(dispatch, { content: "Nothing to submit" });
        return;
      }
      dialogReducerActions.openDialog(dispatch, {
        title: "Confirm Changes",
        content: rowsToBeRemoved.length > 0 ? `Following Dates to be removed: ${rowsToBeRemoved.map((ele) => ele["datetime"]).join(";")}` : "",
        action: async () => {
          const rsp = farm
            ? await PondManagerServices.putFarmData({
                rows: toSubmit,
              })
            : await PondManagerServices.putData({
                rows: toSubmit,
              });
          if (rsp?.payload?.status === "success") {
            alertsActions.addInfo(dispatch, { content: "Upload Success!" });
            setTimeout(() => {
              serverActions.loadData();
            }, 500);
          } else {
            alertsActions.addError(dispatch, { content: "Upload Failed!" });
          }
        },
      });
    }),
    downloadAsCsv: useCallback(() => {
      gridRef?.current?.api.exportDataAsCsv();
    }),
  };

  return (
    <Box px={2} bgcolor={theme.palette.common.white}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
          <ButtonGroup variant="outlined" size="small">
            <Button startIcon={<AddCircleOutlineIcon />} onClick={() => agGridActions.addNewRowAt(undefined)}>
              <Text>interface.general.row</Text>
            </Button>
            <Button startIcon={<AddCircleOutlineIcon />} onClick={() => agGridActions.addNewRowAt(undefined, "1d")}>
              <Text>interface.time.day</Text>
            </Button>
            <Button startIcon={<AddCircleOutlineIcon />} onClick={() => agGridActions.addNewRowAt(undefined, "1w")}>
              <Text>interface.time.week</Text>
            </Button>
            <Button startIcon={<AddCircleOutlineIcon />} onClick={() => agGridActions.addNewRowAt(undefined, "1m")}>
              <Text>interface.time.month</Text>
            </Button>
            <Button startIcon={<RemoveIcon />} onClick={() => agGridActions.removeRowAt()} color="error">
              <Text>interface.general.row</Text>
            </Button>
          </ButtonGroup>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <MuiBorderIconButton icon={<RefreshIcon />} onClick={serverActions.loadData} size="sm" />
            <MuiBorderIconButton icon={<DownloadIcon />} onClick={agGridActions.downloadAsCsv} size="sm" />
            <MuiBorderIconButton icon={<SaveIcon />} onClick={agGridActions.commitChanges} size="sm" />
          </Stack>
        </Stack>
      </Box>
      <Box my={0.5} height="5px">
        {loading && <LinearProgress sx={{ width: "100%" }} />}
      </Box>
      <Box overflow={"hidden"} width="100%" height="calc(100vh - 350px)" className="ag-theme-excel">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          rowSelection="multiple"
          rowHeight={25}
          headerHeight={45}
          groupHeaderHeight={20}
          enableRangeSelection={true}
          enableFillHandle={true}
        ></AgGridReact>
      </Box>
    </Box>
  );
};

/**
 *
 * Pond Level Data AG-grid based data uploading
 *
 * @param {*} param0
 * @returns
 */
const PondDataBulkUploader = ({ farm, pond }) => {
  const [templateLoading, setTemplateLoading] = useState(false);
  const [filteredFields, setFilteredFields] = useState([]);

  const pondManagerStore = AppSelectors.pondManagementStore();
  const cycleStore = AppSelectors.cycleStore();

  const selectedCycle = cycleStore.selectedCycle;

  const farmFields = _.orderBy(
    pondManagerStore.fields.filter((ele) => ele.field_type === "farm" && !ele.is_derived && ele.bulk_upload_enabled),
    ["field_group", "field_sub_group", "field_label", "position"]
  );

  const pondFields = _.orderBy(
    pondManagerStore.fields.filter((ele) => ele.field_type === "pond" && !ele.is_derived && ele.bulk_upload_enabled),
    ["field_group", "field_sub_group", "field_label", "position"]
  );

  const Actions = {
    handleDownloadTemplate: async () => {
      setTemplateLoading(true);
      const response = await PondManagerServices.getFarmBulkUploadTemplate({ farmid: farm?.farm_id });
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const downloadUrl = window.URL.createObjectURL(blob);
      setTemplateLoading(false);
      window.open(downloadUrl, "_blank");
    },
  };

  const singleFieldOptions = pond ? pondFields : farmFields;

  const filteredFarmFields = filteredFields.length > 0 ? filteredFields : farmFields;
  const filteredPondFields = filteredFields.length > 0 ? filteredFields : pondFields;

  return (
    <Box>
      <Box p={2}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Autocomplete
            id="field-autocomplete"
            multiple
            limitTags={3}
            disableCloseOnSelect
            options={singleFieldOptions}
            getOptionLabel={(option) => getText(`fields.${option?.field_id}`) || ""}
            renderOption={(props, option) => (
              <Box {...props}>
                <Box width={"100%"}>
                  <Typography fontSize={11} color="grey">
                    {getText(`fields.group.${option?.field_group}`)}
                  </Typography>
                  <Stack justifyContent={"space-between"} direction={"row"}>
                    <Typography fontSize={12} fontWeight={800}>
                      {getText(`fields.${option?.field_id}`)}
                    </Typography>
                    <Typography fontSize={10} color="grey">
                      {option.field_unit}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            )}
            onChange={(event, value) => {
              setFilteredFields(value);
            }}
            onClose={(e) => {
              console.log(e);
            }}
            renderInput={(params) => <TextField {...params} label="Select a Field" variant="outlined" size="small" sx={{ minWidth: 300 }} />}
            value={filteredFields}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Chip
                    label={getText(`fields.${option?.field_id}`)}
                    key={index}
                    sx={{
                      bgcolor: palette.primary.main,
                      color: palette.primary.light,
                      m: 0,
                      py: 1.2,
                      borderRadius: 3,
                      height: 10,
                      ".MuiChip-deleteIcon": {
                        width: 14,
                        color: "#FFF",
                      },
                      fontWeight: 800,
                      mr: 1,
                    }}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
          />
          <Box>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography fontWeight={800} fontSize={11}>
                Combos:
              </Typography>
              {[
                {
                  label: getText("interface.general.biological"),
                  action: (e) => {
                    console.log(e);
                    setFilteredFields(pondFields.filter((ele) => _.includes(["weight", "current_density", "harvest_biomass"], ele.field_id)));
                  },
                },
                {
                  label: getText("interface.general.feed"),
                  action: (e) => {
                    console.log(e);
                    setFilteredFields(pondFields.filter((ele) => _.includes(["feed_daily", "feed_product"], ele.field_id)));
                  },
                },
                {
                  label: getText("interface.general.additive"),
                  action: (e) => {
                    console.log(e);
                    setFilteredFields(pondFields.filter((ele) => _.includes(["additive_product_usage", "additive_product"], ele.field_id)));
                  },
                },
                {
                  label: getText("interface.general.pond-treatment"),
                  action: (e) => {
                    console.log(e);
                    setFilteredFields(pondFields.filter((ele) => _.includes(["pond_treatment_product_usage", "pond_treatment_product"], ele.field_id)));
                  },
                },
                {
                  label: getText("interface.general.water-quality"),
                  action: (e) => {
                    console.log(e);
                    setFilteredFields(pondFields.filter((ele) => _.includes(["ph", "water_temp", "dissolved_oxygen", "water_exchange", "average_depth"], ele.field_id)));
                  },
                },
              ].map((ele, idx) => (
                <Box
                  onClick={ele.action}
                  sx={{
                    p: 1,
                    py: 0.3,
                    borderRadius: 1,
                    bgcolor: palette.primary.light,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Typography
                    fontWeight={800}
                    fontSize={11}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {ele.label}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>

        {/* <Button startIcon={<Download />} variant="outlined" size="small" onClick={Actions.handleDownloadTemplate}>
            {templateLoading ? "Loading..." : "Download Template"}
          </Button> */}
      </Box>
      {farm && !pond && <DataBulkUploader key={farm.farm_id} farm={farm} fields={filteredFarmFields} />}
      {farm && pond && _.isEmpty(selectedCycle) && <DataBulkUploader key={farm.farm_id} farm={farm} fields={filteredFarmFields} />}
      {farm && pond && !_.isEmpty(selectedCycle) && <DataBulkUploader key={pond.id} pond={pond} fields={filteredPondFields} startDate={selectedCycle.start_date} endDate={selectedCycle.end_date} />}
    </Box>
  );
};

export default PondDataBulkUploader;
