import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import { DashboardIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import PondForecastMk2 from "screens/Aquaculture/components/Forecast/PondForecastMk2";
import AlertBox from "components/Alerts/AlertBox";
import { Box } from "@mui/material";

const ForecastHome = ({ pond, farm, group }) => {
  const MENULIST = [
    {
      label: <Text>interface.general.overview</Text>,
      description: "",
      value: 0,
      icon: <DashboardIcon />,
      component: pond ? (
        <PondForecastMk2 pond={pond} farm={farm} />
      ) : (
        <Box p={2}>
          <AlertBox>Farm Level is currently not supported</AlertBox>
        </Box>
      ),
    },
    // {
    //   label: <Text>interface.general.overview</Text>,
    //   description: "",
    //   value: 1,
    //   icon: <DashboardIcon />,
    //   component: pond ? <PondForecast pond={pond} farm={farm} /> : <FarmForecast farm={farm} group={group} />,
    // },
  ];

  return <SideMenuLayout menulist={MENULIST} title={<Text>interface.general.forecast</Text>} defaultViewMode={0} />;
};

export default ForecastHome;
