import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import { useEffect, useState } from "react";
import { GOOGLE_MAP_KEY } from "secrets";
import GoogleMapReact from "google-map-react";
import usePrevious from "components/Hooks/usePrev";
import WidgetCard from "ui/Card/WidgetCard";
import ClipboardButton from "components/buttons/ClipboardButton";
import * as turf from "@turf/turf";
import { commonInitMaps } from "components/map/googlemapHelper";

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.SATELLITE,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const GeoSketchPad = () => {
  const [mapCenter, setMapCenter] = useState([-31.604802, 146.118482]);
  const [mapZoom, setMapZoom] = useState(1);

  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [mapDrawingLib, setMapDrawingLib] = useState(null);
  const [shape, setShape] = useState(null);

  const prevShape = usePrevious(shape);

  useEffect(() => {
    prevShape && prevShape.setMap(null);
  }, [shape]);

  const Actions = {
    onMapChange: ({ center, zoom, bounds, marginBounds, ...props }) => {
      setMapCenter([center.lat, center.lng]);
      setMapZoom(zoom);
    },
  };

  const handleApiLoaded = async (map, maps) => {
    /** ----- Common Initialisation of Maps ------ */
    commonInitMaps(maps);

    /** ----- Drawing Library ------ */
    const drawing = await maps.importLibrary("drawing");
    const drawingManager = new drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
        drawingModes: [drawing.OverlayType.POLYGON, drawing.OverlayType.RECTANGLE, drawing.OverlayType.MARKER],
      },
      polygonOptions: {
        fillColor: colors.blue[800],
        strokeColor: "#FFF",
        fillOpacity: 0.3,
        strokeWeight: 2,
      },
      rectangleOptions: {
        fillColor: colors.orange[800],
        strokeColor: "#FFF",
        fillOpacity: 0.3,
        strokeWeight: 2,
      },
    });
    drawingManager.setMap(map);

    maps.event.addListener(drawingManager, "polygoncomplete", (selectedArea) => {
      setShape(selectedArea);
    });

    maps.event.addListener(drawingManager, "rectanglecomplete", (selectedArea) => {
      setShape(selectedArea);
    });

    maps.event.addListener(drawingManager, "markercomplete", (marker) => {
      console.log(marker);
    });

    setMap(map);
    setMaps(maps);
    setMapDrawingLib(drawingManager);
  };

  const shapeGeoJson = shape?.toGeoJson();
  const shapeGeoJsonString = shapeGeoJson ? JSON.stringify(shapeGeoJson, null, 4) : "";

  return (
    <Box
      height={"100%"}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 12,
          top: 12,
          zIndex: 999,
        }}
      >
        <WidgetCard title="GeoJson">
          <Box
            p={1}
            bgcolor={"#EEE"}
            borderRadius={2}
            sx={{
              minWidth: "250px",
              maxHeight: "50vh",
              overflow: "auto",
            }}
          >
            <Typography fontSize={10} lineHeight={1.1}>
              <pre>{shapeGeoJsonString || "No Shape"}</pre>
            </Typography>
          </Box>
          <Box mt={1}>
            <ClipboardButton text={shapeGeoJsonString || "{}"} />
          </Box>
        </WidgetCard>
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: "calc(50% - 150px)",
          bottom: 0,
          zIndex: 999,
        }}
      >
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            bgcolor: "#ffffffbd",
            p: 1,
            px: 1,
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Typography fontWeight={800} fontSize={10}>
            Lat: {mapCenter[0]}
          </Typography>
          <Typography fontWeight={800} fontSize={10}>
            Lng: {mapCenter[1]}
          </Typography>
          <Typography fontWeight={800} fontSize={10}>
            Zoom: {mapZoom}
          </Typography>
        </Stack>
      </Box>

      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAP_KEY,
          libraries: "drawing",
        }}
        center={mapCenter}
        defaultZoom={mapZoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={Actions.onMapChange}
      ></GoogleMapReact>
    </Box>
  );
};

export default GeoSketchPad;
