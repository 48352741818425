import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { PondManagerServices } from "api/pondManagerServices";
import LoadingBox from "components/Loading/LoadingBox";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import { useState } from "react";
import PondHistoricalCycleChart from "screens/Aquaculture/components/Dashboard/components/charts/PondHistoricalCycleChart";
import MiniLabelAndValueCard from "ui/Card/MiniLabelAndValueCard";

import WidgetCard from "ui/Card/WidgetCard";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const SummarySection = ({ data }) => {
  const latestCycleName = data?._cycle;
  const latestCycleEnded = data?._cycle_ended;
  const latestDoc = data?.doc;
  const latestBiomass = data?.biomass;
  const latestWeight = data?.weight;
  const latestSurvivalRate = data?.survival_rate;
  const totalHarvested = data?.harvest_biomass_accumulated;

  return (
    <WidgetCard title={<Text>interface.general.summary</Text>}>
      <Stack direction={"row"} spacing={1}>
        <MiniLabelAndValueCard label={<Text>interface.general.latest-cycle</Text>} value={latestCycleName} />
        <MiniLabelAndValueCard label={<Text>interface.general.activity</Text>} value={latestCycleEnded ? getText("interface.general.ended") : getText("interface.genearl.active")} />
        <MiniLabelAndValueCard label={<Text>interface.general.DoC</Text>} value={latestDoc} />
        <MiniLabelAndValueCard label={<Text>interface.general.latest-biomass</Text>} value={NumericFormatters.formatWeightInKg({ value: latestBiomass })} />
        <MiniLabelAndValueCard label={<Text>interface.general.total-harvested</Text>} value={NumericFormatters.formatWeightInKg({ value: totalHarvested })} />
        <MiniLabelAndValueCard label={<Text>interface.general.latest-weight</Text>} value={NumericFormatters.formatWeightInG({ value: latestWeight })} />
        <MiniLabelAndValueCard label={<Text>fields.survival_rate</Text>} value={NumericFormatters.format({ value: latestSurvivalRate, decimalScale: 1, suffix: "%" })} />
      </Stack>
    </WidgetCard>
  );
};

/**
 *
 * Pond Biological Dashboard
 *
 * @param {pond} param0
 * @returns
 */
const PondBiologicalDashboard = ({ pond }) => {
  const viewModes = {
    normal: "normal",
    seasonal: "seasonal",
  };

  const [viewMode, setViewMode] = useState(viewModes.seasonal);
  const [yearLimit, setYearLimit] = useState(5);

  // ~~~~ React Query ~~~
  const theQuery = useQuery({
    queryKey: ["data", "pond-dashboard", "biological", pond?.id],
    queryFn: async () => {
      const response = await PondManagerServices.dashboard.getPondBiologicalDashboardData({ pond });
      return response;
    },
    enabled: !!pond,
    staleTime: 1000 * 60 * 10, // 10 min
    gcTime: 1000 * 60 * 30, // 10 min
    retry: false,
  });

  const { refetch, error, dataUpdatedAt, isFetching } = theQuery;
  const data = theQuery.data || {};
  if (isFetching || _.isEmpty(data)) return <LoadingBox />;

  const latestCycleData = _.maxBy(data?.latest, "_cycle_start");

  return (
    <Box p={2}>
      <SummarySection data={latestCycleData} />
      <Stack direction={"row"} spacing={2} divider={<Divider orientation="vertical" color="primary" />}>
        <MuiTabs
          options={[
            { label: "1Y", value: 1 },
            { label: "2Y", value: 2 },
            { label: "3Y", value: 3 },
            { label: "4Y", value: 4 },
            { label: "5Y", value: 5 },
            { label: "10Y", value: 10 },
            { label: getText("interface.general.all"), value: null },
          ]}
          onNewValue={setYearLimit}
          theme={MuiTabsThemes.chip}
          value={yearLimit}
        />
        <MuiTabs
          options={[
            { label: "H", description: getText("interface.general.full-history"), value: viewModes.normal },
            { label: "C", description: getText("interface.general.by-cycle"), value: viewModes.seasonal },
          ]}
          onNewValue={setViewMode}
          theme={MuiTabsThemes.chip}
          value={viewMode}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <WidgetCard title={getText("fields.biomass")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="biomass" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} lg={4}>
          <WidgetCard title={getText("fields.seeded_density")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="seeded_density"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={"normalHead"}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.weight")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="weight" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.survival_rate")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="survival_rate" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.weight_diff")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="weight_diff" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.organisms")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="organisms" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        {/* ============================= */}

        <Grid item xs={12}>
          <Typography variant="cardtitle">
            <Text>interface.genearl.feed</Text>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.feed_accumulated")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="feed_accumulated"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        {/* <Grid item xs={12} lg={6}>
          <WidgetCard title={getText("fields.fcr")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="fcr" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid> */}

        {/* ============================= */}

        <Grid item xs={12}>
          <Typography variant="cardtitle">KPIs</Typography>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.sgr")}>
            <PondHistoricalCycleChart rows={data?.rows} cycles={data?.cycles} fieldId="sgr" yearLimit={yearLimit} setYearLimit={setYearLimit} viewMode={viewMode} setViewMode={setViewMode} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.biomass_per_area")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="biomass_per_area"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.biomass_per_pl")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="biomass_per_pl"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.mortality_rate")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="mortality_rate"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.biomass_per_area_t")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="biomass_per_area_t"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <WidgetCard title={getText("fields.biomass_per_pl_t")}>
            <PondHistoricalCycleChart
              rows={data?.rows}
              cycles={data?.cycles}
              fieldId="biomass_per_pl_t"
              yearLimit={yearLimit}
              setYearLimit={setYearLimit}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </WidgetCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PondBiologicalDashboard;
