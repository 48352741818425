import { Box, Stack, Typography } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import OutlinedTag from "components/Tags/OutlinedTag";
import React from "react";
import NumberFormat from "react-number-format";

const FarmCostsWidget = (props) => {
  const { farmDashboardData } = props;

  const generateChartConfigs = () => {
    return {
      chart: {
        type: "",
        height: 250,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: ["Total", "Fixed", "Var"],
        title: {
          text: null,
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: [
        {
          title: {
            text: null,
          },
          opposite: true,
        },
        {
          title: {
            text: null,
          },
          opposite: false,
        },
      ],
      series: [
        {
          type: "bar",
          name: "Total",
          yAxis: 0,
          data: [
            farmDashboardData?.costs?.farm_costs_total,
            farmDashboardData?.costs?.farm_fixed_costs_total,
            farmDashboardData?.costs?.farm_var_costs_total,
          ],
        },
        {
          type: "bar",
          name: "Unit",
          yAxis: 1,
          data: [
            farmDashboardData?.costs?.farm_costs_by_unit,
            farmDashboardData?.costs?.farm_fixed_costs_by_unit,
            farmDashboardData?.costs?.farm_var_costs_by_unit,
          ],
        },
      ],
    };
  };

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <OutlinedTag
          text={
            <NumberFormat
              displayType="text"
              value={farmDashboardData?.costs?.farm_costs_total / 1000 / 1000}
              decimalScale={0}
              prefix="Total: $"
              suffix="M"
            />
          }
        />
        <OutlinedTag
          text={
            <NumberFormat
              displayType="text"
              value={farmDashboardData?.costs?.farm_costs_by_unit}
              decimalScale={0}
              prefix="Total Unit: $"
              suffix="/kg"
            />
          }
        />
      </Stack>
      <HighchartsWrapper options={generateChartConfigs()} />
    </Box>
  );
};

export default FarmCostsWidget;
