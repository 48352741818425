import { AddCircleOutline, BorderColor } from "@mui/icons-material";
import { Box, Button, Drawer, Grid, IconButton, Stack, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { AddIcon, ArrowForwardIcon, DeleteIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import MuiBigTextField from "components/input/MuiBigTextField";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import { getColorByFieldId } from "helpers/ColorHelpers";
import Formatters from "helpers/Formatters";
import { formatValueByFieldId } from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";

const DataChart = ({ data, field, cycle, color, onSelect }) => {
  if (!data) return "";
  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const fieldUnit = field.field_unit;

  const dataToDisplay = data.filter((ele) => ele?.[fieldId]);

  const configs = {
    chart: {
      height: "200px",
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {},
    series: [
      {
        name: fieldName,
        type: "areaspline",
        data: dataToDisplay.map((ele, idx) => ({
          x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
          y: ele?.[fieldId],
        })),
        color: color,
        fillOpacity: 0.1,
      },
    ],
  };
  return (
    <Box>
      <HighchartsWrapper options={configs} />
    </Box>
  );
};

const DataTable = ({ data, pond, field, onDeleteData }) => {
  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const fieldUnit = field.field_unit;
  const uploadable = !field.is_derived && field.bulk_upload_enabled;
  const dataToDisplay = _.orderBy(
    data.filter((ele) => ele?.[fieldId]),
    "datetime",
    "desc"
  );
  const [dataRows, setDataRows] = useState(dataToDisplay);
  const [colDefs, setColDefs] = useState([
    {
      field: "datetime",
      headerName: getText("interface.general.timestamp"),
      flex: 1,
    },
    {
      field: fieldId,
      headerName: fieldName,
      flex: 1,
    },
    {
      field: "datetime",
      headerName: "",
      width: 50,
      cellRenderer: ({ data }) => (
        <Stack direction={"row"} spacing={0.3}>
          {/* <MuiBorderIconButton size={"sm"} onClick={() => actions.onEditProduct({ product: data })} icon={<EditIcon />} /> */}
          {uploadable && (
            <MuiBorderIconButton
              size={"sm"}
              onClick={() =>
                onDeleteData({
                  ...data,
                  pond_id: pond?.id,
                  field_id: field?.field_id,
                  _toDelete: true,
                })
              }
              icon={<DeleteIcon />}
            />
          )}
        </Stack>
      ),
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
    },
  ]);

  useEffect(() => {
    const dataToDisplay = _.orderBy(
      data.filter((ele) => ele?.[fieldId]),
      "datetime",
      "desc"
    );
    setDataRows(dataToDisplay);
  }, [data]);

  return (
    <Box
      className="ag-theme-excel"
      sx={{
        height: 300,
      }}
    >
      <AgGridReact rowData={dataRows} columnDefs={colDefs} headerHeight={28} rowHeight={30} />
    </Box>
  );
};

const DataInputPanel = ({ pond, field, cycle, onSubmit }) => {
  return (
    <Box
      p={2}
      sx={{
        maxWidth: "80vw",
      }}
    >
      <Formik
        initialValues={{
          datetime: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          value: null,
        }}
        onSubmit={(values) => {
          const payload = {
            pond_id: pond?.id,
            field_id: field?.field_id,
            datetime: values.datetime,
            value: values.value,
          };
          onSubmit && onSubmit(payload);
        }}
        validateOnBlur={false}
      >
        {({ values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Stack spacing={1}>
                <MuiDatetimePicker
                  value={moment(values.datetime)}
                  onAccept={(newValue) => {
                    setFieldValue("datetime", newValue.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                  onChange={(newValue) => {
                    setFieldValue("datetime", newValue.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                  fullWidth
                />
                <MuiBigTextField name="value" type="number" onChange={handleChange} fullWidth />
                <Button size="large" variant="contained" type="submit" fullWidth>
                  <Text>interface.actions.submit</Text>
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const MobilePondViewDataUploader = ({ pond, field }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const cycleStore = AppSelectors.cycleStore();

  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[pond?.id];

  const uploadable = !field.is_derived && field.bulk_upload_enabled;

  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const color = getColorByFieldId(fieldId);

  const dataToDisplay = data?.filter((ele) => ele?.[fieldId]);
  const latestValue = formatValueByFieldId(_.last(dataToDisplay)?.[fieldId], fieldId);
  const latestValueDt = _.last(dataToDisplay)?.datetime;

  useEffect(() => {
    if (pond?.id === selectedCycle?.pond_id) {
      Actions.loadData();
    }
  }, [pond?.id, selectedCycle?.id]);

  const Actions = {
    loadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(pond?.id, [field.field_id], selectedCycle?.start_date, selectedCycle?.end_date);
      setData(response.rows);
    },
    toggleDrawer: () => {
      setDrawerOpen(!drawerOpen);
    },
    onSubmitData: async (payload) => {
      const response = await PondManagerServices.putData({
        rows: [payload],
      });
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      Actions.loadData();
      setDrawerOpen(false);
    },
    onDeleteData: async (payload) => {
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      confirmed && Actions.onSubmitData(payload);
    },
  };

  return (
    <Box>
      <Box my={2}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={1}>
          <Stack justifyContent={"center"}>
            <Typography fontSize={12} fontWeight={800} color={color}>
              <Text>{fieldName}</Text>
            </Typography>
            {latestValue ? (
              <Typography fontSize={30} fontWeight={800}>
                {latestValue}
              </Typography>
            ) : (
              <Typography fontSize={26} fontWeight={800} color="#CCC">
                <Text>interface.alert.no-data</Text>
              </Typography>
            )}
            <Typography fontSize={10} fontWeight={800} color="grey">
              {latestValueDt ? TimeFormatters.formatDatetime(latestValueDt) : "--"}
            </Typography>
          </Stack>
          <Box>
            {uploadable ? (
              <IconButton
                sx={{
                  border: "1px solid #777",
                }}
                onClick={Actions.toggleDrawer}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Box>
      <Box>{data && <DataChart data={data} pond={pond} field={field} color={color} cycle={selectedCycle} />}</Box>
      <Box>{data && <DataTable data={data} pond={pond} field={field} color={color} cycle={selectedCycle} onDeleteData={Actions.onDeleteData} />}</Box>
      <Drawer anchor={"right"} open={drawerOpen} onClose={Actions.toggleDrawer}>
        <DataInputPanel data={data} pond={pond} field={field} cycle={selectedCycle} onSubmit={Actions.onSubmitData} />
      </Drawer>
    </Box>
  );
};

export default MobilePondViewDataUploader;
