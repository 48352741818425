import { Box, Stack, Typography } from "@mui/material";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { ListIcon } from "components/Icons/MaterialIcons";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import React from "react";
import WidgetCard from "ui/Card/WidgetCard";

const FeatureProfile = ({ feature }) => {
  const { properties } = feature;
  return (
    <Box p={1}>
      <WidgetCard icon={properties.country_code ? <CountryFlagSvgIcon countryCode={properties.country_code} /> : <ListIcon />} title={`Label: ${properties.label}`}>
        <Stack spacing={0.5}>
          <Typography fontSize={10}>
            Country: <strong>{properties.country}</strong>
          </Typography>
          <Typography fontSize={10}>
            Obtained At: <strong>{TimeFormatters.formatUtcDatetime(properties.created)}</strong>
          </Typography>
          <Typography fontSize={10}>
            ID: <strong>{properties.id}</strong>
          </Typography>
          <Typography fontSize={10}>
            Latitude: <strong>{properties.lat}</strong>
          </Typography>
          <Typography fontSize={10}>
            Longitude: <strong>{properties.lng}</strong>
          </Typography>
          <Typography fontSize={10}>
            Area: <strong>{NumericFormatters.formatAreaInHa({ value: properties.area })}</strong>
          </Typography>
          <Typography fontSize={10}>
            Group: <strong>{properties.group}</strong>
          </Typography>
          <Typography fontSize={10}>
            Category: <strong>{properties.category || "-"}</strong>
          </Typography>
          <Typography fontSize={10}>
            Sub-category: <strong>{properties.sub_category || "-"}</strong>
          </Typography>
        </Stack>
      </WidgetCard>
    </Box>
  );
};

export default FeatureProfile;
