import { DashboardIcon, SatelliteAltIcon } from "components/Icons/MaterialIcons";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import ArcGisDashboard from "screens/Aquaculture/components/SatEye/ArcGisDashboard";
import SatEye from "screens/Aquaculture/components/SatEye/SatEye";

const SatEyeHome = ({ pond, farm }) => {
  const MENULIST = [
    {
      label: "Home",
      description: "",
      value: 0,
      icon: <SatelliteAltIcon />,
      component: <SatEye farm={farm} pond={pond} />,
    },
    {
      label: "Biomass",
      description: "",
      value: 1,
      icon: <DashboardIcon />,
      component: <ArcGisDashboard key="biomass" farm={farm} />,
    },
    {
      label: "Disolved Oxygen",
      description: "",
      value: 2,
      icon: <DashboardIcon />,
      component: <ArcGisDashboard key="do" farm={farm} defaultView="do" />,
    },
    {
      label: "Water Temperature",
      description: "",
      value: 3,
      icon: <DashboardIcon />,
      component: <ArcGisDashboard key="temp" farm={farm} defaultView="temp" />,
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={"SATEYE"} defaultViewMode={0} />;
};

export default SatEyeHome;
