import { Container } from "@mui/material";
import FarmAccessControl from "screens/Aquaculture/components/common/FarmAccessControl";

const FarmAccessControlSettings = ({ farm }) => {
  return (
    <Container maxWidth="lg">
      <FarmAccessControl farm={farm} />
    </Container>
  );
};

export default FarmAccessControlSettings;
