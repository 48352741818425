import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Box, Button, Chip, Grid, IconButton, Stack, Switch, Tooltip, Typography, colors } from "@mui/material";
import { GOOGLE_MAP_KEY } from "secrets";
import Text from "components/text/Text";
import { CenterFocusStrongIcon, CircleIcon, InfoIcon, KeyboardArrowLeftIcon, ListIcon, MapIcon } from "components/Icons/MaterialIcons";
import palette from "themes/palette";
import _ from "lodash";
import usePrevious from "components/Hooks/usePrev";
import PondManagerBatchUpdateForm from "screens/Aquaculture/components/PondManager/PondManagerBatchUpdateForm";
import { AppSelectors } from "redux/AppReducers";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import PondMapInfoBox from "screens/Aquaculture/components/ui/PondMapInfoBox";
import PondInfoBanner from "screens/Aquaculture/components/Map/PondInfoBanner";
import { overlays } from "json/googlemap_overlays";
import NumericFormatters from "helpers/NumericFormatters";

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.SATELLITE,
    // minZoom: 11,
    // maxZoom: 18,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const highlightStyle = {
  fillColor: "#FFF",
  strokeColor: "#FFF",
};
const highlightStyle2 = {
  fillColor: "#FFF",
  strokeColor: "#FFF",
  strokeWeight: 4,
  fillOpacity: 0,
};

const PondList = ({ ponds, selectedPond, actions }) => {
  return (
    <Grid container spacing={0}>
      {ponds.map((p) => {
        const isHighlighted = selectedPond?.id === p.id;
        return (
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              px={1}
              py={0.5}
              borderRadius={1}
              sx={{
                background: isHighlighted ? palette.primary.main : "#FFF",
                color: isHighlighted ? palette.primary.light : palette.primary.main,
                "&:hover": {
                  opacity: 0.9,
                  cursor: "pointer",
                  bgcolor: colors.grey[100],
                  color: isHighlighted && palette.primary.main,
                },
              }}
              onClick={() =>
                actions.onSelectPond({
                  ...p,
                  name: p.label,
                })
              }
            >
              <Typography fontWeight={800} fontSize={12}>
                <span style={{ color: colors.grey[400], marginRight: 5 }}>{p.group}</span> {p.label}
              </Typography>
              <Typography fontWeight={800} fontSize={10}>
                {NumericFormatters.formatAreaInHa({ value: p.area })}
              </Typography>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

/**
 *
 * Pond Manager Map Component
 *
 * @param {*} param0
 * @returns
 */
const PondManagerMap = ({ defaultCenter, pondsGeoJson, pondGroupColorMapping, states, actions }) => {
  const { orgId } = useParams();
  const contentDisplayConfigs = {
    writable: orgId ? false : true,
  };

  const { selectedFarm, selectedGroup, selectedPond, selectedPonds, ponds, farms, multiMode } = states;
  const { getPonds, onHandleSelectPondAction, onHandleSelectMultiPonds, unselectPond, handleEditAction, handleDeleteAction, handleCloneAction, handleSelectGroupAction } = actions;

  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [hoveredPond, setHoveredPond] = useState(null);
  const [mapkey, setMapKey] = useState(1);
  const [showList, setShowList] = useState(false);

  // **** REDUX ****
  const mapManagementStore = AppSelectors.mapStore();

  const mapRef = useRef(null);

  const prevGeoJson = usePrevious(pondsGeoJson);

  useEffect(() => {
    if (map && maps) {
      const geoJsonChanged = JSON.stringify(prevGeoJson) !== JSON.stringify(pondsGeoJson);
      if (geoJsonChanged) {
        // reload
        setMapKey(mapkey + 1);
      }
    }
  }, [pondsGeoJson]);

  useEffect(() => {
    onHandleMapActions.highligtMultiPonds(selectedPonds);
  }, [selectedPonds]);

  useEffect(() => {
    onHandleMapActions.highligtMultiPonds(mapManagementStore.pondsToHighlight);
  }, [mapManagementStore.pondsToHighlight]);

  useEffect(() => {
    // handle if selectedPond is null
    if (selectedPond === null) {
      onHandleMapActions.postUnselectPond();
    }
  }, [selectedPond]);

  /**
   * initialize map
   * - load ponds geojson
   * - assign click,.. events
   *
   * @param {*} map
   * @param {*} maps
   */
  const handleApiLoaded = (map, maps) => {
    if (!map || !maps) return;
    // :: clean all data ::

    // :: load data ::
    map.data.addGeoJson(pondsGeoJson, {
      idPropertyName: "pondsGeoJson",
    });

    // :: set style ::
    map.data.setStyle(function (ft) {
      const polygon_group = ft.getProperty("group");
      const polygon_color = pondGroupColorMapping[polygon_group]?.color;
      return {
        fillColor: polygon_color || "#333",
        strokeColor: polygon_color || "#333",
        strokeWeight: 2,
      };
    });
    // :: actions ::
    map.data.addListener("mouseover", (e) => {
      e.feature.toGeoJson(({ properties }) => {
        setHoveredPond(properties);
      });
    });
    map.data.addListener("mouseout", (e) => {
      setHoveredPond(null);
    });
    map.data.addListener("click", (e, f) => {
      if (!multiMode) {
        map.data.revertStyle();
        map.data.overrideStyle(e.feature, highlightStyle);
        e.feature.toGeoJson(({ properties }) => {
          onHandleSelectPondAction(properties);
        });
      } else {
        e.feature.toGeoJson(({ properties }) => {
          onHandleSelectMultiPonds(properties);
        });
      }
    });
    // :: append overlays ::
    let historicalOverlay;
    overlays.forEach((ol) => {
      let imageBounds = ol.bounds;
      historicalOverlay = new google.maps.GroundOverlay(ol.url, imageBounds);
      historicalOverlay.setMap(map);
    });

    // :: fit bounds ::
    const bounds = new maps.LatLngBounds();
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds);

    // :: set Map and Maps reference ::
    setMap(map);
    setMaps(maps);
  };

  const onHandleMapActions = {
    onMapChange: (e) => {
      if (!map || !maps) return;
      // if (e.zoom < 12) {
      //   map.data.forEach((ft) => {
      //     map.data.overrideStyle(ft, {
      //       visible: false,
      //     });
      //   });
      // } else {
      //   map.data.forEach((ft) => {
      //     map.data.overrideStyle(ft, {
      //       visible: true,
      //     });
      //   });
      // }
    },
    highligtMultiPonds: async (selectedPonds) => {
      // after map is rendered
      if (mapRef?.current?.map_) {
        map.data.revertStyle();
        mapRef.current.map_.data.forEach((d) => {
          d.toGeoJson(({ properties }) => {
            if (
              _.includes(
                selectedPonds.map((e) => e.id),
                properties?.id
              )
            ) {
              mapRef.current.map_.data.overrideStyle(d, highlightStyle2);
            } else {
            }
          });
        });
      }
    },
    fitToCenter: () => {
      const bounds = new maps.LatLngBounds();
      map.data.forEach(function (feature) {
        feature.getGeometry().forEachLatLng(function (latlng) {
          bounds.extend(latlng);
        });
      });
      map.fitBounds(bounds);
      setMap(map);
    },
    postUnselectPond: () => {
      map?.data?.revertStyle();
    },
  };

  const writable = selectedFarm?.access_level_write && contentDisplayConfigs.writable;

  return (
    <Box height="100%" width={"100%"} position={"relative"}>
      {/* :: Google Map Overlay Legends :: */}
      <Box
        zIndex={999}
        sx={{
          position: "absolute",
          left: "5px",
          bottom: "5px",
          bgcolor: "#eee",
          p: 0.5,
          borderRadius: 3,
        }}
      >
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            svg: {
              transform: "translateY(2px)",
            },
          }}
        >
          <ListIcon />
          <Switch size="small" value={showList} onChange={() => setShowList(!showList)} />
          <MapIcon />
        </Stack>
      </Box>
      <Box
        p={1}
        zIndex={999}
        sx={{
          position: "absolute",
          bottom: "36px",
          left: "-5px",
          boxSizing: "border-box",
          display: showList && "none",
        }}
      >
        <Button size="small" startIcon={<CenterFocusStrongIcon />} variant="contained" onClick={onHandleMapActions.fitToCenter}>
          <Text>interface.actions.center-map</Text>
        </Button>
      </Box>
      <Box
        zIndex={999}
        p={1}
        sx={{
          display: showList && "none",
          position: "absolute",
          bottom: "80px",
          left: "5px",
          boxSizing: "border-box",
          borderRadius: 1,
          bgcolor: "#03030376",
          backdropFilter: "blur(10px)",
          maxHeight: "300px",
          overflow: "auto",
        }}
      >
        {_.values(pondGroupColorMapping)
          .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: "base" }))
          .map((ele) => (
            <Box
              key={ele.label}
              onClick={() => {
                if (selectedGroup && selectedGroup === ele.label) {
                  handleSelectGroupAction(null);
                } else {
                  handleSelectGroupAction(ele.label);
                }
              }}
              sx={{
                opacity: 0.6,
                "&:hover": {
                  cursor: "pointer",
                  opacity: 1,
                },
              }}
            >
              <Typography fontWeight={"bold"} fontSize="0.7rem" color="white">
                <span>
                  <CircleIcon
                    sx={{
                      fontSize: "0.6rem",
                      color: !selectedGroup ? ele.color : ele.label === selectedGroup ? ele.color : colors.grey[600],
                      transform: "translateY(2px)",
                    }}
                  />
                </span>{" "}
                {ele.label}
              </Typography>
            </Box>
          ))}
      </Box>

      {/* :: Farm Info Box :: */}
      {!selectedPond && !selectedGroup && !multiMode && (
        <Box
          p={1}
          pt={2}
          pb={3}
          zIndex={99}
          sx={{
            background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5676645658263305) 46%, rgba(0,0,0,0.9037990196078431) 100%)",
            position: "absolute",
            width: "100%",
            boxSizing: "border-box",
            animation: "fadeIn 0.4s",
          }}
        >
          <Stack alignItems={"center"}>
            <Typography color="primary.light" variant="caption">
              <Text>interface.general.farm</Text>
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5" fontWeight="bold" color="primary.light" sx={{ mb: 1 }}>
                {selectedFarm.label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Pond Number" placement="top">
                <Chip
                  size="small"
                  color="primary"
                  label={`P#: ${ponds.length}`}
                  sx={{
                    backgroundColor: colors.blue[600],
                  }}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Box>
      )}

      {selectedPonds.length > 0 && multiMode && (
        <Box
          p={2}
          sx={{
            backgroundColor: "#ffffffcc",
            position: "absolute",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="underline">
            <strong>{selectedPonds.length}</strong> <Text>interface.general.ponds</Text>
          </Typography>
          <Tooltip
            title={
              <Stack spacing={0.5}>
                {selectedPonds.map((ele) => (
                  <Chip color="primary" label={ele.name} size="small" />
                ))}
              </Stack>
            }
            placement="right"
          >
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <PondManagerBatchUpdateForm selectedFarm={selectedFarm} farms={farms} pondIds={selectedPonds.map((ele) => ele.id)} callback={getPonds} />
        </Box>
      )}

      {/* Group Info Box */}
      {!selectedPond && selectedGroup && !multiMode && (
        <Box
          p={1}
          pb={3}
          zIndex={99}
          sx={{
            background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5676645658263305) 46%, rgba(0,0,0,0.9037990196078431) 100%)",
            position: "absolute",
            width: "100%",
            boxSizing: "border-box",
            animation: "fadeDown 0.4s",
          }}
        >
          <Box>
            <Button
              variant="text"
              size="small"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => handleSelectGroupAction(null)}
              sx={{
                color: "primary.light",
              }}
            >
              <Text>interface.actions.switch-to-farm</Text>
            </Button>
          </Box>

          <Stack alignItems={"center"}>
            <Typography color="primary.light" variant="caption">
              <Text>interface.general.group</Text>
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5" fontWeight="bold" color="primary.light" sx={{ mb: 1 }}>
                {selectedGroup}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Pond Number" placement="top">
                <Chip
                  size="small"
                  color="primary"
                  label={`P#: ${ponds.length}`}
                  sx={{
                    backgroundColor: colors.blue[600],
                  }}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Box>
      )}

      {/* :: Pond Info Box :: */}
      {selectedPond && !multiMode && (
        <PondInfoBanner
          pond={selectedPond}
          writable={writable}
          selectedGroup={selectedGroup}
          actions={{
            onReturn: () => {
              unselectPond();
              onHandleMapActions.postUnselectPond();
            },
            handleEditAction,
            handleCloneAction,
            handleDeleteAction,
          }}
        />
      )}

      {/* Multi-Mode */}
      {selectedPonds.length > 0 && multiMode && (
        <Box
          p={2}
          sx={{
            zIndex: 99,
            bgcolor: "#eee",
            position: "absolute",
            width: "100%",
            boxSizing: "border-box",
            animation: "fadeIn 0.4s",
          }}
        >
          <Stack direction="row" alignItems={"center"}>
            <Typography>
              <strong>{selectedPonds.length}</strong> <Text>interface.general.ponds</Text>
            </Typography>
            <Tooltip
              title={
                <Stack spacing={0.5}>
                  {selectedPonds.map((ele) => (
                    <Chip color="primary" label={ele.name} size="small" />
                  ))}
                </Stack>
              }
              placement="right"
            >
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          {contentDisplayConfigs.writable && <PondManagerBatchUpdateForm selectedFarm={selectedFarm} farms={farms} pondIds={selectedPonds.map((ele) => ele.id)} callback={getPonds} />}
        </Box>
      )}

      {showList ? (
        <Box
          sx={{
            height: "100%",
            bgcolor: "white",
            overflow: "auto",
          }}
        >
          <Box pt={25} px={2} pb={20}>
            <PondList
              ponds={ponds}
              selectedPond={selectedPond}
              actions={{
                onSelectPond: onHandleSelectPondAction,
              }}
            />
          </Box>
        </Box>
      ) : (
        <GoogleMapReact
          ref={mapRef}
          bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
          center={center}
          defaultZoom={zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          onChange={onHandleMapActions.onMapChange}
          key={mapkey}
        >
          {hoveredPond && <PondMapInfoBox lat={hoveredPond?.lat} lng={hoveredPond?.lng} pond={hoveredPond} />}
        </GoogleMapReact>
      )}
    </Box>
  );
};

export default PondManagerMap;
