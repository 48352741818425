import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PondManagerServices } from "api/pondManagerServices";
import _ from "lodash";
import FarmImages from "screens/Aquaculture/components/Files/FarmImages";
import FarmProfileForm from "screens/Aquaculture/components/Farm/FarmProfileForm";

const FarmMainSettings = ({ farmid }) => {
  const [farm, setFarm] = useState(null);
  const [ready, setReady] = useState(!farmid);

  useEffect(() => {
    Actions.onLoadFarm();
  }, []);

  const Actions = {
    onLoadFarm: async () => {
      if (farmid) {
        const result = await PondManagerServices.getFarms();
        const farm_ = _.find(result, { farm_id: farmid });
        farm_ && setFarm(farm_);
        setReady(true);
      }
    },
  };

  if (!ready) return null;

  return (
    <Box px={2}>
      <Container maxWidth="lg">
        <Box mb={4}>{farm && <FarmProfileForm farm={farm} />}</Box>
      </Container>
    </Box>
  );
};

export default FarmMainSettings;
