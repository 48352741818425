const defaultFont = `"Manrope", Helvetica, -apple-system, "Helvetica Neue", Arial`;

export const typography = {
  fontFamily: defaultFont,
  fontSize: 12,
  fontWeight: 400,
  htmlFontSize: 16,
  button: {
    fontWeight: 800,
  },
  h1: {
    fontWeight: 600,
  },
  h2: {
    fontWeight: 600,
  },
  h3: {
    fontWeight: 600,
  },
  h4: {
    fontWeight: 600,
  },
  h5: {
    fontWeight: 600,
  },
  h6: {
    fontWeight: 600,
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: 28,
    fontFamily: defaultFont,
    lineHeight: 2,
  },
  cardtitle: {
    fontWeight: 800,
    fontSize: 15,
    textTransform: "uppercase",
    fontFamily: defaultFont,
  },
  inputLabel: {
    fontWeight: 800,
    fontSize: 10,
    marginBottom: "5px",
    textTransform: "uppercase",
    fontFamily: defaultFont,
    display: "block",
  },
};
