import { Box, Button, Checkbox, Container, FormControlLabel, FormLabel, Grid, Stack, TextField, Typography, Divider } from "@mui/material";
import { ArrowBackIcon } from "components/Icons/MaterialIcons";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";

import { PondManagerServices } from "api/pondManagerServices";
import moment from "moment";
import { alertsActions } from "redux/alerts";

import * as Yup from "yup";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";
import Text from "components/text/Text";
import { OutlinedTagCollections } from "components/Tags/OutlinedTag";
import CycleEndForm from "screens/Aquaculture/components/CycleManager/CycleEndForm";

export const formValidationBeforeSubmit = (form) => {
  if (moment(form.start_date) > moment(form.end_date)) {
    return {
      error: true,
      message: "Start Date must before End Date",
    };
  }
  return {
    error: false,
  };
};

const CycleManagementEditPanel = ({ cycle, pond, farm, onExit }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const ponds = pondManagementStore.ponds;

  const CycleCreationTypes = {
    NOAPPLY: "NOAPPLY",
    APPLYTOGROUP: "APPLYTOGROUP",
    APPLYTOFARM: "APPLYTOFARM",
    APPLYTOCUSTOM: "APPLYTOCUSTOM",
  };
  const [creationType, setCreationType] = useState(CycleCreationTypes.NOAPPLY);
  const [alsoApplyToPonds, setAlsoApplyToPonds] = useState([]);
  const dispatch = useDispatch();

  const isNew = _.isEmpty(cycle);
  const formValidationSchema = Yup.object().shape({
    label: Yup.string().required("Required"),
    start_date: Yup.string().required("Required"),
    // end_date: Yup.string().required("Required"),
  });

  return (
    <Box>
      {onExit && (
        <Button startIcon={<ArrowBackIcon />} onClick={onExit}>
          <Text>interface.actions.return</Text>
        </Button>
      )}
      <Container maxWidth="md">
        <Box my={2}>
          <Box mb={2}>
            {isNew ? (
              <Typography variant="cardtitle">
                <Text>interface.actions.create-a-new-cycle</Text>
              </Typography>
            ) : (
              <Box>
                <Typography>
                  <strong>
                    <Text>interface.general.cycle</Text>
                  </strong>
                  : #{cycle?.id}
                </Typography>
                <Box mt={1}>{cycle.ended ? <OutlinedTagCollections.CycleInactiveTag /> : <OutlinedTagCollections.CycleActiveTag />}</Box>
              </Box>
            )}
          </Box>
          <Formik
            onSubmit={async (values) => {
              /**
               *
               * Create New Cycle
               *
               */
              if (isNew) {
                let alsoApplyToPonds_ = [];
                if (creationType === CycleCreationTypes.APPLYTOFARM) {
                  alsoApplyToPonds_ = ponds.map((ele) => ele.id).filter((ele) => ele !== pond.id);
                } else if (creationType === CycleCreationTypes.APPLYTOGROUP) {
                  alsoApplyToPonds_ = ponds
                    .filter((ele) => ele.group === pond.group)
                    .map((ele) => ele.id)
                    .filter((ele) => ele !== pond.id);
                } else if (creationType === CycleCreationTypes.APPLYTOCUSTOM) {
                  alsoApplyToPonds_ = alsoApplyToPonds;
                }
                const formData = {
                  pond_id: pond.id,
                  label: values.label,
                  start_date: values.start_date,
                  end_date: values.end_date,
                  alsoApplyToPonds: alsoApplyToPonds_,
                };
                const formValidated = formValidationBeforeSubmit(formData);
                if (formValidated.error) {
                  alertsActions.addError(dispatch, {
                    content: formValidated.message,
                  });
                  return;
                }
                const rsp = await PondManagerServices.createCycle(formData);
                if (rsp.status === "ok") {
                  alertsActions.addInfo(dispatch, {
                    content: "Cycle is created",
                  });
                  await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm.farm_id);
                  onExit();
                } else {
                  alertsActions.addError(dispatch, { content: "Some Error" });
                }
              } else {
                /**
                 *
                 * Update an Existing Cycle
                 *
                 *
                 */
                const formData = {
                  pond_id: pond.id,
                  id: values.id,
                  label: values.label,
                  start_date: values.start_date,
                  end_date: values.end_date,
                  ended: values.ended,
                };
                const formValidated = formValidationBeforeSubmit(formData);
                if (formValidated.error) {
                  alertsActions.addError(dispatch, {
                    content: formValidated.message,
                  });
                  return;
                }
                const rsp = await PondManagerServices.updateCycle(formData);
                if (rsp.status === "ok") {
                  alertsActions.addInfo(dispatch, {
                    content: "Cycle is updated",
                  });
                  await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm.farm_id);
                  onExit();
                } else {
                  alertsActions.addError(dispatch, { content: "Some Error" });
                }
              }
            }}
            validationSchema={formValidationSchema}
            initialValues={
              isNew
                ? {
                    start_date: moment().format("YYYY-MM-DD"),
                    end_date: moment().add(6, "months").format("YYYY-MM-DD"),
                  }
                : cycle
            }
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                      <FormLabel>
                        <Text>interface.general.label</Text>{" "}
                        {errors.label && (
                          <Typography sx={{ ml: 1 }} variant="overline" color="error">
                            * {errors.label}
                          </Typography>
                        )}
                      </FormLabel>
                      <TextField variant="outlined" fullWidth id="label" value={values.label} onChange={handleChange} placeholder="e.g. 2022 #1" />
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <FormLabel>
                          <Text>interface.general.start-date</Text>
                        </FormLabel>
                      </Box>
                      <MuiDatePicker
                        value={moment(values.start_date, "YYYY-MM-DD")}
                        onChange={(newV) => {
                          setFieldValue("start_date", newV.format("YYYY-MM-DD"));
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <FormLabel>
                          <Text>interface.general.end-date</Text> {(isNew || !cycle?.ended) && " (estimated)"}
                        </FormLabel>
                      </Box>
                      <MuiDatePicker
                        value={moment(values.end_date, "YYYY-MM-DD")}
                        onChange={(newV) => {
                          setFieldValue("end_date", newV.format("YYYY-MM-DD"));
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {
                        // === Apply to farm or group
                        isNew && (
                          <Box>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                              <FormControlLabel
                                onClick={() => setCreationType(CycleCreationTypes.NOAPPLY)}
                                control={<Checkbox checked={creationType === CycleCreationTypes.NOAPPLY} />}
                                label={<Text>interface.actions.no-apply</Text>}
                              />
                              <FormControlLabel
                                onClick={() => setCreationType(CycleCreationTypes.APPLYTOGROUP)}
                                control={<Checkbox checked={creationType === CycleCreationTypes.APPLYTOGROUP} />}
                                label={<Text>interface.actions.apply-to-group</Text>}
                              />
                              <FormControlLabel
                                onClick={() => setCreationType(CycleCreationTypes.APPLYTOFARM)}
                                control={<Checkbox checked={creationType === CycleCreationTypes.APPLYTOFARM} />}
                                label={<Text>interface.actions.apply-to-farm</Text>}
                              />
                              <FormControlLabel
                                onClick={() => setCreationType(CycleCreationTypes.APPLYTOCUSTOM)}
                                control={<Checkbox checked={creationType === CycleCreationTypes.APPLYTOCUSTOM} />}
                                label={<Text>interface.actions.also-apply-to</Text>}
                              />
                            </Stack>
                          </Box>
                        )
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {creationType === CycleCreationTypes.APPLYTOCUSTOM && (
                        <Box
                          sx={{
                            p: 2,
                            border: "1px solid #eee",
                            borderRadius: 1,
                          }}
                        >
                          <Grid container>
                            {_.orderBy(ponds, "label").map((p, idx) => (
                              <FormControlLabel
                                key={idx}
                                onClick={() => {
                                  if (p.id === pond.id) return;
                                  _.includes(alsoApplyToPonds, p.id) ? setAlsoApplyToPonds(alsoApplyToPonds.filter((p_) => p_ !== p.id)) : setAlsoApplyToPonds([...alsoApplyToPonds, p.id]);
                                }}
                                control={<Checkbox disabled={p.id === pond.id} checked={p.id === pond.id || _.includes(alsoApplyToPonds, p.id)} />}
                                label={p.label}
                              />
                            ))}
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button color="error" onClick={onExit}>
                          <Text>interface.actions.cancel</Text>
                        </Button>
                        <Button type="submit" variant="contained">
                          {isNew ? <Text>interface.actions.confirm</Text> : <Text>interface.actions.update</Text>}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Divider />
          <Stack mt={4} direction="row" justifyContent="flex-end" spacing={1}>
            {!isNew && !cycle.ended && (
              <Box>
                <CycleEndForm
                  cycle={cycle}
                  onExit={async () => {
                    alertsActions.addInfo(dispatch, { content: "Cycle is ended" });
                    await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm.farm_id);
                    onExit();
                  }}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CycleManagementEditPanel;
