import { ChevronRightOutlined, ChevronRightRounded, ChevronRightSharp } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { ArrowBackIcon, ArrowForwardIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { getColorByFieldId } from "helpers/ColorHelpers";
import { formatValueByFieldId } from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import MobilePondViewDataUploader from "screens/Mobile/PondManager/components/PondView/DataManager/MobilePondViewDataUploader";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";

const DataCard = ({ data, field, onSelect }) => {
  if (!data) return {};
  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const fieldUnit = field.field_unit;

  const dataToDisplay = _.takeRight(
    data.filter((ele) => ele?.[fieldId]),
    30
  );

  const color = getColorByFieldId(fieldId);

  const latestValue = formatValueByFieldId(_.last(dataToDisplay)?.[fieldId], fieldId);
  const latestValueDt = _.last(dataToDisplay)?.datetime;

  const configs = {
    chart: {
      height: "80px",
      margin: [0, 0, 0, 0], // Removes all margins (top, right, bottom, left)
      spacing: [0, 0, 0, 0], // Removes all spacing around the chart
      animation: false,
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: fieldName,
        type: "column",
        data: dataToDisplay.map((ele, idx) => ({
          // x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
          x: idx,
          y: ele?.[fieldId],
          color: idx === dataToDisplay.length - 1 ? color : colors.grey[200],
        })),
        borderRadius: 0,
      },
    ],
  };
  // console.log(configs);
  return (
    <MobileWidgetCard padding={1}>
      <Stack direction={"row"} justifyContent={"space-between"} mb={1}>
        <Box>
          <Typography fontWeight={800} fontSize={15} color={color}>
            {fieldName} <span style={{ color: "#BBB", fontSize: 10 }}></span>
          </Typography>
        </Box>
        {onSelect && (
          <Box>
            <IconButton onClick={() => onSelect(field)} size="small">
              <ChevronRightSharp />
            </IconButton>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
        <Box>
          {latestValue ? (
            <Typography fontSize={26} fontWeight={800}>
              {latestValue}
            </Typography>
          ) : (
            <Typography fontSize={26} fontWeight={800} color="#CCC">
              <Text>interface.alert.no-data</Text>
            </Typography>
          )}
          <Typography fontSize={10} fontWeight={800} color="grey">
            {latestValueDt && TimeFormatters.formatDatetime(latestValueDt)}
          </Typography>
        </Box>
        <Box width={150}>
          <HighchartsWrapper options={configs} />
        </Box>
      </Stack>
    </MobileWidgetCard>
  );
};

const MobilePondViewDataManager = ({ pond }) => {
  const [data, setData] = useState(null);
  const [selectedField, setField] = useState(null);

  const cycleStore = AppSelectors.cycleStore();
  const pondmanagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondmanagementStore;

  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[pond?.id];

  useEffect(() => {
    if (pond?.id === selectedCycle?.pond_id || !selectedField) {
      Actions.loadData();
    }
  }, [pond?.id, selectedCycle?.id, selectedField]);

  const Actions = {
    loadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(
        pond?.id,
        ["weight", "current_density", "organisms", "biomass", "survival_rate", "ph", "water_temp", "dissolved_oxygen", "average_depth", "water_exchange"],
        selectedCycle?.start_date,
        selectedCycle?.end_date
      );
      setData(response.rows);
    },
    onSelectField: (field) => {
      setField(field);
    },
  };

  if (selectedField) {
    return (
      <Box p={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <IconButton onClick={() => setField(null)} size="small">
            <ArrowBackIcon />
          </IconButton>
        </Stack>
        <Box>
          <MobilePondViewDataUploader pond={pond} field={selectedField} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {data && (
        <Stack spacing={1} p={1}>
          <Box py={2}>
            <Typography variant="cardtitle">
              <Text>interface.general.biological</Text>
            </Typography>
          </Box>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "weight" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "current_density" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "organisms" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "biomass" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "survival_rate" }) }} />
          <Box py={2}>
            <Typography variant="cardtitle">
              <Text>interface.general.water-quality</Text>
            </Typography>
          </Box>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "ph" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "water_temp" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "dissolved_oxygen" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "average_depth" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "water_exchange" }) }} />
        </Stack>
      )}
    </Box>
  );
};

export default MobilePondViewDataManager;
