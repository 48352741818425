import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ChatBoard from "components/ChatBoard/ChatBoard";
import SenderBox from "./SenderBox";
import { chatApiMock, chatMockApis } from "mock/chatMock";
import {
  TextField,
  Stack,
  colors,
  Typography,
  Box,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { chatStoreActions } from "redux/chatSlice";
import { NoMessageFoundSvg } from "components/Images";
import palette from "themes/palette";
import PrivateChatBoardWrapper from "components/PrivateChat/PrivateChatBoardWrapper";

const PrivateChat = ({ farm, isFloatingMessage = false, chatId }) => {
  const dispatch = useDispatch();

  const { isMenuCollapsed, members, isInit } = AppSelectors.chatStore();

  const [selectedChat, setSelectedChat] = useState({});
  const [selectedChatId, setChatId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("user");

  const [chats, setChats] = useState([]);

  useEffect(() => {
    dispatch(
      chatStoreActions.init({
        farmId: farm?.farm_id,
      })
    );

    const chats_ = chatMockApis.getChats(farm?.farm_id);
    setChats(chats_);

    // handle default chatId if exists`
    // if (chatId) {
    //   const selectedChatFromId = chatApiMock.find(
    //     (messageData) => messageData.chatId === +chatId
    //   );
    //   setSelectedChat(selectedChatFromId);
    // }
  }, [chatId]);

  const Actions = {
    onSelectChat: (chatId) => {
      setChatId(chatId);
    },
  };

  const onSelectSenderBox = (selectedChat) => setSelectedChat(selectedChat);

  const filteredChats =
    chats?.filter((messages) => {
      if (searchText) {
        return messages.sender
          ?.toLowerCase()
          .includes(searchText?.toLowerCase());
      } else {
        return messages;
      }
    }) || [];

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          height: "4px",
          width: "100%",
          top: 0,
        }}
      >
        {!isInit && <LinearProgress />}
      </Box>
      <Grid
        container
        sx={{
          height: isFloatingMessage ? "100%" : "100%",
          width: isFloatingMessage ? "450px" : "100%",
          borderRadius: isFloatingMessage ? 2 : 0,
          overflow: isFloatingMessage && "hidden",
          border: isFloatingMessage && "1px solid #CCC",
        }}
      >
        {isMenuCollapsed && (
          <Grid
            item
            sx={{
              backgroundColor: "#FFF",
              overflowY: "auto",
              width: isFloatingMessage ? "150px" : "350px",
              borderRight: !isFloatingMessage && "1px solid #CCC",
            }}
          >
            <Box p={2} mb={0}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="cardtitle" fontWeight={800}>
                  Team Chat (preview)
                </Typography>
              </Stack>
            </Box>
            <div
              style={
                isFloatingMessage
                  ? {
                      backgroundColor: "#FFF",
                      height: "100%",
                      overflowY: "auto",
                      textAlign: "center",
                    }
                  : { overflowY: "auto" }
              }
            >
              {!isFloatingMessage && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{
                    margin: "20px",
                  }}
                >
                  <TextField
                    size="small"
                    type="text"
                    id="outlined-basic"
                    autoComplete="off"
                    label="Search by Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  {/* <Select
                  size="small"
                  variant="standard"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  sx={{ marginLeft: "10px", width: "100px" }}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                </Select> */}
                </Stack>
              )}
              {/* -- chat list -- */}
              <Stack spacing={1} px={1}>
                {filteredChats.map((chat) => {
                  const lastMessageIndex = chat.conversation.length - 1;
                  const lastMessage =
                    chat.conversation[lastMessageIndex]?.message;
                  const unReadMessageCount = chat.conversation?.filter(
                    (msg) => {
                      if (msg.type === "received") {
                        return msg?.seen === "notseen";
                      }
                    }
                  );
                  return (
                    <SenderBox
                      key={chat.chatId}
                      displayName={chat.sender}
                      lastMessage={lastMessage}
                      timeGap={chat.time}
                      onClick={() => Actions.onSelectChat(chat.chatId)}
                      isSelected={chat.chatId === selectedChatId}
                      isFloatingMessage={isFloatingMessage}
                      badgeCount={unReadMessageCount?.length}
                    />
                  );
                })}
              </Stack>

              {filteredChats.length === 0 && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Typography fontSize={25} color={colors.grey[500]}>
                    No chat found
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs>
          {selectedChatId ? (
            <PrivateChatBoardWrapper
              farmId={farm?.farm_id}
              chatId={selectedChatId}
              isFloatingMessage={isFloatingMessage}
              isMenuCollapsed
            />
          ) : (
            <Stack p={6} alignItems={"center"} spacing={4}>
              <img
                src={NoMessageFoundSvg}
                alt="No Message Found"
                width={"40%"}
                style={{
                  maxWidth: "500px",
                }}
              />
              <Box mt={2}>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  color={palette.secondary.main}
                >
                  Select <span style={{ color: colors.grey[800] }}>a chat</span>
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PrivateChat;
