import { PondManagerServices } from "api/pondManagerServices";
import LoadingBox from "components/Loading/LoadingBox";
import Text from "components/text/Text";
import { useEffect, useState } from "react";
import { GiHouseKeys, GiPhotoCamera, GiSettingsKnobs, GiSwapBag } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import FarmAccessControlSettings from "screens/Aquaculture/components/Farm/FarmAccessControlSettings";
import FarmMainSettings from "screens/Aquaculture/components/Farm/FarmMainSettings";
import SideMenuLayout, { SideMenuLayoutThemes } from "screens/Aquaculture/components/Layout/SideMenuLayout";
import FarmImagesSettings from "screens/Aquaculture/components/Farm/FarmImagesSettings";
import FarmProductSettings from "screens/Aquaculture/components/Farm/FarmProductSettings";
import { SettingsIcon } from "components/Icons/MaterialIcons";
import { Collections } from "@mui/icons-material";

/**
 *
 *
 * @param {} param0
 * @returns
 */
const FarmSettingsHome = () => {
  const { farmid } = useParams();
  const navigate = useNavigate();

  const [farm, setFarm] = useState(null);

  const Actions = {
    onLoadFarm: async () => {
      if (farmid) {
        const result = await PondManagerServices.getFarms();
        const farm_ = _.find(result, { farm_id: farmid });
        farm_ && setFarm(farm_);
      }
    },
  };

  useEffect(() => {
    Actions.onLoadFarm();
  }, []);

  const MENULIST = [
    {
      label: <Text>interface.general.settings</Text>,
      description: "",
      value: 0,
      icon: <SettingsIcon />,
      component: <FarmMainSettings farm={farm} farmid={farmid} />,
    },
    // {
    //   label: "Boundary",
    //   description: "",
    //   value: 1,
    //   icon: <SettingsIcon />,
    //   component: <FarmBoundarySetting farm={farm} />,
    // },
    {
      label: <Text>interface.general.farming-products</Text>,
      description: "",
      value: 2,
      icon: <GiSwapBag />,
      component: <FarmProductSettings farm={farm} farmid={farmid} />,
    },
    {
      label: <Text>interface.general.gallery</Text>,
      description: "",
      value: 3,
      icon: <Collections />,
      component: <FarmImagesSettings farmId={farmid} />,
    },
    {
      label: <Text>interface.access-control.access-control</Text>,
      description: "",
      value: 4,
      icon: <GiHouseKeys />,
      component: <FarmAccessControlSettings farm={farm} />,
    },
  ];

  if (farm) {
    return (
      <SideMenuLayout
        theme={SideMenuLayoutThemes.buttons}
        menulist={MENULIST}
        defaultViewMode={0}
        styles={{
          height: "100%",
        }}
        actions={{
          back: () => {
            navigate(`/aqua/pond-manager/${farmid}`);
          },
        }}
      />
    );
  }
  return <LoadingBox />;
};

export default FarmSettingsHome;
