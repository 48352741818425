import React from "react";
import { typography } from "themes/typography";
import palette from "themes/palette";

import Highcharts from "highcharts";
import Highstock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreemap from "highcharts/modules/treemap";
import HighchartsBullet from "highcharts/modules/bullet";
import HighchartsHistogram from "highcharts/modules/histogram-bellcurve";
import Highcharts3d from "highcharts/highcharts-3d";
import { colors } from "@mui/material";
import { duration } from "moment";

HighchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreemap(Highcharts);
HighchartsHistogram(Highcharts);
HighchartsBullet(Highcharts);
Highcharts3d(Highcharts);

const globalOptions = {
  chart: {
    backgroundColor: "transparent",
    style: {
      fontFamily: typography.fontFamily,
      fontSize: "13px",
    },
  },
  title: {
    style: {
      color: palette.primary.main,
      fontSize: "15px",
    },
    text: "",
    margin: 20,
    align: "left",
    y: 20, //  this to move y-coordinate of title to desired location
  },
  credits: { enabled: false },
  colors: [
    "#1565C0",
    "#F9A825",
    "#D84315",
    "#43A047",
    "#00ACC1",
    "#9E9D24",
    "#6D4C41",
    "#4527A0",

    "#0277BD",
    "#FBC02D",
    "#E64A19",
    "#00838F",
    "#2E7D32",
    "#AFB42B",
    "#795548",
    "#512DA8",

    "#0D47A1",
    "#006064",
    "#1B5E20",
    "#827717",
    "#F57F17",
    "#BF360C",
    "#5D4037",
    "#311B92",
  ],
  tooltip: {
    animation: {
      duration: 2000,
    },
    valueDecimals: 2,
    borderRadius: 10,
    shadow: false,
    backgroundColor: "#ffffffcc",
    borderColor: "#d9d9d9",
    borderWidth: 1,
    style: {
      color: "#000000",
    },
    hideDelay: 0,
    useHTML: true,
    headerFormat: '<div style="font-size: 10px; font-weight: 800; margin-bottom: 12px;">X: {point.key}</div><table style="border-collapse: collapse;">',
    pointFormat: `<tr>
          <td><div style="padding: 0; margin-right: 3px; background-color: {series.color}; color: #FFFFFF; width: 2px; height: 10px;"></div></td>
          <td style="padding: 0; margin-right: 6px; color: {series.color}; font-weight: 800; font-size: 10px;">{series.name}</td>
          <td style="padding-left: 10px;"><b>{point.y}</b></td>
        </tr>`,
    footerFormat: "</table>",
  },
  // L E G E N D
  legend: {
    symbolPadding: 2,
    padding: 6,
    margin: 20,
    align: "left",
    verticalAlign: "top",
    borderWidth: 0,
    borderColor: "#DDD",
    borderRadius: 10,
    itemStyle: {
      fontSize: "0.8em",
      fontWeight: "800",
      color: colors.grey[600],
    },
  },
  plotOptions: {
    series: {
      marker: {
        radius: 2,
        enabledThreshold: 5,
      },
      animation: {
        duration: 500,
      },
    },
    scatter: {
      lineWidth: 0,
    },
    line: {
      lineWidth: 1,
    },
    area: {
      lineWidth: 1,
    },
    areasplit: {
      lineWidth: 1,
    },
  },
};

Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
  return ["M", x, y, "L", x + w, y + h, "M", x + w, y, "L", x, y + h, "z"];
};

Highcharts.setOptions(globalOptions);

const HighchartsWrapper = ({ options }) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

Highstock.setOptions(globalOptions);

export const HighstockWrapper = ({ options }) => {
  return <HighchartsReact highcharts={Highstock} constructorType={"stockChart"} options={options} />;
};

export default HighchartsWrapper;
