import { Box, IconButton, Stack, Typography } from "@mui/material";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { DeleteIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import AppLocalStorage from "helpers/LocalStorage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import palette from "themes/palette";

/**
 * A small widget shows recent viewed farms
 *
 * @param {*} param0
 * @returns
 */
export const RecentViewedFarms = ({ farms }) => {
  const [viewedItems, setViewedItems] = useState([]);
  const local = new AppLocalStorage();

  useEffect(() => {
    const recentViewedFarmIds = local.getRecentViewedFarmIds() || [];
    const validFarms = recentViewedFarmIds.map((ele) => _.find(farms, { farm_id: ele })).filter((ele) => ele);
    setViewedItems(validFarms);
  }, [farms]);

  const navigate = useNavigate();

  const Actions = {
    onClean: () => {
      local.removeAllRecentViewedFarmIds();
      setViewedItems([]);
    },
  };

  if (viewedItems.length === 0) return "";

  return (
    <Box>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Typography fontWeight={800} fontSize={11}>
          <Text>interface.general.recent</Text>:
        </Typography>
        {viewedItems.map((ele, idx) => (
          <Box
            key={idx}
            p={1}
            sx={{
              position: "relative",
              border: "1px solid #ccc",
              transition: "all .3s",
              overflow: "hidden",
              borderRadius: 3,
              "&:hover": {
                fontWeight: 800,
                cursor: "pointer",
                bgcolor: palette.primary.main,
                color: palette.primary.light,
              },
            }}
            onClick={() => {
              navigate(`${ROUTE_POND_MANAGER_HOME_PAGE}/${ele.farm_id}`);
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              sx={{
                zIndex: 99,
              }}
            >
              <CountryFlagSvgIcon countryCode={ele.country_code} />
              <Typography fontWeight={800} fontSize={11} color="main">
                {ele.label}
              </Typography>
            </Stack>
          </Box>
        ))}
        <Box>
          <IconButton onClick={Actions.onClean}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};
