import { AccountTreeIcon, BusinessIcon } from "components/Icons/MaterialIcons";
import FloatingMessageBoard from "components/PrivateChat/FloatingMessageBoard";
import PrivateChat from "components/PrivateChat/PrivateChat";
import Text from "components/text/Text";
import { Roles } from "configs/appConfigs";
import Interface from "layout/Interface";
import MainBoardHome from "layout/MainBoardHome";
import { useEffect } from "react";
import { GiShrimp, GiSwapBag } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { organisationManagementActions } from "redux/pondManagement/organisationManagement";
import { pathologyManagementActions } from "redux/pondManagement/pathologyManagement";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import {
  ROUTE_CONFIRM_EMAIL,
  ROUTE_EMAIL_NOT_VERIFIED,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_GOOGLE_SIGNED_IN,
  ROUTE_LOGIN,
  ROUTE_MICROSOFT_SIGNED_IN,
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGNUP_FOR_INTERNAL,
  ROUTE_SIGNUP_FOR_SHRIMPL,
} from "routes/paths";
import AquaFieldsManager from "screens/Admin/AquaCulture/AquaFieldsManager";
import ProducerHome from "screens/Admin/FarmingProduct/ProducerHome";
import ProductHome from "screens/Admin/FarmingProduct/ProductHome";
import PondConfirmation from "screens/Admin/PondConfirmation";
import Users from "screens/Admin/Users";
import ClientManager from "screens/Aquaculture/Organisations/ClientManager";
import ClientManagerHome from "screens/Aquaculture/Organisations/ClientManagerHome";
import OrganisationAdminPage from "screens/Aquaculture/Organisations/OrganisationAdminPage";
import OrganisationCreatePage from "screens/Aquaculture/Organisations/OrganisationCreatePage";
import OrganisationHome from "screens/Aquaculture/Organisations/OrganisationHome";
import PondManager from "screens/Aquaculture/PondManager";
import FarmSettingsHome from "screens/Aquaculture/components/Farm/FarmSettingsHome";
import PondManagerFarmEditPage from "screens/Aquaculture/components/PondManager/PondManagerFarmEditPage";
import PondManagerHomePage from "screens/Aquaculture/components/PondManager/PondManagerHomePage";
import BomOnline from "screens/AustralianWaterMarket/BomOnline";
import EntitlementOrders from "screens/AustralianWaterMarket/EntitlementOrders";
import WaterMarketDashboard from "screens/AustralianWaterMarket/WaterMarketDashboard";
import WaterOverview from "screens/AustralianWaterMarket/WaterOverview";
import WaterReport from "screens/AustralianWaterMarket/WaterReport";
import HomePage from "screens/HomePage";
import WaterFundPerformance from "screens/LivePerformance/WaterFundPerformance";
import ConfirmEmail from "screens/Login/ConfirmEmail";
import EmailNotVerified from "screens/Login/EmailNotVerified";
import ForgotPassword from "screens/Login/ForgotPassword";
import GoogleSignedIn from "screens/Login/GoogleSignedIn";
import Login from "screens/Login/Login";
import MicrosoftSignedIn from "screens/Login/MicrosoftSignedIn";
import ResetPassword from "screens/Login/ResetPassword";
import Signup from "screens/Login/Signup";
import NotFoundPage from "screens/NotFoundPage";
import DesignGuide from "screens/Other/DesignGuide";
import OrganisationLandingPage from "screens/Public/OrganisationLandingPage";
import UserProfile from "screens/User/UserProfile";
// Other
import UserRoles from "constants/UserRoles";
import { FaTable, FaUsers } from "react-icons/fa";
import { MdInventory } from "react-icons/md";
import { isMobile } from "themes/helpers";
import MobilePondManagerHome from "screens/Mobile/PondManager/MobilePondManagerHome";
import MobilePondManager from "screens/Mobile/PondManager/MobilePondManager";
import MobileHomePage from "screens/Mobile/Home/MobileHomePage";
import UtilsHome from "screens/Dev/UtilsHome";
import { BiWorld } from "react-icons/bi";
import AquaExplorer from "screens/Aquaculture/AquaExplorer/AquaExplorer";
import MobilePondManagerPondView from "screens/Mobile/PondManager/MobilePondManagerPondView";

/**
 * The main routes for the entire application
 *
 * TODO:
 * 1. role based route render
 *
 * @returns
 */
const AppRoutes = () => {
  /**   H O O K S   */
  // hook: dispatch
  const dispatch = useDispatch();

  const userStore = AppSelectors.userStore();
  const { isValid } = userStore;

  const GlobalPreload = async () => {
    // Load All Farms
    await pondManagementActions.loadAllFarms(dispatch);

    // Load Pathology Data
    await pathologyManagementActions.initData(dispatch);

    // Load Organisations
    await organisationManagementActions.loadOrgs(dispatch);
  };

  useEffect(() => {
    /** load all farms owned by the user */
    isValid && GlobalPreload();
  }, [isValid]);

  const mobileMode = isMobile();

  /**
   *
   * ///////////
   * M O B I L E
   * ///////////
   *
   */
  if (mobileMode) {
    return (
      <Routes>
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route path={ROUTE_EMAIL_NOT_VERIFIED} element={<EmailNotVerified />} />
        <Route path={ROUTE_CONFIRM_EMAIL} element={<ConfirmEmail />} />
        <Route path={ROUTE_MICROSOFT_SIGNED_IN} element={<MicrosoftSignedIn />} />
        <Route path={ROUTE_GOOGLE_SIGNED_IN} element={<GoogleSignedIn />} />
        <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={"design-guide"} element={<DesignGuide />} />
        <Route path={ROUTE_SIGNUP_FOR_SHRIMPL} element={<Signup source={UserRoles.SHRIMPL_USER} />} />
        <Route path={ROUTE_SIGNUP_FOR_INTERNAL} element={<Signup source={Roles.INTERNAL} />} />
        <Route path="/" element={<Interface title="Home" key="interface--home" />}>
          <Route path="" element={<MobileHomePage />} />
        </Route>

        {/* ----- A Q U A C U L T U R E ------ */}
        <Route path="aqua" element={<Interface key="interface--aquaculture" title={<Text>interface.general.aquaculture</Text>} />}>
          <Route
            path=""
            element={
              <MainBoardHome
                title={<Text>interface.general.aquaculture</Text>}
                list={[
                  {
                    name: <Text>interface.general.my-farms</Text>,
                    path: "/pond-manager",
                    icon: <GiShrimp />,
                  },
                ]}
                navigateToFirst
              />
            }
          />
          {/* ----- P O N D   M A N A G E R ------ */}
          <Route path="pond-manager">
            <Route path={""} element={<MobilePondManagerHome />} index></Route>
            {/* <Route path={"create"} element={<PondManagerFarmEditPage />} /> */}
            {/* <Route path={`edit/:farmid`} element={<FarmSettingsHome />} /> */}
            <Route path=":farmid" element={<MobilePondManager />}></Route>
          </Route>

          <Route path="orgs">
            <Route index element={<OrganisationHome />}></Route>
            <Route path={"create"} element={<OrganisationCreatePage />} />
            <Route path={"admin/:orgId"} element={<OrganisationAdminPage />} />
          </Route>

          <Route path="client-manager">
            <Route index element={<ClientManagerHome />}></Route>
            <Route path=":orgid" element={<ClientManagerHome />}></Route>
            <Route path=":orgid/:farmid" element={<ClientManager />}>
              <Route path=":page"></Route>
              <Route path=":page/:subpage"></Route>
            </Route>
          </Route>
        </Route>

        {/* ----- A C C O U N T ------ */}
        <Route path="/settings" element={<Interface key="interface--account" title={<Text>interface.general.settings</Text>} />}>
          <Route
            path=""
            element={
              <MainBoardHome
                title={<Text>interface.general.settings</Text>}
                list={[
                  {
                    name: <Text>interface.general.profile</Text>,
                    path: "/profile",
                    faClass: "fal fa-user",
                  },
                ]}
                navigateToFirst
              />
            }
          />
          <Route path="profile" element={<UserProfile />} />
        </Route>

        {/* ----- A D M I N ------ */}

        <Route path="/au-water" element={<Interface title="Australian Water Market" requiredRoles={[Roles.INTERNAL]} />}>
          <Route
            path=""
            element={
              <MainBoardHome
                title="Australian Water Market"
                list={[
                  {
                    name: "Market Map Dashboard",
                    path: "/water_map_dashboard",
                    faClass: "fal fa-map-marked-alt",
                  },
                  {
                    name: "Market Dashboard",
                    path: "/market_dashboard",
                    faClass: "fal fa-chart-line",
                  },
                  {
                    name: "Auctions",
                    path: "/auction",
                    faClass: "fal fa-gavel",
                  },
                  {
                    name: "Monthly Report",
                    path: "/water_market_report",
                  },
                  {
                    name: "AIP Trading Performance",
                    path: "/water_performance",
                    faClass: "fal fa-chart-bar",
                  },
                  {
                    name: "Australia BOM Data",
                    path: "/bom",
                    faClass: "fal fa-chart-bar",
                  },
                ]}
              />
            }
          />
          <Route path="water_map_dashboard" element={<WaterOverview />} />
          <Route path="market_dashboard" element={<WaterMarketDashboard />} />
          <Route path="auction" element={<EntitlementOrders />} />
          <Route path="water_market_report" element={<WaterReport />} />
          <Route path="water_performance" element={<WaterFundPerformance />} />
          <Route path="bom" element={<BomOnline />} />
        </Route>

        <Route path="/admin" element={<Interface title="Admin" requiredRoles={[Roles.INTERNAL]} />}>
          <Route
            path=""
            element={
              <MainBoardHome
                title="Shrimpl Admin"
                list={[
                  {
                    name: "Users",
                    path: "/users",
                    icon: <FaUsers />,
                  },
                  {
                    name: "Data Fields",
                    path: "/aquafieldsmanager",
                    icon: <FaTable />,
                  },
                  {
                    name: "Farming Products",
                    path: "/farming-products",
                    icon: <MdInventory />,
                  },
                ]}
              />
            }
          />
          <Route path="aquafieldsmanager" element={<AquaFieldsManager />} />
          <Route path="pondconfirm" element={<PondConfirmation />} />
          <Route path="users" element={<Users />} />
          <Route path="floatMessage" element={<FloatingMessageBoard />} />
          <Route path="farming-products">
            <Route index element={<ProducerHome />}></Route>
            <Route path=":producerid" element={<ProductHome />}></Route>
          </Route>
        </Route>

        {/* ----- p u b l i c ------ */}
        <Route path="public">
          <Route path="orgs/:orgId" element={<OrganisationLandingPage />}></Route>
        </Route>

        <Route path="chat" element={<PrivateChat />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  /**
   *
   * /////////////
   * D E S K T O P
   * /////////////
   *
   */

  return (
    <Routes>
      <Route path={ROUTE_LOGIN} element={<Login />} />
      <Route path={ROUTE_EMAIL_NOT_VERIFIED} element={<EmailNotVerified />} />
      <Route path={ROUTE_CONFIRM_EMAIL} element={<ConfirmEmail />} />
      <Route path={ROUTE_MICROSOFT_SIGNED_IN} element={<MicrosoftSignedIn />} />
      <Route path={ROUTE_GOOGLE_SIGNED_IN} element={<GoogleSignedIn />} />
      <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={"design-guide"} element={<DesignGuide />} />
      <Route path={ROUTE_SIGNUP_FOR_SHRIMPL} element={<Signup source={UserRoles.SHRIMPL_USER} />} />
      <Route path={ROUTE_SIGNUP_FOR_INTERNAL} element={<Signup source={Roles.INTERNAL} />} />
      <Route path="/" element={<Interface title="Home" key="interface--home" />}>
        <Route path="" element={<HomePage />} />
      </Route>

      {/* ----- A Q U A C U L T U R E ------ */}
      <Route path="aqua" element={<Interface key="interface--aquaculture" title={<Text>interface.general.aquaculture</Text>} />}>
        <Route
          path=""
          element={
            <MainBoardHome
              title={<Text>interface.general.aquaculture</Text>}
              list={[
                {
                  name: <Text>interface.general.my-farms</Text>,
                  path: "/pond-manager",
                  icon: <GiShrimp />,
                },
                {
                  name: <Text fallback={"Client Manager"}>interface.general.client-manager</Text>,
                  path: "/client-manager",
                  icon: <AccountTreeIcon />,
                },
                {
                  name: <Text>interface.general.organisations</Text>,
                  path: "/orgs",
                  icon: <BusinessIcon />,
                },
              ]}
            />
          }
        />
        {/* ----- P O N D   M A N A G E R ------ */}
        <Route path="pond-manager">
          <Route path={""} element={<PondManagerHomePage />} index></Route>
          <Route path={"create"} element={<PondManagerFarmEditPage />} />
          <Route path={`edit/:farmid`} element={<FarmSettingsHome />} />
          <Route path=":farmid" element={<PondManager />}>
            <Route path=":page"></Route>
          </Route>
        </Route>

        <Route path="orgs">
          <Route index element={<OrganisationHome />}></Route>
          <Route path={"create"} element={<OrganisationCreatePage />} />
          <Route path={"admin/:orgId"} element={<OrganisationAdminPage />} />
        </Route>

        <Route path="client-manager">
          <Route index element={<ClientManagerHome />}></Route>
          <Route path=":orgid" element={<ClientManagerHome />}></Route>
          <Route path=":orgid/:farmid" element={<ClientManager />}>
            <Route path=":page"></Route>
            <Route path=":page/:subpage"></Route>
          </Route>
        </Route>
      </Route>

      {/* ----- A C C O U N T ------ */}
      <Route path="/settings" element={<Interface key="interface--account" title={<Text>interface.general.settings</Text>} />}>
        <Route
          path=""
          element={
            <MainBoardHome
              title={<Text>interface.general.settings</Text>}
              list={[
                {
                  name: <Text>interface.general.profile</Text>,
                  path: "/profile",
                  faClass: "fal fa-user",
                },
              ]}
            />
          }
        />
        <Route path="profile" element={<UserProfile />} />
      </Route>

      {/* ----- A D M I N ------ */}

      <Route path="/au-water" element={<Interface title="Australian Water Market" requiredRoles={[Roles.INTERNAL]} />}>
        <Route
          path=""
          element={
            <MainBoardHome
              title="Australian Water Market"
              list={[
                {
                  name: "Market Map Dashboard",
                  path: "/water_map_dashboard",
                  faClass: "fal fa-map-marked-alt",
                },
                {
                  name: "Market Dashboard",
                  path: "/market_dashboard",
                  faClass: "fal fa-chart-line",
                },
                {
                  name: "Auctions",
                  path: "/auction",
                  faClass: "fal fa-gavel",
                },
                {
                  name: "Monthly Report",
                  path: "/water_market_report",
                },
                {
                  name: "AIP Trading Performance",
                  path: "/water_performance",
                  faClass: "fal fa-chart-bar",
                },
                {
                  name: "Australia BOM Data",
                  path: "/bom",
                  faClass: "fal fa-chart-bar",
                },
              ]}
            />
          }
        />
        <Route path="water_map_dashboard" element={<WaterOverview />} />
        <Route path="market_dashboard" element={<WaterMarketDashboard />} />
        <Route path="auction" element={<EntitlementOrders />} />
        <Route path="water_market_report" element={<WaterReport />} />
        <Route path="water_performance" element={<WaterFundPerformance />} />
        <Route path="bom" element={<BomOnline />} />
      </Route>

      <Route path="/admin" element={<Interface title="Admin" requiredRoles={[Roles.INTERNAL]} />}>
        <Route
          path=""
          element={
            <MainBoardHome
              title="Shrimpl Admin"
              list={[
                {
                  name: "Users",
                  path: "/users",
                  icon: <FaUsers />,
                },
                {
                  name: "Data Fields",
                  path: "/aquafieldsmanager",
                  icon: <FaTable />,
                },
                {
                  name: "Farming Products",
                  path: "/farming-products",
                  icon: <MdInventory />,
                },
                {
                  name: "Aqua Explorer",
                  path: "/aqua-exp",
                  icon: <BiWorld />,
                },
              ]}
            />
          }
        />
        <Route path="aquafieldsmanager" element={<AquaFieldsManager />} />
        <Route path="pondconfirm" element={<PondConfirmation />} />
        <Route path="users" element={<Users />} />
        <Route path="floatMessage" element={<FloatingMessageBoard />} />
        <Route path="aqua-exp" element={<AquaExplorer />} />
        <Route path="farming-products">
          <Route index element={<ProducerHome />}></Route>
          <Route path=":producerid" element={<ProductHome />}></Route>
        </Route>
      </Route>

      {/* ----- p u b l i c ------ */}
      <Route path="public">
        <Route path="orgs/:orgId" element={<OrganisationLandingPage />}></Route>
      </Route>
      <Route path="dev">
        <Route path="utils" element={<UtilsHome />} />
      </Route>

      <Route path="chat" element={<PrivateChat />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
