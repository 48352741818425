import { Box, FormControl, IconButton, InputLabel, NativeSelect, Stack, Typography } from "@mui/material";
import AppLocalStorage from "helpers/LocalStorage";
import _ from "lodash";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import { ArrowBackIcon, CircleIcon, HomeIcon, StorageIcon } from "components/Icons/MaterialIcons";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import MobilePondViewHome from "screens/Mobile/PondManager/components/PondView/PondViewHome/MobilePondViewHome";
import MobilePondViewDataManager from "screens/Mobile/PondManager/components/PondView/DataManager/MobilePondViewDataManager";
import Text from "components/text/Text";

const appStorage = new AppLocalStorage();

/**
 *
 * Ponds Management System Main
 *
 * @returns
 */

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.SATELLITE,
    // minZoom: 11,
    // maxZoom: 18,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const PageViews = {
  home: {
    label: "Home",
    value: "home",
    icon: <HomeIcon />,
  },
  data_manager: {
    label: "Data Manager",
    value: "data_manager",
    icon: <StorageIcon />,
  },
};

const MobilePondManagerPondView = ({ ponds }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ~~~ Redux ~~~

  const pondManagementStore = AppSelectors.pondManagementStore();
  // const ponds = pondManagementStore.ponds;
  const pondid = pondManagementStore.pondid;
  const selectedPond = _.find(ponds, { id: pondid });
  const selectedPondLabel = selectedPond?.label;
  const selectedPondArea = selectedPond?.area;

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[selectedPond?.id];
  const cyclesForPond = cycleStore.cycles?.[selectedPond?.id];

  const [selectedView, setView] = useState(PageViews.home.value);

  const Actions = {
    onPageReturn: () => {
      pondManagementActions.selectPond(dispatch, { pondid: null });
    },
    onChangePond: (newPondId) => {
      pondManagementActions.selectPond(dispatch, { pondid: newPondId });
      cycleManagementActions.onChangeCycle(dispatch, null);
    },
    onChangeCycle: (newCycleId) => {
      cycleManagementActions.onChangeCycle(dispatch, _.find(cyclesForPond, { id: newCycleId }));
    },
  };

  return (
    <Box>
      <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"} p={1}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            p: 1,
            bgcolor: "var(--color-light-background)",
          }}
        >
          {selectedPond && (
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Box>
                <IconButton size="small" onClick={Actions.onPageReturn}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <Box sx={{ position: "absolute", top: -10 }}>
                    <Typography fontSize={10} color="grey">
                      <Text>interface.general.pond</Text>
                    </Typography>
                  </Box>
                  <NativeSelect
                    value={pondid}
                    onChange={(e) => {
                      Actions.onChangePond(e.target.value);
                    }}
                    sx={{
                      minWidth: 150,
                    }}
                  >
                    {ponds.map((ele, idx) => (
                      <option key={idx} value={ele.id}>
                        {ele.group} • {ele.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <Box sx={{ position: "absolute", top: -10 }}>
                    <Typography fontSize={10} color="grey">
                      <Text>interface.general.cycle</Text>
                    </Typography>
                  </Box>
                  <NativeSelect
                    value={selectedCycle?.id}
                    onChange={(e) => {
                      Actions.onChangeCycle(e.target.value);
                    }}
                    sx={{
                      minWidth: 150,
                    }}
                  >
                    {cyclesForPond.map((ele, idx) => (
                      <option key={idx} value={ele.id}>
                        {ele.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* ------- Functions Menu */}
      <Stack direction={"row"} justifyContent={"space-between"} pt={5}>
        <Box px={1}>
          <Typography fontSize={10} color="#999">
            {selectedPond?.group} • {selectedPond?.label}
          </Typography>
          <Typography fontSize={10} color="#999">
            <CircleIcon sx={{ fontSize: 8, color: selectedCycle?.active ? "green" : "grey" }} /> {selectedCycle?.label} • {selectedCycle?.start_date} ~ {selectedCycle?.end_date}
          </Typography>
        </Box>
        <MuiTabs theme={MuiTabsThemes.icon} options={[PageViews.home, PageViews.data_manager]} value={selectedView} onNewValue={setView} />
      </Stack>

      {/* ------- Render Pages */}
      {selectedView === PageViews.home.value && <MobilePondViewHome key={selectedPond?.id} pond={selectedPond} />}
      {selectedView === PageViews.data_manager.value && <MobilePondViewDataManager pond={selectedPond} />}
    </Box>
  );
};

export default MobilePondManagerPondView;
