import React from "react";
import styled from "styled-components";

import { AppSettings } from "helpers/LocalStorage";

import ShrimplHomePage from "screens/HomPages/ShrimplHomePage";
import { Roles } from "configs/appConfigs";
import AdminHomePage from "screens/HomPages/AdminHomePage";
import UserRoles from "constants/UserRoles";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { AppSelectors } from "redux/AppReducers";
import { PersonIcon } from "components/Icons/MaterialIcons";
import { Fade } from "react-reveal";

// ===============
// --+-- CSS --+--
// ===============

const Layout = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;

  background: var(--color-light-background);

  .hp_fullpage {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .hp_section {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
  }

  .hp_spotlight {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "l r";
    background: var(--color-light-background);
    height: 600px;
  }

  .hp_spotlight_img {
    background-position: center;
    background-size: cover;
  }

  @keyframes backgroundScaleIn {
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 105%;
    }
  }

  @keyframes backgroundFadeIn {
    0% {
      background: var(--main-color);
    }
    100% {
      background: var(--main-color-o);
    }
  }

  @keyframes goDownEffect {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const MobileHomePage = () => {
  // redux
  const userStore = AppSelectors.userStore();

  return (
    <Box p={2}>
      <Stack spacing={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Fade left duration={500} distance={"40px"}>
            <Typography fontWeight={800} fontSize={30} lineHeight={1.2} color={"primary"}>
              Welcome back
              <br />
              {userStore.firstname}!
            </Typography>
          </Fade>
          <Fade right duration={500} distance={"40px"}>
            <Avatar
              sx={{
                width: 70,
                height: 70,
                border: "2px solid #aaa",
              }}
            >
              <PersonIcon sx={{ fontSize: 40 }} />
            </Avatar>
          </Fade>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MobileHomePage;
