import React, { useState } from "react";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";

import Text, { getText } from "components/text/Text";
import { PhotoIcon, TextSnippetIcon } from "components/Icons/MaterialIcons";
import FarmDataRoom from "screens/Aquaculture/components/Farm/FarmDataRoom";
import PhotoLogs from "screens/Aquaculture/components/Files/PhotoLogs";

const FilesHome = ({ farm, pond }) => {
  const MENULIST = [
    {
      label: <Text>interface.general.farm-records</Text>,
      description: "",
      value: 0,
      icon: <TextSnippetIcon />,
      component: <FarmDataRoom />,
    },
    {
      label: <Text>interface.general.photo-logs</Text>,
      description: "",
      value: 1,
      icon: <PhotoIcon />,
      component: <PhotoLogs pond={pond} farm={farm} />,
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={getText("interface.general.files")} defaultViewMode={0} />;
};

export default FilesHome;
