import { Box, Grid, Stack, Typography } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import _ from "lodash";
import { useState } from "react";
import Formatters from "helpers/Formatters";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { ChartColorsRoundRobinDark, ChartColorsRoundRobinGetter } from "helpers/constants";
import NumericFormatters from "helpers/NumericFormatters";
import Text from "components/text/Text";

/**
 *
 * productIdField:
 * - additive_product
 * - feed_product
 * - pond_treatment_product
 *
 * productUsageField
 * - feed_daily
 * - additive_product_usage
 * - pond_treatment_usage
 *
 * @param {*} param0
 * @returns
 */
const FarmingProductTimeseriesChart = ({ data, cycle }) => {
  const [viewProductId, setViewProductId] = useState(null);

  const chartConfigs = {
    chart: {
      height: "300px",
      animation: {
        duration: 0,
      },
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      title: {
        text: "Usage",
      },
    },
    plotOptions: {
      series: {
        stacking: "overlap",
      },
      column: {
        /* Here is the setting to limit the maximum column width. */
        maxPointWidth: 30,
        grouping: true,
      },
    },
    series: _.take(data, 10)
      .filter((ele) => (!viewProductId ? true : ele.productId === viewProductId))
      .map((ele, idx) => ({
        name: ele?.product?.name,
        type: "column",
        color: ele.color,
        data: ele.rows.map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.usage]),
      })),
  };

  const Actions = {
    handleViewProductChang: (productId) => {
      if (viewProductId && viewProductId === productId) {
        setViewProductId(null);
      } else {
        setViewProductId(productId);
      }
    },
  };

  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
      <Stack spacing={1}>
        <Typography fontSize={11}>
          <Text>interface.general.total-usage</Text>:
        </Typography>
        <Grid container spacing={0.5}>
          {data.map((ele) => (
            <Grid item xs={12} md={4} lg={3}>
              <Stack
                direction={"row"}
                spacing={0.4}
                alignItems={"center"}
                onClick={() => Actions.handleViewProductChang(ele?.productId)}
                sx={{
                  bgcolor: viewProductId === ele.productId && "#EEE",
                  borderRadius: 2,
                  pl: 0.5,
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <CircleIcon
                  sx={{
                    fontSize: 10,
                    color: ele.color,
                  }}
                />
                <Typography fontSize={11}>{ele?.product?.name}:</Typography>
                <Typography fontSize={11}>
                  <strong>{NumericFormatters.format({ value: ele?.stats?.total })}</strong>
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default FarmingProductTimeseriesChart;
