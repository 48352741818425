import React, { useEffect, useState, useRef } from "react";
import { colors, Typography, Box, Stack, LinearProgress, Grid } from "@mui/material";

import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import { calculatePercentageChange } from "helpers/Utils";
import { PondManagerLayoutStyles } from "../PondManagerMapView";
import { PondManagerServices } from "api/pondManagerServices";
import axios from "axios";
import { AppSelectors } from "redux/AppReducers";
import DataExplorerSideBar from "screens/Aquaculture/components/DataExplorer/DataExplorerSideBar";
import DataExplorerView from "screens/Aquaculture/components/DataExplorer/DataExplorerView";
import DataExplorerSummary from "screens/Aquaculture/components/DataExplorer/DataExplorerSummary";
import Text from "components/text/Text";
import TimeFormatters from "helpers/TimeFormatters";
import palette from "themes/palette";
import DataExplorerMultiPondView from "screens/Aquaculture/components/DataExplorer/DataExplorerMultiPondView";

export const CHART_TYPES = {
  LINE: "line",
  BAR: "bar",
  TABLE: "table",
  CALENDAR: "calendar",
};

/**
 *
 * Pond Manager Data Viewer (AKA "Data Explorer")
 *
 * @param {object} pond - pond data (under single-pond mode)
 * @param {list} ponds - list of ponds (under multi-pond mode)
 * @returns {React.Component}
 */
const DataExplorer = ({ farm, pond, ponds, field, isMultiMode, defaultField, defaultFieldType }) => {
  const cycleManagementStore = AppSelectors.cycleStore();
  const cycles = _.orderBy(cycleManagementStore.cycles[pond?.id] || [], "start_date", "desc");
  const selectedCycle = cycleManagementStore.selectedCycle;
  const selectedDate = cycleManagementStore.selectedDate;

  const pondManagementStore = AppSelectors.pondManagementStore();
  const fields = pondManagementStore.fields;

  const [isLoading, setLoading] = useState(false);
  const [selectedField, setField] = useState(defaultField || {});
  const [referenceFields, setReferenceFields] = useState([]);

  const [pondData, setPondData] = useState([]);
  const [pondsData, setPondsData] = useState([]);
  const [pondReferenceData, setPondReferenceData] = useState([]);
  const [chartType, setChartType] = useState(CHART_TYPES.LINE);

  const [referenceFieldsMode, setReferenceFieldsMode] = useState(false);
  const [aggregatorMode, setAggregatorMode] = useState("");

  /** Used to ponds selections update -- [Multi Mode] */
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevPonds = usePrevious(ponds);

  // ============== SINGLE MODE

  /** handle cycle update */
  useEffect(() => {
    pond && !isMultiMode && Actions.singleMode.onSelectField(selectedField || field);
  }, [selectedCycle]);

  // USE EFFECT :: referenceFields update
  useEffect(() => {
    // trigger only when
    // - pond is selected
    // - cycle is defined
    // - referenceFieldsChanged

    if (pond && selectedCycle?.pond_id === pond?.id && referenceFields.length > 0) {
      Actions.singleMode.onLoadingRefereceData();
    }
  }, [pond, referenceFields, selectedCycle]);

  // ============== MULTI MODE

  /** fire when pond selections are updated - [Multi Mode] */
  useEffect(() => {
    // NOTE: this could be fired even in Single Mode
    // TODO: is it possible that we could fix it?
    if (isMultiMode && ponds && !_.isEmpty(selectedField)) {
      Actions.multiMode.onHandleUpdatePonds(ponds);
    }
  }, [ponds, selectedDate]);

  useEffect(() => {
    !isMultiMode ? Actions.singleMode.onSelectField(selectedField || field) : Actions.multiMode.onSelectField();
  }, [selectedField]);

  const Actions = {
    singleMode: {
      onSelectField: async (field) => {
        if (_.isEmpty(field)) return;

        // determine if it's farm field
        const isFarmField = field.field_type === "farm";
        // determine if it's composite view (no cycle selected but cycles exist)
        const isComposite = _.isEmpty(selectedCycle) && cycles.length > 0;

        const farmId = farm.farm_id;
        setLoading(true);

        let data = [];

        if (isFarmField) {
          const rsp = await PondManagerServices.getFarmTimeseriesData(farmId, [field.field_id], selectedCycle.start_date, selectedCycle.end_date);
          data = rsp.rows;
        } else if (isComposite) {
          const rsp = await axios.all(cycles.map(({ start_date, end_date }) => PondManagerServices.getTimeseriesData(pond?.id, [field.field_id], start_date, end_date)));
          data = _.orderBy(_.flatten(rsp.map((r) => r.rows)), "datetime");
        } else {
          const rsp = await PondManagerServices.getTimeseriesData(pond?.id, [field.field_id], selectedCycle.start_date, selectedCycle.end_date);
          data = rsp.rows;
        }
        setLoading(false);
        data && setPondData(data);
      },
      onSwitchToUploadMode: () => {},
      onLoadingRefereceData: async () => {
        const response = await PondManagerServices.getTimeseriesData(pond?.id, referenceFields, selectedCycle?.start_date, selectedCycle?.end_date);
        setPondReferenceData(response?.rows);
      },
    },
    multiMode: {
      /** fire when a new field is selected. All data will be reloaded */
      onSelectField: async () => {
        setLoading(true);
        const batchData = await axios.all(
          ponds
            .map((p) => {
              const allCyclesForThisPond = cycleManagementStore.cycles[p.id];
              const latestCycleForThisPond = _.maxBy(allCyclesForThisPond || [], "start_date") || {};
              const pivotDateMatchedCycleForThisPond = allCyclesForThisPond?.filter(
                (ele) => moment(ele.start_date, "YYYY-MM-DD") <= moment(selectedDate, "YYYY-MM-DD") && moment(ele.end_date, "YYYY-MM-DD") >= moment(selectedDate, "YYYY-MM-DD")
              )[0];
              const displayCycle = pivotDateMatchedCycleForThisPond || latestCycleForThisPond;
              return displayCycle ? PondManagerServices.getTimeseriesData(p.id, [selectedField.field_id], displayCycle?.start_date, displayCycle?.end_date) : null;
            })
            .filter((ele) => ele)
        );
        setPondsData(batchData);
        setLoading(false);
      },
      onHandleUpdatePonds: async (ponds) => {
        setLoading(true);
        const batchData = await axios.all(
          ponds
            .map((p) => {
              const allCyclesForThisPond = cycleManagementStore.cycles[p.id];
              const latestCycleForThisPond = _.maxBy(allCyclesForThisPond || [], "start_date") || {};
              const pivotDateMatchedCycleForThisPond = allCyclesForThisPond?.filter(
                (ele) => moment(ele.start_date, "YYYY-MM-DD") <= moment(selectedDate, "YYYY-MM-DD") && moment(ele.end_date, "YYYY-MM-DD") >= moment(selectedDate, "YYYY-MM-DD")
              )[0];
              const displayCycle = pivotDateMatchedCycleForThisPond || latestCycleForThisPond;
              return displayCycle ? PondManagerServices.getTimeseriesData(p.id, [selectedField.field_id], displayCycle?.start_date, displayCycle?.end_date) : null;
            })
            .filter((ele) => ele)
        );
        setPondsData(batchData);
        setLoading(false);
      },
      handleAggregatorChange: (e) => {
        setAggregatorMode(e.target.value);
      },
    },
  };

  const stats =
    pondData.length > 0 && selectedField
      ? {
          lastValue: _.last(pondData)[selectedField.field_id],
          lastCollectedAt: _.last(pondData).datetime,
          totalChange: pondData.length > 1 ? _.last(pondData)[selectedField.field_id] - _.first(pondData)[selectedField.field_id] : 0,
          totalPercChange: pondData.length > 1 ? calculatePercentageChange(_.first(pondData)[selectedField.field_id], _.last(pondData)[selectedField.field_id]) : 0,
          lastChange: pondData.length > 1 ? _.last(pondData)[selectedField.field_id] - _.first(_.takeRight(pondData, 2))[selectedField.field_id] : 0,
          lastPercChange: pondData.length > 1 ? calculatePercentageChange(_.first(_.takeRight(pondData, 2))[selectedField.field_id], _.last(pondData)[selectedField.field_id]) : 0,
        }
      : {};

  const PartialRender = {
    /** ONLY when it's ... 1. single-pond selected */
    renderStatsCards: () => {
      if (!isMultiMode) {
        if (pond && pondData?.length > 0) {
          return (
            <Grid
              container
              // columnSpacing={1}
              p={1}
              borderRadius={1}
              bgcolor={colors.grey[50]}
              sx={{
                opacity: isLoading ? 0.3 : 1,
              }}
            >
              {[
                [<Text>interface.general.group</Text>, <Text>{`fields.group.${selectedField.field_group}`}</Text>],
                [<Text>interface.general.data</Text>, <Text>{`fields.${selectedField.field_id}`}</Text>],
                [<Text>interface.general.unit</Text>, selectedField.field_unit],
                [<Text>interface.general.last-value</Text>, <NumberFormat thousandSeparator={true} value={stats.lastValue} displayType="text" decimalScale={2} />],
                [<Text>interface.general.last-value</Text>, TimeFormatters.formatDatetime(stats.lastCollectedAt, "DD")],
              ].map((ele, idx) => (
                <Grid item sx={6} lg={2} ml={1}>
                  <Box key={idx}>
                    <Typography variant="caption" textTransform={"uppercase"} color={"grey"}>
                      <strong>{ele[0]}</strong>
                    </Typography>
                    <Typography>{ele[1]}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          );
        }
      }
      return "";
    },
    /** ONLY when it's ... 1. single-pond selected 2. cycle is selected */
    renderSummaryCard: () => {
      if (!isMultiMode && pond && !_.isEmpty(selectedCycle)) {
        return (
          <Box borderRadius={1} bgcolor={palette.primary.light} p={1} px={2} mb={2}>
            <DataExplorerSummary farm={farm} pond={pond} cycle={selectedCycle} />
          </Box>
        );
      } else {
        return "";
      }
    },
  };

  return (
    <Box
      sx={{
        ...PondManagerLayoutStyles.rightPanel,
        p: 0,
      }}
    >
      <Box sx={{ position: "absolute", width: "100%" }}>{isLoading && <LinearProgress height="2px" />}</Box>
      <Stack direction="row">
        <Box
          sx={{
            ...PondManagerLayoutStyles.rightPanelInner,
            width: "300px",
            p: 0,
          }}
        >
          <DataExplorerSideBar
            isMultiMode={isMultiMode}
            defaultFieldType={defaultFieldType}
            fields={fields}
            selectedField={selectedField}
            onSelectField={(field_) => {
              setField(field_);
            }}
            referenceFields={referenceFields}
            setReferenceFields={setReferenceFields}
            referenceFieldsMode={referenceFieldsMode}
            setReferenceFieldsMode={setReferenceFieldsMode}
          />
        </Box>
        <Box
          width="100%"
          sx={{
            ...PondManagerLayoutStyles.rightPanelInner,
          }}
        >
          {/* <PartialRender.renderSummaryCard /> */}
          {!isMultiMode && pond && !_.isEmpty(selectedCycle) && (
            <Box borderRadius={1} bgcolor={palette.primary.light} p={1} px={2} mb={2}>
              <DataExplorerSummary farm={farm} pond={pond} cycle={selectedCycle} />
            </Box>
          )}

          <PartialRender.renderStatsCards />
          <Box
            my={1}
            sx={{
              width: "100%",
            }}
          >
            {/* 
            
            Multi Pond Mode 
            ---------------
            
            */}
            {isMultiMode && (
              <DataExplorerMultiPondView
                pond={pond}
                ponds={ponds}
                pondData={pondData}
                pondsData={pondsData.map((ele) => ele.rows)}
                selectedField={selectedField}
                chartType={chartType}
                aggregatorMode={aggregatorMode}
                handleAggregatorChange={Actions.multiMode.handleAggregatorChange}
              />
            )}
            {/* 
            
            Single Pond Mode 
            ----------------
            
            */}
            {!isMultiMode && (
              <DataExplorerView
                loading={isLoading}
                cycle={selectedCycle}
                cycles={cycles}
                farm={farm}
                pond={pond}
                pondData={pondData}
                pondReferenceData={pondReferenceData}
                referenceFields={referenceFields}
                referenceFieldsMode={referenceFieldsMode}
                selectedField={selectedField}
                chartType={chartType}
                subpageAction={Actions.singleMode.onSwitchToUploadMode}
              />
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default DataExplorer;
