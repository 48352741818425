import _ from "lodash";

export const KEYMAPPING = {
  USERID: "_userid",
  TOKEN: "_token",
  EMAIL: "_email",
  FIRSTNAME: "_fname",
  ROLE: "_role",
  ORGID: "_orgid",
  SETTINGS: {
    SELECTED_FARM: "_d8521bb0-fce6-42fc-8c25-c81472c7c657",
    SELECTED_GROUP: "_aee27278-0136-4f02-b19b-ac6dac0a22c8",
    FAV_FARMS: "_s_fav_farms",
    SHRIMP_PRICES: "092ca6c7-d4b8-4540-9dcf-4c524cfe59d0",
    FARMS_VIEW_MODE: "farms_view_mode",
    RECENT_VIEWED_FARMS: "RECENT_VIEWED_FARMS",
  },
};

export const saveLocalStorageItem = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const getLocalStorageItem = (key) => {
  let value = localStorage.getItem(key);
  return value;
};

export const getLocalStorageItemAsJson = (key) => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

export const saveLocalStorageItemAsJson = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageAccessToken = () => {
  return getLocalStorageItem("_token");
};

export const AppSettings = {
  Auth: {
    getRole: () => getLocalStorageItem(KEYMAPPING.ROLE),
  },
  Aqua: {
    lastGeoLocation: {
      get: () => getLocalStorageItemAsJson("_LASTGEOLOCATION"),
      save: (value) => saveLocalStorageItemAsJson("_LASTGEOLOCATION", value),
    },
  },
  App: {
    farmsViewMode: {
      get: () => getLocalStorageItemAsJson(KEYMAPPING.SETTINGS.FARMS_VIEW_MODE),
      save: (value) => saveLocalStorageItemAsJson(KEYMAPPING.SETTINGS.FARMS_VIEW_MODE, value),
    },
  },
};

/**
 * A class contains all localstorage actions
 *
 * usage
 *
 * const local = AppLocalStorage();
 *
 * const stuff = local.getAsJson("xxxx")
 */
class AppLocalStorage {
  constructor() {
    this.storage = localStorage;
  }

  /** get localstorage item */
  get = (key) => this.storage.getItem(key);

  /** get localstorage item and parse it to object */
  getAsJson = (key) => {
    try {
      return JSON.parse(this.storage.getItem(key));
    } catch {
      return null;
    }
  };

  /** save to localstorage */
  save = (key, item) => (item ? this.storage.setItem(key, item) : this.storage.removeItem(key));

  /** save stringfied object to localstorage */
  saveJson = (key, item) => (item ? this.storage.setItem(key, JSON.stringify(item)) : this.storage.removeItem(key));

  cleanup = () => this.storage.clear();

  cleanSettings = () => {
    _.values(KEYMAPPING.SETTINGS).map((k) => this.storage.removeItem(k));
  };

  /** ~~~~ PONDMANAGEMENT ~~~~~ */
  getRecentViewedFarmIds = () => this.getAsJson(KEYMAPPING.SETTINGS.RECENT_VIEWED_FARMS);

  addRecentViewedFarmId = (farmId) => {
    const farmids = this.getRecentViewedFarmIds(KEYMAPPING.SETTINGS.RECENT_VIEWED_FARMS) || [];
    if (!_.includes(farmids, farmId)) {
      const newFarmIds = [farmId, ..._.take(farmids, 7)];
      this.saveJson(KEYMAPPING.SETTINGS.RECENT_VIEWED_FARMS, newFarmIds);
    }
  };

  removeAllRecentViewedFarmIds = () => {
    this.storage.removeItem(KEYMAPPING.SETTINGS.RECENT_VIEWED_FARMS);
  };

  /** ~~~~ U S E R ~~~~~ */

  /** Get user's role */
  getUserRole = () => this.get(KEYMAPPING.ROLE);

  getUserToken = () => this.get(KEYMAPPING.TOKEN);

  /** ~~~~ O R G ~~~~~ */

  getOrgId = () => this.get(KEYMAPPING.ORGID);

  saveOrgId = (orgid) => this.save(KEYMAPPING.ORGID, orgid);
}

export default AppLocalStorage;
