import { Box, colors, Grid, Stack, TextField, Typography } from "@mui/material";
import { getLocalStorageItem, saveLocalStorageItem } from "helpers/LocalStorage";
import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { getText } from "components/text/Text";
import LoadingBox from "components/Loading/LoadingBox";
import MobileFarmCard from "screens/Mobile/components/MobileFarmCard";
import BorderCard from "ui/Card/BorderCard";
import { BorderBottom } from "@mui/icons-material";

const HomePageListViews = {
  grid: "grid",
  table: "table",
  map: "map",
};

const MobilePondManagerHome = () => {
  const farmListViewStorageData = getLocalStorageItem("farmListView");

  const pondManagerStates = useSelector((state) => state.pond);

  const farms = pondManagerStates.farms;

  const [selectedListView, setSelectedListView] = useState(farmListViewStorageData || HomePageListViews.grid);

  const [searchTerm, setSearchTerm] = useState(null);

  const Variables = {
    stats: [
      {
        label: getText("interface.general.total-farms"),
        value: farms.length,
      },
      {
        label: getText("interface.general.total-ponds"),
        value: _.sumBy(farms, "total_ponds"),
      },
      {
        label: getText("interface.general.total-area") + " (ha.)",
        value: _.sumBy(farms, "total_area")?.toFixed(0),
      },
      // {
      //   label: getText("interface.general.total-area") + " (ac.)",
      //   value: (_.sumBy(farms, "total_area") * 2.47105)?.toFixed(0),
      // },
    ],
  };

  const navigate = useNavigate();

  const handleListViewSelector = (selectedType) => {
    setSelectedListView(selectedType);
    saveLocalStorageItem("farmListView", selectedType);
  };

  const displayFarms = farms.filter((ele) => {
    if (!searchTerm) return true;
    const normalizedSearchTerm = searchTerm.trim().toLowerCase();
    const normalizedItem = ele.label.trim().toLowerCase();
    return normalizedItem.includes(normalizedSearchTerm);
  });

  return (
    <Box p={1} sx={{ position: "relative" }}>
      {/* Top Toolbar */}
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 999,
          backdropFilter: "blur(20px)",
        }}
      >
        <BorderCard
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={1}>
            {Variables.stats.map((ele, idx) => {
              return (
                <Box key={idx}>
                  <Typography fontSize={10} fontWeight={800} textAlign={"center"} color={"grey"}>
                    {ele.label}
                  </Typography>
                  <Typography fontSize={20} fontWeight={800} textAlign={"center"}>
                    {ele.value}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
          <Box mt={1}>
            <TextField fullWidth variant="standard" size="small" placeholder={getText("interface.actions.search")} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </BorderCard>
      </Box>

      {/* ~~~~~ Placeholder ~~~~~ */}

      <Box mt={14} zIndex={10}>
        {!pondManagerStates.loading && farms.length === 0 && (
          <Box p={2}>
            <Stack alignItems={"center"} width="100%">
              <Box>
                <Typography fontSize="3rem" fontWeight="bold" color={colors.grey[500]}>
                  Create your first farm!
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color={colors.grey[500]}>Click the add button above to claim your first farm</Typography>
              </Box>
            </Stack>
          </Box>
        )}

        {/* ~~~~~ MAIN ~~~~~ */}

        {pondManagerStates.loading ? (
          <LoadingBox />
        ) : (
          <>
            {/* ************* GRID VIEW ************* */}
            <Grid container spacing={1}>
              {selectedListView === HomePageListViews.grid &&
                displayFarms.map((f) => {
                  return (
                    <Grid key={f.farm_id} item xs={6}>
                      <MobileFarmCard farm={f} />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default MobilePondManagerHome;
