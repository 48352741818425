import { Box, IconButton, LinearProgress, Stack, Tooltip } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { ErrorAlertBox } from "components/Alerts/AlertBox";
import { ListIcon, NotificationImportantIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import CommonModal, { CommonModalSizes } from "components/Modal/CommonModal";
import AccessWrapper from "components/Wrapper/AccessWrapper";
import Text, { getText } from "components/text/Text";
import PlatformAccessPolices from "constants/PlatformAccessPolicies";
import { getShrimpSizeCategoryByWeight } from "helpers/Aqua";
import _ from "lodash";
import { spatialData } from "mock/spatialData";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { modalIds, modalReducersActions } from "redux/modal";
import ClimateDashboard from "screens/Aquaculture/components/Climate/ClimateDashboard/ClimateDashboard";
import FarmAlertsRulesForm from "screens/Aquaculture/components/Dashboard/components/FarmAlertsRulesForm";
import FarmMonitorDashboard from "screens/Aquaculture/components/Dashboard/components/FarmMonitorDashboard";
import { getAlertResult } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import FarmFinancePanel from "screens/Aquaculture/components/PondManager/PondManagerFarmDashboard/FarmFinancePanel";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import FarmSummaryDashboard from "screens/Aquaculture/components/Dashboard/FarmSummaryDashboard";
import WidgetCard from "ui/Card/WidgetCard";
import { useQuery } from "@tanstack/react-query";
import TimeFormatters from "helpers/TimeFormatters";

const viewModes = {
  OVERVIEW: {
    label: <Text>interface.general.summary</Text>,
    value: 0,
  },
  DASHBOARD: {
    label: <Text>interface.general.monitor</Text>,
    value: 1,
  },
  FINANCE: {
    label: <Text>interface.general.finance</Text>,
    value: 2,
  },
  CLIMATE: {
    label: <Text>interface.general.climate</Text>,
    value: 3,
  },
};

// reformat the farm dashboard result so it's easy to be integrated into some charts
const reformatResult = ({ raw, ponds, farm }) => {
  const rawAggData = raw.aggregated_pond_data;

  const alertRules = farm?.settings?.alerts;

  const tableRows = ponds.map((p) => {
    const latestBiomass = rawAggData?.[p.id]?.biomass?.value;
    const latestBiomassDt = rawAggData?.[p.id]?.biomass?.datetime;
    const latestWeight = rawAggData?.[p.id]?.weight?.value;
    const latestWeightDt = rawAggData?.[p.id]?.weight?.datetime;
    const latestSurvivalRate = rawAggData?.[p.id]?.survival_rate?.value;
    const latestSurvivalRateDt = rawAggData?.[p.id]?.survival_rate?.datetime;

    const latestPh = rawAggData?.[p.id]?.ph?.value;
    const latestPhDt = rawAggData?.[p.id]?.ph?.datetime;
    const latestSalinity = rawAggData?.[p.id]?.salinity?.value;
    const latestSalinityDt = rawAggData?.[p.id]?.salinity?.datetime;
    const latestDO = rawAggData?.[p.id]?.dissolved_oxygen?.value;
    const latestDODt = rawAggData?.[p.id]?.dissolved_oxygen?.datetime;
    const latestWaterTemp = rawAggData?.[p.id]?.water_temp?.value;
    const latestWaterTempDt = rawAggData?.[p.id]?.water_temp?.datetime;
    const latestTurbidity = rawAggData?.[p.id]?.turbidity?.value;
    const latestTurbidityDt = rawAggData?.[p.id]?.turbidity?.datetime;
    const latestWaterExchange = rawAggData?.[p.id]?.water_exchange?.value;
    const latestWaterExchangeDt = rawAggData?.[p.id]?.water_exchange?.datetime;
    const latestNitrate = rawAggData?.[p.id]?.nitrate?.value;
    const latestNitrateDt = rawAggData?.[p.id]?.nitrate?.datetime;
    const latestNitrite = rawAggData?.[p.id]?.nitrite?.value;
    const latestNitriteDt = rawAggData?.[p.id]?.nitrite?.datetime;
    const latestAverageDepth = rawAggData?.[p.id]?.average_depth?.value;
    const latestAverageDepthDt = rawAggData?.[p.id]?.average_depth?.datetime;
    const latestAlkalinity = rawAggData?.[p.id]?.alkalinity?.value;
    const latestAlkalinityDt = rawAggData?.[p.id]?.alkalinity?.datetime;

    const forecasted_optimal_biomass = rawAggData?.[p.id]?._forecast?.biomass_forecast_max?.value;
    const forecasted_optimal_biomass_dt = rawAggData?.[p.id]?._forecast?.biomass_forecast_max?.date;
    const forecasted_optimal_biomass_before_harvest = rawAggData?.[p.id]?._forecast?.biomass_forecast_before_last_harvest?.value;
    const forecasted_optimal_biomass_before_harvest_dt = rawAggData?.[p.id]?._forecast?.biomass_forecast_before_last_harvest?.date;
    const spatialDataBiomassEstimated = _.last(
      spatialData?.[farm.farm_id]?.aquosmic?.biomass?.data
        ?.filter((ele) => ele.pond_id == p.id)
        ?.filter((ele) => moment(ele.date) <= moment(latestBiomassDt) && moment(latestBiomassDt).diff(moment(ele.date), "d") <= 14) || []
    )?.value;
    const spatialDataBiomassEstimatedDt = _.last(
      spatialData?.[farm.farm_id]?.aquosmic?.biomass?.data
        ?.filter((ele) => ele.pond_id == p.id)
        ?.filter((ele) => moment(ele.date) <= moment(latestBiomassDt) && moment(latestBiomassDt).diff(moment(ele.date), "d") <= 14) || []
    )?.date;
    let row = {
      p__id: p.id,
      p__label: p.label,
      p__area: p.area,
      p__group: p.group,
      c__cycle_started: rawAggData?.[p.id]?._cycle?.start_date,
      c__cycle_ended: rawAggData?.[p.id]?._cycle?.end_date,
      c__cycle_active: rawAggData?.[p.id]?._cycle?.ended === false,
      c__cycle_label: rawAggData?.[p.id]?._cycle?.label,
      c__cycle_id: rawAggData?.[p.id]?._cycle?.id,
      d__last_weight: latestWeight,
      d__last_weight_cat: latestWeight && getShrimpSizeCategoryByWeight(latestWeight)?.category,
      d__last_weight_dt: latestWeightDt,
      d__last_biomass: latestBiomass,
      d__last_biomass_dt: latestBiomassDt,
      d__last_survival_rate: latestSurvivalRate,
      d__last_survival_rate_dt: latestSurvivalRateDt,
      d__last_harvest_biomass: rawAggData?.[p.id]?.harvest_biomass?.value,
      d__last_harvest_biomass_dt: rawAggData?.[p.id]?.harvest_biomass?.datetime,
      d__last_seeded_pl: rawAggData?.[p.id]?.seeded_pl?.value,
      d__last_seeded_pl_dt: rawAggData?.[p.id]?.seeded_pl?.datetime,
      d__total_harvest_biomass: rawAggData?.[p.id]?.harvest_biomass_accumulated?.value,
      d__total_harvest_biomass_dt: rawAggData?.[p.id]?.harvest_biomass_accumulated?.datetime,
      d__last_feed: rawAggData?.[p.id]?.feed_daily?.value,
      d__last_feed_dt: rawAggData?.[p.id]?.feed_daily?.datetime,
      d__total_feed: rawAggData?.[p.id]?.feed_accumulated?.value,
      d__total_feed_dt: rawAggData?.[p.id]?.feed_accumulated?.datetime,
      d__fcr: rawAggData?.[p.id]?.fcr_full_cycle?.value,
      d__fcr_dt: rawAggData?.[p.id]?.fcr_full_cycle?.datetime,
      d__forecasted_optimal_biomass: forecasted_optimal_biomass,
      d__forecasted_optimal_biomass_dt: forecasted_optimal_biomass_dt,
      d__forecasted_optimal_biomass_before_harvest: forecasted_optimal_biomass_before_harvest,
      d__forecasted_optimal_biomass_before_harvest_dt: forecasted_optimal_biomass_before_harvest_dt,
      d__satellite_forecast_last_biomass: spatialDataBiomassEstimated,
      d__satellite_forecast_last_biomass_dt: spatialDataBiomassEstimatedDt,
      d__ph: latestPh,
      d__ph_dt: latestPhDt,
      d__salinity: latestSalinity,
      d__salinity_dt: latestSalinityDt,
      d__do: latestDO,
      d__do_dt: latestDODt,
      d__temp: latestWaterTemp,
      d__temp_dt: latestWaterTempDt,
      d__turbidity: latestTurbidity,
      d__turbidity_dt: latestTurbidityDt,
      d__waterexchange: latestWaterExchange,
      d__waterexchange_dt: latestWaterExchangeDt,
      d__nitrate: latestNitrate,
      d__nitrate_dt: latestNitrateDt,
      d__nitrite: latestNitrite,
      d__nitrite_dt: latestNitriteDt,
      d__averagedepth: latestAverageDepth,
      d__averagedepth_dt: latestAverageDepthDt,
      d__alkalinity: latestAlkalinity,
      d__alkalinity_dt: latestAlkalinityDt,
      a__alerts: {
        water_temp: getAlertResult({
          field_id: "water_temp",
          value: latestWaterTemp,
          rules: alertRules,
        }),
        salinity: getAlertResult({
          field_id: "salinity",
          value: latestSalinity,
          rules: alertRules,
        }),
        dissolved_oxygen: getAlertResult({
          field_id: "dissolved_oxygen",
          value: latestDO,
          rules: alertRules,
        }),
        ph: getAlertResult({ field_id: "ph", value: latestPh }),
        survival_rate: getAlertResult({
          field_id: "survival_rate",
          value: latestSurvivalRate,
          rules: alertRules,
        }),
        turbidity: getAlertResult({
          field_id: "turbidity",
          value: latestTurbidity,
          rules: alertRules,
        }),
        water_exchange: getAlertResult({
          field_id: "water_exchange",
          value: latestWaterExchange,
          rules: alertRules,
        }),
        nitrate: getAlertResult({
          field_id: "nitrate",
          value: latestNitrate,
          rules: alertRules,
        }),
        nitrite: getAlertResult({
          field_id: "nitrite",
          value: latestNitrite,
          rules: alertRules,
        }),
        average_depth: getAlertResult({
          field_id: "average_depth",
          value: latestAverageDepth,
          rules: alertRules,
        }),
        alkalinity: getAlertResult({
          field_id: "alkalinity",
          value: latestAlkalinity,
          rules: alertRules,
        }),
      },
    };
    row["d__forcast_accuracy"] = row.d__total_harvest_biomass && (1 - Math.abs(row.d__forecasted_optimal_biomass_before_harvest / row.d__total_harvest_biomass - 1)) * 100;
    row["a__total_alerts"] = _.values(row.a__alerts).filter((ele) => ele.isAlert).length;
    return row;
  });

  return {
    ...raw,
    tableRows: tableRows,
  };
};

const FarmDashboardView = ({ farm, ponds, group, pondGroupColorMapping, onSwitchToDataViewField, actions }) => {
  const cycleStore = AppSelectors.cycleStore();
  const selectedDate = cycleStore.selectedDate;

  const { toggleAlertPonds } = actions;

  const dispatch = useDispatch();

  const theQuery = useQuery({
    queryKey: ["data", "farm-dashboard", farm.farm_id, selectedDate],
    queryFn: async () => {
      const response = await PondManagerServices.getFarmDashboardData({
        farmId: farm.farm_id,
        date: selectedDate,
        group: group,
      });
      const reformatted = reformatResult({
        raw: response,
        ponds: ponds,
        farm: farm,
      });
      return reformatted;
    },
    enabled: !!farm && !!selectedDate,
    retry: false,
  });

  const { refetch, error, dataUpdatedAt, isFetching } = theQuery;
  const farmDashboardData = theQuery.data || {};

  const Actions = {
    onReload: () => {
      !isFetching && refetch();
    },
    onOpenAlertRules: () => {
      modalReducersActions.openModal(dispatch, {
        id: modalIds.FARM_ALERT_RULES_MODAL,
      });
    },
    postAlertRulesSubmitted: () => {
      window.location.reload();
    },
  };

  const [view, setView] = useState(viewModes.OVERVIEW.value);

  const viewModeTabs = _.orderBy(_.values(viewModes), "value");

  const pondLimit = 500;

  if (ponds.length > pondLimit) {
    return (
      <Box p={2}>
        <AlertBox title="" />
      </Box>
    );
  }

  const accessPolices = farm?.["org-access-test"];
  const accessAlways = accessPolices ? false : true;

  return (
    <Box>
      <CommonModal id={modalIds.FARM_ALERT_RULES_MODAL} size={CommonModalSizes.large}>
        <FarmAlertsRulesForm farm={farm} postSubmit={Actions.postAlertRulesSubmitted} />
      </CommonModal>
      <Box>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={2}>
          <MuiTabs options={viewModeTabs} value={view} onNewValue={(val) => setView(val)} theme={MuiTabsThemes.chip} />
          <Box>
            <Stack direction={"row"} spacing={1}>
              <AccessWrapper target={PlatformAccessPolices.alerts.write} allowed={accessPolices} always={accessAlways} hideOnly>
                <IconButton size="small" onClick={Actions.onOpenAlertRules}>
                  <NotificationImportantIcon />
                </IconButton>
              </AccessWrapper>
              <Tooltip placement="top" title={`${getText("interface.general.last-updated")} ${TimeFormatters.formatMilliseconds(dataUpdatedAt)}`}>
                <IconButton size="small" onClick={Actions.onReload}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>
        <Box height={3}>{isFetching && <LinearProgress />}</Box>
        <Box p={2}>
          {error && <ErrorAlertBox title={error} />}
          {!_.isEmpty(farmDashboardData) && (
            <>
              {view === viewModes.OVERVIEW.value && (
                <FarmSummaryDashboard
                  {...{
                    farm,
                    ponds,
                    farmDashboardData,
                    onSwitchToDataViewField,
                    pondGroupColorMapping,
                    actions: {
                      toggleAlertPonds,
                    },
                  }}
                />
              )}
              {view === viewModes.DASHBOARD.value && (
                <FarmMonitorDashboard
                  {...{
                    farm,
                    ponds,
                    farmDashboardData,
                    onSwitchToDataViewField,
                    actions: {
                      toggleAlertPonds,
                    },
                  }}
                />
              )}
              {view === viewModes.FINANCE.value && (
                <AccessWrapper target={PlatformAccessPolices.finance.read} allowed={accessPolices} always={accessAlways}>
                  <FarmFinancePanel
                    {...{
                      farm,
                      ponds,
                      farmDashboardData,
                      onSwitchToDataViewField,
                      actions: {
                        toggleAlertPonds,
                      },
                    }}
                  />
                </AccessWrapper>
              )}
              {view === viewModes.CLIMATE.value && (
                <WidgetCard>
                  <ClimateDashboard farm={farm} />
                </WidgetCard>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FarmDashboardView;
