import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CircleIcon } from "components/Icons/MaterialIcons";
import OutlinedTag from "components/Tags/OutlinedTag";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { AppSelectors } from "redux/AppReducers";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const ViewModes = {
  harvest_forecast: {
    value: 1,
    label: <Text>interface.general.harvest-forecast</Text>,
    configs: {
      tableColDefs: [
        {
          headerName: "Total Harvested (t)",
          field: "d__total_harvest_biomass",
          cellRenderer: ({ value }) => {
            return <NumberFormat displayType="text" decimalScale={1} thousandSeparator="," value={value / 1000 || "-"} />;
          },
        },
        {
          headerName: "Forecasted Optimal Harvest (t)",
          field: "d__forecasted_optimal_biomass_before_harvest",
          cellRenderer: ({ value }) => {
            return <NumberFormat displayType="text" decimalScale={1} thousandSeparator="," value={value / 1000 || "-"} />;
          },
        },
        {
          headerName: "Accuracy",
          field: "d__forcast_accuracy",
          cellRenderer: ({ value, data }) => {
            if (data?.c__cycle_active) {
              return "Cycle is Active";
            }

            return (
              <span
                style={{
                  color: value > 80 ? colors.green[500] : colors.orange[500],
                }}
              >
                <NumberFormat displayType="text" decimalScale={1} thousandSeparator="," value={value || "-"} suffix="%" />
              </span>
            );
          },
        },
        {
          headerName: "Last Harvested",
          field: "d__last_harvest_biomass_dt",
          cellRenderer: ({ value }) => {
            return moment(value).format("YYYY-MM-DD");
          },
        },
        {
          headerName: "Forecasted Optimal Harvest Date",
          field: "d__forecasted_optimal_biomass_dt",
          cellRenderer: ({ value }) => {
            return moment(value).format("YYYY-MM-DD");
          },
        },
      ],
    },
  },
  biological: {
    value: 0,
    label: <Text>interface.general.biological</Text>,
    configs: {
      tableColDefs: [
        {
          headerName: "Wt",
          field: "d__last_weight",
          width: "100px",
          cellRenderer: NumericFormatters.formatWeightInG,
          cellStyle: {
            justifyContent: "flex-end",
          },
        },
        {
          headerName: "Cpp",
          field: "d__last_weight_cat",
          width: "100px",
          cellStyle: {
            justifyContent: "center",
          },
        },
        {
          headerName: "BM",
          field: "d__last_biomass",
          width: "100px",
          cellRenderer: ({ value }) => NumericFormatters.format({ value: value / 1000, suffix: " t", decimalScale: 3 }),
          cellStyle: {
            justifyContent: "flex-end",
          },
        },
        {
          headerName: "SR%",
          field: "d__last_survival_rate",
          width: "100px",
          cellRenderer: ({ value }) => {
            return <NumberFormat displayType="text" decimalScale={0} suffix="%" value={value} />;
          },
          cellStyle: {
            justifyContent: "flex-end",
          },
        },
        {
          headerName: "SdPL",
          field: "d__last_seeded_pl",
          width: "100px",
          cellRenderer: ({ value }) => NumericFormatters.format({ value: value, decimalScale: 0 }),
          cellStyle: {
            justifyContent: "flex-end",
          },
        },

        // {
        //   headerName: "Satellite Detected (t)",
        //   field: "d__satellite_forecast_last_biomass",
        //   cellRenderer: ({ value }) => {
        //     return <NumberFormat displayType="text" decimalScale={1} value={value / 1000 || "-"} />;
        //   },
        // },
      ],
    },
    notes: (
      <Stack direction={"row"} spacing={1} my={1}>
        <OutlinedTag text={<Text prefix={"Wt: "}>fields.weight</Text>} />
        <OutlinedTag text={<Text prefix={"Cpp: "}>interface.general.count-per-pond</Text>} />
        <OutlinedTag text={<Text prefix={"Sr%: "}>fields.survival_rate</Text>} />
        <OutlinedTag text={<Text prefix={"BM: "}>fields.biomass</Text>} />
        <OutlinedTag text={<Text prefix={"SdPL: "}>fields.seeded_pl</Text>} />
      </Stack>
    ),
  },
  feed: {
    value: 2,
    label: <Text>interface.general.feed-and-fcr</Text>,
    configs: {
      tableColDefs: [
        {
          headerName: "Total Feed Used (t)",
          field: "d__total_feed",
          cellRenderer: ({ value }) => {
            return <NumberFormat displayType="text" decimalScale={1} value={value / 1000 || "-"} thousandSeparator="," />;
          },
        },
        {
          headerName: "FCR",
          field: "d__fcr",
          cellRenderer: ({ value }) => {
            return <NumberFormat displayType="text" decimalScale={1} value={value} />;
          },
        },
      ],
    },
  },
  water_quality: {
    value: 3,
    label: <Text>interface.general.water-quality</Text>,
    configs: {
      tableColDefs: [
        {
          headerName: "PH",
          field: "d__ph",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "WT",
          field: "d__temp",
          width: "70px",
          cellRenderer: NumericFormatters.formatTemperature,
        },
        {
          headerName: "SAL",
          field: "d__salinity",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "DO",
          field: "d__do",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "AD",
          field: "d__averagedepth",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "TB",
          field: "d__turbidity",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "WX%",
          field: "d__waterexchange",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "NO3-",
          field: "d__nitrate",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "NO2−",
          field: "d__nitrite",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
        {
          headerName: "AT",
          field: "d__alkalinity",
          width: "70px",
          cellRenderer: NumericFormatters.format,
        },
      ],
    },
    notes: (
      <Stack direction={"row"} spacing={1} my={1}>
        <OutlinedTag text={<Text prefix={"WT: "}>fields.water_temp</Text>} />
        <OutlinedTag text={<Text prefix={"SAL: "}>fields.salinity</Text>} />
        <OutlinedTag text={<Text prefix={"DO: "}>fields.dissolved_oxygen</Text>} />
        <OutlinedTag text={<Text prefix={"AD: "}>fields.average_depth</Text>} />
        <OutlinedTag text={<Text prefix={"TB: "}>fields.turbidity</Text>} />
        <OutlinedTag text={<Text prefix={"WX%: "}>fields.water_exchange</Text>} />
        <OutlinedTag text={<Text prefix={"NO3-: "}>fields.nitrate</Text>} />
        <OutlinedTag text={<Text prefix={"NO2-: "}>fields.nitrite</Text>} />
        <OutlinedTag text={<Text prefix={"AT: "}>fields.alkalinity</Text>} />
      </Stack>
    ),
  },
};

const FarmPondsSummaryWidget = (props) => {
  const pondmanagementStore = AppSelectors.pondManagementStore();
  const { selectedGroup } = pondmanagementStore;

  const [sharedTableCols, setSharedTableCols] = useState([
    {
      headerName: getText("interface.general.group"),
      field: "p__group",
      pinned: "left",
      width: "100px",
      filter: "agSetColumnFilter",
    },
    {
      headerName: getText("interface.general.pond"),
      field: "p__label",
      pinned: "left",
      width: "100px",
    },
    {
      headerName: getText("interface.general.pond-area"),
      field: "p__area",
      pinned: "left",
      width: "100px",
      filter: "agSetColumnFilter",
      cellRenderer: ({ value }) => NumericFormatters.format({ value: value, suffix: " ha.", decimalScale: 2 }),
    },
    {
      headerName: getText("interface.general.cycle"),
      field: "c__cycle_active",
      pinned: "left",
      width: "100px",
      filter: "agSetColumnFilter",
      cellRenderer: ({ value, data }) => {
        return (
          <Tooltip
            title={
              data?.c__cycle_id ? (
                <Box>
                  <Typography fontSize={10}>{data?.c__cycle_label}</Typography>
                  <Typography fontSize={10}>
                    {TimeFormatters.formatDatetime(data?.c__cycle_started, "DD")} ~ {TimeFormatters.formatDatetime(data?.c__cycle_ended, "DD")}
                  </Typography>
                </Box>
              ) : (
                getText("interface.general.no-cycle")
              )
            }
            placement="right"
          >
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <CircleIcon
                sx={{
                  color: data?.c__cycle_active ? colors.green[500] : colors.grey[400],
                  fontSize: 13,
                }}
              />
              <Box>
                <Typography fontSize={10} fontStyle={"italic"}>
                  {data?.c__cycle_label}
                </Typography>
              </Box>
            </Stack>
          </Tooltip>
        );
      },
    },
  ]);
  const [colDef, setColDef] = useState([...sharedTableCols, ...ViewModes.biological.configs.tableColDefs]);

  const [view, setView] = useState(ViewModes.biological.value);

  const tableRef = useRef();

  const viewData = _.find(ViewModes, { value: view });

  const tableRows = props.farmDashboardData?.tableRows?.filter((ele) => (selectedGroup ? ele.p__group === selectedGroup : true));

  useEffect(() => {
    const selectedViewMode = _.chain(ViewModes).values().find({ value: view }).value()?.configs?.tableColDefs;
    setColDef([...sharedTableCols, ...selectedViewMode]);
  }, [view]);

  const tabOptions = _.chain(ViewModes).values().orderBy("value").value();

  const Actions = {
    handleNewView: (newViewValue) => {
      setView(newViewValue);
    },
  };

  return (
    <Box>
      <Box>
        <MuiTabs theme={MuiTabsThemes.chip} value={view} onNewValue={Actions.handleNewView} options={tabOptions} />
      </Box>
      <Box
        className="ag-theme-excel"
        mt={2}
        sx={{
          height: 500,
        }}
      >
        <AgGridReact
          defaultColDef={{
            cellStyle: {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
            },
          }}
          ref={tableRef}
          rowData={tableRows}
          columnDefs={colDef}
          rowHeight={20}
          headerHeight={30}
          rowSelection={"single"}
          suppressScrollOnNewData
          animateRows={false}
        />
      </Box>
      {viewData?.notes}
    </Box>
  );
};

export default FarmPondsSummaryWidget;
