import { Box, Button, Card, CardContent, Popover } from "@mui/material";
import { GroupsIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import React, { useState } from "react";
import FarmAccessControl from "screens/Aquaculture/components/common/FarmAccessControl";

const FarmAccessControlButton = ({ farm }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const Actions = {
    handleClick: (event) => {
      setAnchorEl(event.currentTarget);
    },
    handleClose: () => {
      setAnchorEl(null);
    },
  };

  const open = Boolean(anchorEl);
  const id = open ? "farm-access-control-interface" : undefined;

  if (!farm) return "";
  return (
    <Box>
      <Button startIcon={<GroupsIcon />} variant="contained" color="secondary" onClick={Actions.handleClick}>
        <Text>interface.access-control.collab</Text>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={Actions.handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Card>
          <CardContent sx={{ width: 300 }}>
            <FarmAccessControl farm={farm} onClose={Actions.handleClose} />
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};

export default FarmAccessControlButton;
