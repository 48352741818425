import { Box, Stack, Typography } from "@mui/material";
import { AspectRatioIcon } from "components/Icons/MaterialIcons";
import OutlinedTag from "components/Tags/OutlinedTag";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";

const PondDashboardHero = ({ pond }) => {
  return (
    <Box>
      <Typography color="secondary" sx={{ lineHeight: 0.5 }} fontSize={10} fontWeight={800}>
        <Text>interface.general.pond</Text>
      </Typography>
      <Typography color="secondary" fontSize={18} fontWeight={800}>
        {pond?.name}
      </Typography>
      <Stack direction={"row"} spacing={0.5}>
        <OutlinedTag icon={<AspectRatioIcon />} text={NumericFormatters.formatAreaInHa({ value: pond?.area })} tooltip={getText("interface.general.pond-area")} />
        <OutlinedTag text={"G: " + pond?.group} tooltip={getText("interface.general.group")} />
        <OutlinedTag text={"C: " + pond?.category} tooltip={getText("interface.general.category")} />
        <OutlinedTag text={"SC: " + pond?.sub_category} tooltip={getText("interface.general.sub-category")} />
      </Stack>
    </Box>
  );
};

export default PondDashboardHero;
