import { Box, Stack, Typography } from "@mui/material";
import SimpleGeojsonMapView from "components/map/SimpleGeojsonMapView";
import NumericFormatters from "helpers/NumericFormatters";
import React from "react";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";

const MobilePondViewHome = ({ pond }) => {
  return (
    <Stack p={1} spacing={1}>
      <MobileWidgetCard>
        <Typography fontSize={20} fontWeight={800}>
          {pond?.label}
        </Typography>
        <Typography>{NumericFormatters.formatAreaInHa({ value: pond?.area })}</Typography>
        <Typography>G: {pond?.group}</Typography>
        <Box
          mt={1}
          sx={{
            position: "relative",
            height: 200,
            overflow: "hidden",
            borderRadius: 2,
          }}
        >
          {pond?.boundary_geojson && (
            <SimpleGeojsonMapView
              geojson={{
                type: "FeatureCollection",
                features: pond?.boundary_geojson,
              }}
            />
          )}
        </Box>
      </MobileWidgetCard>
    </Stack>
  );
};

export default MobilePondViewHome;
