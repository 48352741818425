import { Box, Grid, LinearProgress } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { NoPondAlertBox } from "components/Alerts/AlertBox";
import moment from "moment";
import { useEffect, useState } from "react";
import ImageQueryForm from "screens/Aquaculture/components/Files/components/ImageQueryForm";
import ImageTimeline from "screens/Aquaculture/components/Files/components/ImageTimeline";
import ImageUploadForm from "screens/Aquaculture/components/Files/components/ImageUploadForm";

const PhotoLogs = ({ farm, pond }) => {
  const [images, setImages] = useState([]);
  const [filters, setFilters] = useState({
    dateFrom: moment().subtract(6, "M"),
    dateTo: moment(),
    tag: "",
    label: "",
  });
  const [isUploading, setUploading] = useState(false);

  // actions
  const Actions = {
    fetchPondImages: async ({ pondId }) => {
      const dateFrom = filters?.dateFrom?.format("YYYY-MM-DD");
      const dateTo = filters?.dateTo?.add(1, "d")?.format("YYYY-MM-DD");
      const tag = filters?.tag;
      const label = filters?.label;

      const rsp = await PondManagerServices.fetchPondImages({ pondId, dateFrom: dateFrom, dateTo: dateTo, tag: tag, label: label });
      const imagesResult = rsp.rows;
      setImages(imagesResult);
    },
    uploadPondImage: async ({ pondId, formData }) => {
      setUploading(true);
      await PondManagerServices.uploadPondImage({ pondId, formData });
      Actions.fetchPondImages({ pondId });
      setUploading(false);
    },
    onImageUploadFormSubmitted: (values) => {
      if (pond) {
        const pondId = pond.id;
        const formData = {
          ...values,
          tags: values.tags.join(","),
          datetime: values.datetime.format("YYYY-MM-DD HH:mm:ss"),
          image: values.file,
        };
        Actions.uploadPondImage({ pondId, formData });
      }
    },
    onImageDelete: async ({ pondId, imageId }) => {
      await PondManagerServices.deletePondImage({ pondId, imageId });
      Actions.fetchPondImages({ pondId });
    },
    // update image details
    onImageUpdateFormSubmit: async (data) => {
      const pondId = pond.id;
      const formData = {
        label: data.form.label,
        image_id: data.image.id,
        tags: data.form.tags.join(","),
        note: data.form.note,
      };
      await PondManagerServices.updatePondImage({
        pondId,
        formData,
      });
      Actions.fetchPondImages({ pondId });
    },
  };

  // trigger when the pond or filters is updated
  useEffect(() => {
    if (pond) {
      const pondId = pond.id;
      Actions.fetchPondImages({ pondId });
    }
  }, [pond, filters]);

  if (!pond) {
    return (
      <Box p={2}>
        <NoPondAlertBox />
      </Box>
    );
  }

  const componentKey = pond?.id;

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {/* ~~~~~ Form Part  ~~~~~~  */}
        <Grid xs={12} md={4} item>
          <Box height={3} mb={1}>
            {isUploading && <LinearProgress />}
          </Box>
          <ImageUploadForm key={componentKey} onSubmit={Actions.onImageUploadFormSubmitted} />
        </Grid>
        <Grid xs={12} md={8} item>
          <Box>
            <ImageQueryForm filters={filters} setFilters={setFilters} />
            <ImageTimeline images={images} actions={Actions} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PhotoLogs;
