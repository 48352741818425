import * as turf from "@turf/turf";

/**
 *
 *
 *
 * Validate GeoJSON object.
 * @param {object} geoJson - The GeoJSON object to validate.
 * @returns {boolean} True if valid, false otherwise.
 *
 *
 *
 *
 */
export const validateGeoJson = (geoJson) => {
  // Check if the input is an object
  if (typeof geoJson !== "object" || geoJson === null) return false;

  // Validate the 'type' property
  const validGeoJsonTypes = ["Feature", "FeatureCollection", "Point", "MultiPoint", "LineString", "MultiLineString", "Polygon", "MultiPolygon", "GeometryCollection"];

  if (!validGeoJsonTypes.includes(geoJson.type)) return false;

  // Validate based on the type of GeoJSON object
  switch (geoJson.type) {
    case "Feature":
      return validateFeature(geoJson);
    case "FeatureCollection":
      return validateFeatureCollection(geoJson);
    case "GeometryCollection":
      return validateGeometryCollection(geoJson);
    default: // For Geometry types: Point, MultiPoint, LineString, MultiLineString, Polygon, MultiPolygon
      return validateGeometry(geoJson);
  }
};

// Validate GeoJSON Feature
function validateFeature(feature) {
  if (!feature.hasOwnProperty("type") || feature.type !== "Feature") return false;
  if (!feature.hasOwnProperty("geometry") || !validateGeometry(feature.geometry)) return false;
  if (!feature.hasOwnProperty("properties") || typeof feature.properties !== "object") return false;

  return true;
}

// Validate GeoJSON FeatureCollection
function validateFeatureCollection(featureCollection) {
  if (!featureCollection.hasOwnProperty("type") || featureCollection.type !== "FeatureCollection") return false;
  if (!featureCollection.hasOwnProperty("features") || !Array.isArray(featureCollection.features)) return false;

  return featureCollection.features.every((feature) => validateFeature(feature));
}

// Validate GeoJSON GeometryCollection
function validateGeometryCollection(geometryCollection) {
  if (!geometryCollection.hasOwnProperty("type") || geometryCollection.type !== "GeometryCollection") return false;
  if (!geometryCollection.hasOwnProperty("geometries") || !Array.isArray(geometryCollection.geometries)) return false;

  return geometryCollection.geometries.every((geometry) => validateGeometry(geometry));
}

// Validate GeoJSON Geometry
function validateGeometry(geometry) {
  if (!geometry.hasOwnProperty("type")) return false;

  const validGeometryTypes = ["Point", "MultiPoint", "LineString", "MultiLineString", "Polygon", "MultiPolygon"];

  if (!validGeometryTypes.includes(geometry.type)) return false;
  if (!geometry.hasOwnProperty("coordinates") || !Array.isArray(geometry.coordinates)) return false;

  return validateCoordinates(geometry.type, geometry.coordinates);
}

// Validate coordinates based on Geometry type
function validateCoordinates(type, coordinates) {
  switch (type) {
    case "Point":
      return validatePosition(coordinates);
    case "MultiPoint":
      return coordinates.every(validatePosition);
    case "LineString":
      return validateLineString(coordinates);
    case "MultiLineString":
      return coordinates.every(validateLineString);
    case "Polygon":
      return validatePolygon(coordinates);
    case "MultiPolygon":
      return coordinates.every(validatePolygon);
    default:
      return false;
  }
}

// Validate a single position (must be an array of numbers with length 2 or 3)
function validatePosition(position) {
  return Array.isArray(position) && (position.length === 2 || position.length === 3) && position.every((coord) => typeof coord === "number");
}

// Validate LineString (an array of valid positions)
function validateLineString(lineString) {
  return Array.isArray(lineString) && lineString.length >= 2 && lineString.every(validatePosition);
}

// Validate Polygon (an array of LinearRings, each of which is an array of valid positions)
function validatePolygon(polygon) {
  return Array.isArray(polygon) && polygon.every((linearRing) => Array.isArray(linearRing) && linearRing.length >= 4 && validateLinearRing(linearRing));
}

// Validate LinearRing (must be a closed LineString, the first and last positions must be the same)
function validateLinearRing(linearRing) {
  return validateLineString(linearRing) && JSON.stringify(linearRing[0]) === JSON.stringify(linearRing[linearRing.length - 1]);
}

/**
 *
 *
 *
 * selectPolygons
 *
 *
 *
 *
 */
export const selectPolygonsFromRectangle = ({ geojsonData, rectangleGeojson }) => {
  const features = geojsonData.features.filter((ele) => ele.geometry.type === "Polygon");
  const filteredPolygons = features.filter((polygon) => {
    const polygonAsTurf = turf.polygon(polygon.geometry.coordinates);
    const rectangleAsTurf = turf.polygon(rectangleGeojson.geometry.coordinates);
    // Check if the polygon intersects with or is contained within the rectangle
    return turf.booleanOverlap(polygonAsTurf, rectangleAsTurf) || turf.booleanContains(rectangleAsTurf, polygonAsTurf);
  });
  return filteredPolygons;
};
