import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import { ArrowBackIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { useState } from "react";
import { Fade } from "react-reveal";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";
import palette from "themes/palette";
import MuiTabs from "ui/tabs/MuiTabs";

export const SideMenuLayoutThemes = {
  tabs: "tabs",
  buttons: "buttons",
};

const heights = {
  default: "100%",
};

/**
 *
 * A sub layout of subview in pond manager map view with sidebar
 *
 * @returns
 */
const SideMenuLayout = ({ theme = SideMenuLayoutThemes.tabs, title, menulist, defaultViewMode, styles, height = heights.default, actions }) => {
  const [viewMode, setViewMode] = useState(0);

  if (theme === SideMenuLayoutThemes.buttons) {
    return (
      <Box bgcolor={"#ffffff"} height={height || "100%"} position={"relative"}>
        {/* --------------- Top Bar -------------- */}
        <Box position={"absolute"} top={0} left={0} height={"40px"} width={"100%"} bgcolor={"#ffffff"} zIndex={10} px={1} boxSizing={"border-box"}>
          <Stack height={"100%"} direction={"row"} alignItems={"center"}>
            <Fade left distance="20px" duration={300}>
              <Stack direction="row" spacing={1} alignItems={"center"}>
                {actions && actions.back && (
                  <Button startIcon={<ArrowBackIcon />} onClick={actions.back} size="small">
                    <Text>interface.actions.return</Text>
                  </Button>
                )}
                <Typography color="primary" fontSize={13} fontWeight={"bold"} textTransform={"uppercase"} letterSpacing={1}>
                  {title}
                </Typography>
              </Stack>
            </Fade>
          </Stack>
        </Box>
        {/* --------------- Main -------------- */}
        <Grid container sx={{ height: "100%", pt: "40px" }}>
          <Grid
            item
            width={{
              md: 200,
              lg: 300,
            }}
          >
            <Fade left distance="10px" duration={300} delay={100}>
              <Stack spacing={0.3} p={2} pt={4}>
                {menulist.map((ele) => {
                  const selected = viewMode === ele.value;
                  return (
                    <Box
                      key={ele.value}
                      onClick={() => setViewMode(ele.value)}
                      sx={{
                        p: 0.6,
                        borderRadius: 0.8,
                        bgcolor: !selected ? "transparent" : palette.primary.light,
                        "&:hover": {
                          cursor: "pointer",
                          bgcolor: colors.grey[200],
                        },
                        "&:active": {
                          transform: "translateY(1px)",
                        },
                        ".menu-icon": {
                          color: colors.grey[600],
                          fontSize: 15,
                          transform: "translateY(2px)",
                        },
                        ".menu-icon svg": {
                          color: selected ? colors.grey[900] : colors.grey[500],
                          fontSize: 15,
                        },
                        ".menu-label": {
                          fontSize: 13,
                          fontWeight: selected ? 800 : 400,
                        },
                      }}
                    >
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Box className="menu-icon">{ele.icon}</Box>
                        <Typography textTransform={"capitalize"} className="menu-label">
                          {ele.label}
                        </Typography>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </Fade>
          </Grid>
          <Grid
            item
            xs
            sx={{
              height: "100%",
              overflow: "auto",
            }}
          >
            {menulist.filter((ele) => ele.value === viewMode).map((m) => m.component)}
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        ...PondManagerLayoutStyles.rightPanel,
        p: 0,
        ...styles,
      }}
    >
      <Fade left distance="20px" duration={300}>
        <Box display={"inline-block"} p={2} py={1} boxSizing={"border-box"} height={30}>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            {actions && actions.back && (
              <Button startIcon={<ArrowBackIcon />} onClick={actions.back} size="small">
                <Text>interface.actions.return</Text>
              </Button>
            )}
            <Typography color="primary" fontSize={13} fontWeight={"bold"} textTransform={"uppercase"} letterSpacing={1}>
              {title}
            </Typography>
          </Stack>
        </Box>
      </Fade>
      <Grid container height={"calc(100% - 30px)"}>
        <Grid item width={"100px"} borderRight={`1px solid ${colors.grey[200]}`}>
          <Fade left distance="10px" duration={300} delay={100}>
            <Box className="sidebar" py={2}>
              <Box>
                <MuiTabs options={menulist} value={viewMode} vertical onNewValue={setViewMode} />
              </Box>
            </Box>
          </Fade>
        </Grid>
        <Grid
          item
          width={"calc(100% - 100px)"}
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {menulist.filter((ele) => ele.value === viewMode).map((m) => m.component)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SideMenuLayout;
