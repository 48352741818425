import { Box, Stack, Typography } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import NumericFormatters from "helpers/NumericFormatters";
import { fetchEmissionData } from "mock/emissionData";
import React, { useEffect, useState } from "react";

const generateChartConfigs = ({ data }) => {
  return {
    chart: {
      type: "",
      height: 200,
    },
    title: {
      text: "",
    },
    colors: ["green", "blue", "orange", "brown"],
    tooltip: {
      pointFormat: "{series.name}{point.percentage:.0f}%",
    },
    series: [
      {
        type: "pie",
        name: "",
        size: "100%",
        innerSize: "60%",
        data: [
          {
            name: "Feed",
            y: data?.feed,
          },
          {
            name: "Energy",
            y: data?.energy,
          },
          {
            name: "Seed",
            y: data?.seed,
          },
          {
            name: "Land use and pond activity",
            y: data?.lupa,
          },
        ],
      },
    ],
  };
};

const PondEmissionWidget = ({ pond }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    Actions.onLoad();
  }, [pond]);

  const Actions = {
    onLoad: () => {
      if (pond) {
        const rsp = fetchEmissionData({ pondId: pond.id });
        rsp && setData(rsp);
      }
    },
  };

  if (!data) {
    return <VerticalAlertBoxCollections.NoData />;
  }

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Box>
          <Typography>
            <span style={{ color: "#777" }}>
              CO<sub>2</sub>{" "}
              <small>
                (kgCO<sub>2</sub>)
              </small>
            </span>
            <strong> {NumericFormatters.format({ value: data?.co2 })}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <span style={{ color: "#777" }}>
              Emission Intensity{" "}
              <small>
                (kg CO<sub>2</sub> per kg shrimp)
              </small>
            </span>
            <strong> {NumericFormatters.format({ value: data?.intensity })}</strong>
          </Typography>
        </Box>
      </Stack>
      <Box>
        <HighchartsWrapper options={generateChartConfigs({ data })} />
      </Box>
    </Box>
  );
};

export default PondEmissionWidget;
