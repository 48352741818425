import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, Slider, Stack, TextField, Tooltip, Typography, colors } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import _, { endsWith, filter } from "lodash";
import { AgGridReact } from "ag-grid-react";
import * as turf from "@turf/turf";
import WidgetCard from "ui/Card/WidgetCard";
import NumericFormatters from "helpers/NumericFormatters";
import { selectPolygonsFromRectangle, validateGeoJson } from "helpers/GISHelpers";
import FileInputWrapper from "components/Files/FileInputWrapper";
import { DownloadIcon, FilterAltOffIcon, InfoIcon, TableRowsIcon, TableViewIconIcon } from "components/Icons/MaterialIcons";
import { saveObjectAsJsonFile } from "helpers/FileHelpers";
import { calculatePoints, commonInitMaps, simplifyFeature, simplifyGeojson } from "components/map/googlemapHelper";
import { Filter, FilterAlt } from "@mui/icons-material";

const createMapOptions = (maps) => {
  return {
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: maps.MapTypeId.SATELLITE,
  };
};

const shapeStyles = {
  normal: {
    fillColor: "#e7a324",
    fillOpacity: 0.7,
    strokeColor: "#ffffff",
    strokeWeight: 1,
  },
  selected: {
    fillColor: "#ffffff",
    fillOpacity: 0.7,
    strokeColor: "#ffffff",
    strokeWeight: 1,
  },
};

const Filters = ({ values, onChange, orginalGeoJson, processedGeoJson }) => {
  const [simplifierEnabled, setSimplifierEnabled] = useState(values.simplifierEnabled || false);
  const [simplifierThreshold, setSimplifierThreshold] = useState(values.simplifierThreshold || 20);
  const [minimumArea, setMinimumArea] = useState(values.minimumArea || 0);

  const originalGeoJsonPoints = calculatePoints(orginalGeoJson);
  const processedGeoJsonPoints = calculatePoints(processedGeoJson);
  const reduceRatio = originalGeoJsonPoints ? processedGeoJsonPoints / originalGeoJsonPoints - 1 : 0;

  const Actions = {
    onSubmit: () => {
      const form = {
        simplifierEnabled,
        simplifierThreshold,
        minimumArea,
      };
      onChange({
        ...values,
        ...form,
      });
    },
  };

  return (
    <Stack divider={<Divider />} spacing={2}>
      <Box>
        <Typography>Polygon Simplifier</Typography>
        <FormControlLabel control={<Checkbox checked={simplifierEnabled} onChange={(e) => setSimplifierEnabled(e.target.checked)} />} label="Simplifier" />
        {simplifierEnabled && (
          <>
            <Slider valueLabelDisplay="auto" value={simplifierThreshold} max={100} onChange={(e, val) => setSimplifierThreshold(val)} />
          </>
        )}
      </Box>
      <Box>
        <Typography>Minimum Area (m2)</Typography>
        <TextField size={"small"} fullWidth value={minimumArea} type="number" onChange={(e) => setMinimumArea(parseFloat(e.target.value))}></TextField>
      </Box>
      <Box>
        <Typography>Stats</Typography>
        <Typography fontSize={10}>Reduce Ratio: {NumericFormatters.format({ value: reduceRatio * 100 })} %</Typography>
      </Box>
      <Box mt={1}>
        <Button onClick={Actions.onSubmit}>Apply</Button>
      </Box>
    </Stack>
  );
};

const GeojsonPondSelector = ({ geoJsonData }) => {
  const [filters, setFilters] = useState({
    simplifierEnabled: false,
    simplifierThreshold: 7,
    minimumArea: 0,
  });

  const [geojsonData, setGeojsonData] = useState(geoJsonData);
  const [filteredGeojsonData, setFilteredGeojsonData] = useState(geojsonData);
  const [currentFileName, setCurrentFileName] = useState("download.geojson");
  const [tableConfigs, setTableConfigs] = useState(null);
  const [featuresToRemove, setFeaturesToRemove] = useState([]);
  const [featuresToFilterOut, setFeaturesToFilterOut] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [highlightedPolygon, setHighlightedPolygon] = useState(null);
  const [highlightedPolygons, setHighlightedPolygons] = useState([]);

  const [mapCenter, setMapCenter] = useState([-3.1877999452261236, -80.3073759273808]);
  const [mapZoom, setMapZoom] = useState(10);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);

  const mapRef = useRef(null);

  const handleApiLoaded = async (map, maps) => {
    commonInitMaps(maps);

    // drawing
    const drawing = await maps.importLibrary("drawing");
    const drawingManager = new drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
        drawingModes: [drawing.OverlayType.RECTANGLE, drawing.OverlayType.POLYGON],
      },
      rectangleOptions: {
        fillColor: colors.blue[600],
        strokeColor: colors.blue[600],
        fillOpacity: 0.4,
        strokeWeight: 3,
      },
      polygonOptions: {
        fillColor: colors.blue[600],
        strokeColor: colors.blue[600],
        fillOpacity: 0.4,
        strokeWeight: 3,
      },
    });
    drawingManager.setMap(map);

    setMap(map);
    setMaps(maps);
    setDrawingManager(drawingManager);
  };

  /** when features to be removed changed */
  useEffect(() => {
    Actions.updateMapStyleAfterFeaturesRemoved();
  }, [featuresToRemove]);

  /** when highlighed features changed */
  useEffect(() => {
    Actions.updateMapStyleAfterHighlight();
  }, [highlightedPolygons]);

  // useEffect(() => {
  //   if (!filteredGeojsonData) return;
  //   // apply minimum
  //   const simplified = filters?.simplifierEnabled ? simplifyGeojson({ geojson: geojsonData, tolerance: filters.simplifierThreshold / 1000000 }) : geojsonData;
  //   const filteredGeojsonDataAfter = {
  //     ...simplified,
  //     features: simplified.features.map((item) => {
  //       const filteredByArea = item?.properties?._areaM < filters?.minimumArea;
  //       const filteredBySimplifier = filters?.simplifierEnabled;
  //       // const simplifiedFeature = filteredBySimplifier ? simplifyFeature({ feature: item, tolerance: filters.simplifierThreshold / 100 }) : item;
  //       return {
  //         ...item,
  //         _filters: {
  //           isFilteredByArea: filteredByArea,
  //         },
  //       };
  //     }),
  //   };
  //   setFilteredGeojsonData(filteredGeojsonDataAfter);
  // }, [filters]);

  // useEffect(() => {
  //   filteredGeojsonData && Actions.updateMapStyleAfterFilter();
  // }, [filteredGeojsonData]);

  const Actions = {
    handleMapKeyboardShortcuts: (e) => {
      if (e.key === "Delete" || e.key === "x") {
        Actions.handleRemovedFeature();
      }
      if (e.key === "z") {
        Actions.handleRevertFeature();
      }
      if (e.key === "1" || e.key === "Space") {
        drawingManager &&
          drawingManager.setOptions({
            drawingMode: null,
          });
      }
      if (e.key === "2") {
        drawingManager &&
          drawingManager.setOptions({
            drawingMode: "rectangle",
          });
      }
      if (e.key === "3") {
        drawingManager &&
          drawingManager.setOptions({
            drawingMode: "polygon",
          });
      }
    },
    handleRemovedFeature: () => {
      // handle single item deletion
      if (highlightedPolygon) {
        const itemIfExist = _.find(featuresToRemove, { _id: highlightedPolygon._id });
        if (itemIfExist) {
          setFeaturesToRemove(featuresToRemove.filter((item) => item.id === highlightedPolygon._id));
        } else {
          setFeaturesToRemove([...featuresToRemove, highlightedPolygon]);
        }
        setHighlightedPolygon(null);
      }
      // handle multiple items deletion
      if (highlightedPolygons.length > 0) {
        const polygonsToAppend = highlightedPolygons.filter((ele) => !_.find(featuresToRemove, { _id: ele.properties._id })).map((ele) => ele.properties);
        setFeaturesToRemove([...featuresToRemove, ...polygonsToAppend]);
        setHighlightedPolygons([]);
      }
    },
    handleRevertFeature: () => {
      const lastFeature = _.last(featuresToRemove);
      if (lastFeature && map && maps) {
        const fullFeature = geojsonData.features.filter((ele) => ele.properties._id === lastFeature._id)[0];
        map.data.addGeoJson(fullFeature);
        setFeaturesToRemove(_.dropRight(featuresToRemove, 1));
      }
    },
    displayMap: async (jsonData) => {
      if (jsonData && map && maps) {
        // filter
        const polygonData = {
          ...jsonData,
        };
        // const pointData = {
        //   ...polygonData,
        //   features: polygonData.features.map((item) => ({
        //     ...item,
        //     type: "Feature",
        //     geometry: {
        //       type: "Point",
        //       coordinates: item.properties.centroid,
        //     },
        //     properties: {
        //       ...item.properties,
        //     },
        //   })),
        // };

        // clear data
        map.data.forEach(function (feature) {
          // filter...
          map.data.remove(feature);
        });
        // load geojson

        map.data.addGeoJson(polygonData, {
          idPropertyName: "jsonData",
        });
        // set style
        map.data.setStyle(shapeStyles.normal);

        // style cliked event
        map.data.addListener("click", (e, f) => {
          e.feature.toGeoJson((geojson) => {
            setHighlightedPolygons([geojson]);
          });
        });

        map.data.addListener("rightclick", function (e, f) {
          console.log(e, f);
        });
        // --------------- fit bounds
        const bounds = new maps.LatLngBounds();
        map.data.forEach(function (feature) {
          feature.getGeometry().forEachLatLng(function (latlng) {
            bounds.extend(latlng);
          });
        });
        map.fitBounds(bounds);

        maps.event.addListener(drawingManager, "rectanglecomplete", (selectedArea) => {
          setTimeout(() => {
            selectedArea.setMap(null);
          }, 200);
          const selectedAreaBounds = selectedArea.getBounds().toJSON();
          const selectedAreaFeature = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [selectedAreaBounds.west, selectedAreaBounds.north],
                  [selectedAreaBounds.east, selectedAreaBounds.north],
                  [selectedAreaBounds.east, selectedAreaBounds.south],
                  [selectedAreaBounds.west, selectedAreaBounds.south],
                  [selectedAreaBounds.west, selectedAreaBounds.north],
                ],
              ],
            },
          };
          const filtered = selectPolygonsFromRectangle({
            geojsonData: jsonData,
            rectangleGeojson: selectedAreaFeature,
          });
          setHighlightedPolygons(filtered);
        });

        maps.event.addListener(drawingManager, "polygoncomplete", (selectedArea) => {
          setTimeout(() => {
            selectedArea.setMap(null);
          }, 200);
          const selectedAreaGeoJson = selectedArea.toGeoJson();
          const filtered = selectPolygonsFromRectangle({
            geojsonData: jsonData,
            rectangleGeojson: selectedAreaGeoJson,
          });
          setHighlightedPolygons(filtered);
        });

        //
        // Table
        //

        const tableConfigs = {
          rowData: jsonData.features,
          columnDefs: [
            {
              field: "properties._id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "properties._area",
              headerName: "AREA",
              flex: 1,
            },
            {
              field: "geometry.type",
              headerName: "TYPE",
              flex: 1,
            },
          ],
          rowHeight: 30,
          headerHeight: 30,
        };
        setTableConfigs(tableConfigs);
      }
    },
    updateMapStyleAfterHighlight: () => {
      if (map && maps) {
        map.data.forEach((ft) => {
          if (
            _.find(
              highlightedPolygons.map((ele) => ele.properties),
              { _id: ft.getProperty("_id") }
            )
          ) {
            map.data.overrideStyle(ft, shapeStyles.selected);
          } else {
            map.data.overrideStyle(ft, shapeStyles.normal);
          }
        });
      }
    },
    updateMapStyleAfterFeaturesRemoved: () => {
      if (map && maps) {
        map.data.forEach((ft) => {
          if (_.find(featuresToRemove, { _id: ft.getProperty("_id") })) {
            map.data.remove(ft);
          }
        });
      }
    },
    updateMapStyleAfterFilter: () => {
      if (map && maps) {
        map.data.forEach((ft) => {
          const ftId = ft.getProperty("_id");
          const coordinates = _.find(filteredGeojsonData.features, { properties: { _id: ftId } })?.geometry?.coordinates?.[0];
          if (!coordinates) return;
          const paths = coordinates.map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          });
          const newGeometry = new maps.Data.Polygon([paths]);
          ft.setGeometry(newGeometry);
          const filterState = _.find(filteredGeojsonData.features, { properties: { _id: ftId } })?._filters;
          const isFilteredByArea = filterState?.isFilteredByArea;
          const visibility = !isFilteredByArea;
          map.data.overrideStyle(ft, {
            ...shapeStyles.normal,
            visible: visibility,
          });
        });
      }
    },
    handleFileChange: (e) => {
      // pre-cleanup
      // clear featuresToRemove
      setFeaturesToRemove([]);
      setHighlightedPolygons([]);

      // const filePath = URL.createObjectURL(e.target.files[0]);
      if (!e.target.files[0]) return;
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      const fileName = e.target.files[0].name;
      fileReader.onload = (e) => {
        let jsonData = JSON.parse(e.target.result);
        // validate
        if (!validateGeoJson(jsonData)) {
          window.alert("Invalid GeoJSON");
          return;
        }
        // add properties
        jsonData = {
          ...jsonData,
          features: jsonData.features
            .map((ft, idx) => {
              if (ft.geometry.type === "Point") {
                return {
                  ...ft,
                  properties: {
                    ...ft.properties,
                    _id: idx,
                    _type: ft.geometry.type,
                    _area: 0,
                    _areaM: 0,
                    _centroid: null,
                  },
                };
              }
              if (ft.geometry.coordinates.length === 0 || ft.geometry.type !== "Polygon") {
                return {
                  ...ft,
                  properties: {
                    ...ft.properties,
                    _id: idx,
                    _type: ft.geometry.type,
                    _area: 0,
                    _areaM: 0,
                    _centroid: null,
                  },
                };
              }
              const turfPolygon = turf.polygon(ft.geometry.coordinates);
              const turfArea = turf.area(turfPolygon) / 10000; // ha
              const turfAreaM = turf.area(turfPolygon); // m2
              const turfCentroid = turf.centroid(turfPolygon).geometry.coordinates;
              console.log(turfPolygon, turfArea, turfCentroid);
              return {
                ...ft,
                geometry: {
                  ...ft.geometry,
                  coordinates: [ft.geometry.coordinates[0] || []],
                },
                properties: {
                  ...ft.properties,
                  _id: idx,
                  _type: ft.geometry.type,
                  _area: turfArea,
                  _areaM: turfAreaM,
                  _centroid: turfCentroid,
                },
              };
            })
            .filter((item) => item.geometry.type === "Polygon"),
        };
        // sort by area
        jsonData = {
          ...jsonData,
          features: _.orderBy(jsonData.features, (e) => e.properties._area),
        };
        setGeojsonData(jsonData);
        setFilteredGeojsonData(jsonData);
        setCurrentFileName(fileName);
        Actions.displayMap(jsonData);
      };
    },
    /** ------ Download Data as JSON ------ */
    downloadGeoJson: () => {
      if (filteredGeojsonData) {
        let savedGeojsonData = filteredGeojsonData;
        console.log(savedGeojsonData);
        savedGeojsonData = {
          ...savedGeojsonData,
          features: savedGeojsonData.features
            // filter out removed filteres
            .filter((ele) => !_.find(featuresToRemove, { _id: ele.properties._id }))
            // filter out area filtered
            .filter((ele) => !ele?._filters?.isFilteredByArea),
        };
        saveObjectAsJsonFile({
          data: savedGeojsonData,
          filename: currentFileName,
        });
      }
    },
  };

  const geometryStats = _.groupBy(
    geojsonData?.features?.map((ele) => ele.geometry),
    "type"
  );

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
      }}
      onKeyDown={Actions.handleMapKeyboardShortcuts}
    >
      <Box
        sx={{
          position: "absolute",
          left: 12,
          top: 12,
          minWidth: 400,
          zIndex: 99,
          bgcolor: "#FFF",
          p: 0.5,
          borderRadius: 4,
        }}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Box>
            <FileInputWrapper accept="application/geo+json,application/json" onChange={Actions.handleFileChange} buttonLabel="Upload GeoJSON" />
          </Box>
          <Box>
            <Typography>
              Features: <strong>{geojsonData?.features?.length || "--"}</strong>
            </Typography>
          </Box>
          <Box>
            <Typography>
              Removed: <strong>{featuresToRemove.length || 0}</strong>
            </Typography>
          </Box>
          <Tooltip
            placement="bottom"
            title={
              !geojsonData ? (
                "No Data"
              ) : (
                <Stack>
                  <Typography>
                    {_.keys(geometryStats).map((k) => (
                      <Typography key={k}>
                        {k}: {geometryStats[k].length}
                      </Typography>
                    ))}
                  </Typography>
                </Stack>
              )
            }
          >
            <Box>
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Button variant="contained" onClick={Actions.downloadGeoJson} startIcon={<DownloadIcon />}>
            Save and Download
          </Button>
          <Button variant={filtersVisible ? "contained" : "outlined"} onClick={() => setFiltersVisible(!filtersVisible)} startIcon={<FilterAlt />}>
            Filters
          </Button>
          <Button variant={tableVisible ? "contained" : "outlined"} onClick={() => setTableVisible(!tableVisible)} startIcon={<TableRowsIcon />}>
            Table
          </Button>
        </Stack>
      </Box>
      {
        // highlightedPolygon && (
        //   <Box
        //     sx={{
        //       position: "absolute",
        //       left: 12,
        //       bottom: 12,
        //       minWidth: 400,
        //       background: "transparent",
        //       zIndex: 999,
        //       opacity: 1,
        //       maxHeight: 400,
        //       overflow: "auto",
        //     }}
        //   >
        // <WidgetCard title={highlightedPolygon?._type}>
        //   <Stack sx={{ "*": { fontSize: 11 } }}>
        //     <Typography>
        //       ID: <strong>{highlightedPolygon?._id}</strong>
        //     </Typography>
        //     <Typography>
        //       AREA: <strong>{NumericFormatters.formatAreaInHa({ value: highlightedPolygon?._area })}</strong>
        //     </Typography>
        //     <Divider />
        //     {_.keys(highlightedPolygon)
        //       .filter((ele) => !_.includes(["_id", "_type", "_area"], ele))
        //       .map((k) => (
        //         <Typography key={k}>
        //           {k}: <strong>{highlightedPolygon?.[k]}</strong>
        //         </Typography>
        //       ))}
        //     <Box mt={1}>
        //       <Button variant="contained" color="error" onClick={Actions.handleRemovedFeature} fullWidth>
        //         DELETE (or press button "Del")
        //       </Button>
        //     </Box>
        //   </Stack>
        // </WidgetCard>
        //   </Box>
        // )
      }
      {highlightedPolygons.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            left: 12,
            bottom: 12,
            minWidth: 400,
            background: "transparent",
            zIndex: 999,
            opacity: 1,
            maxHeight: 400,
            overflow: "auto",
          }}
        >
          {highlightedPolygons.length === 1 ? (
            <WidgetCard title={highlightedPolygons[0]?.properties?._type}>
              <Stack sx={{ "*": { fontSize: 11 } }}>
                <Typography>
                  ID: <strong>{highlightedPolygons[0]?.properties?._id}</strong>
                </Typography>
                <Typography>
                  AREA: <strong>{NumericFormatters.formatAreaInHa({ value: highlightedPolygons[0]?.properties?._area })}</strong>
                </Typography>
                <Divider />
                {_.keys(highlightedPolygons[0]?.properties)
                  .filter((ele) => !_.includes(["_id", "_type", "_area"], ele))
                  .map((k) => (
                    <Typography key={k}>
                      {k}: <strong>{highlightedPolygons[0]?.properties?.[k]}</strong>
                    </Typography>
                  ))}
                <Box mt={1}>
                  <Button variant="contained" color="error" onClick={Actions.handleRemovedFeature} fullWidth>
                    DELETE (or press button "Del")
                  </Button>
                </Box>
              </Stack>
            </WidgetCard>
          ) : (
            <WidgetCard title={`${highlightedPolygons.length} Selected`}>
              <Box mt={1}>
                <Button variant="contained" color="error" onClick={Actions.handleRemovedFeature} fullWidth>
                  DELETE (or press button "Del")
                </Button>
              </Box>
            </WidgetCard>
          )}
        </Box>
      )}
      {tableVisible && (
        <Box
          sx={{
            position: "absolute",
            top: 70,
            bottom: 200,
            right: 12,
            zIndex: 999,
            opacity: 1,
            overflow: "auto",
            height: "auto",
            width: "50%",
          }}
        >
          <WidgetCard title="Table" icon={<TableViewIconIcon />} sx={{ height: "100%", position: "relative" }}>
            <Box
              className="ag-theme-excel"
              sx={{
                position: "relative",
                height: "calc(100vh - 380px)",
              }}
            >
              {tableConfigs && <AgGridReact {...tableConfigs} />}
            </Box>
          </WidgetCard>
        </Box>
      )}
      {filtersVisible && (
        <Box
          sx={{
            position: "absolute",
            top: 70,
            bottom: 70,
            left: 12,
            zIndex: 999,
            opacity: 1,
            overflow: "auto",
            width: "20%",
          }}
        >
          <WidgetCard title="Filteres" icon={<FilterAlt />}>
            <Filters values={filters} onChange={(form) => setFilters(form)} orginalGeoJson={geojsonData} processedGeoJson={filteredGeojsonData} />
          </WidgetCard>
        </Box>
      )}
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        center={mapCenter}
        defaultZoom={mapZoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </Box>
  );
};

export default GeojsonPondSelector;
