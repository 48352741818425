import { colors } from "@mui/material";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import currencies from "json/currencies.json";

const NumericFormatters = {
  format: ({ value, suffix, decimalScale = 2 }) => {
    return <NumberFormat displayType="text" value={value} thousandSeparator="," suffix={suffix} decimalScale={decimalScale} />;
  },
  formatWeightInKg: ({ value, decimalScale = 2, dynamic = true }) => {
    if (dynamic && value > 1000) {
      const displayValue = value / 1000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" t" thousandSeparator="," decimalScale={decimalScale} />;
    } else if (dynamic && value > 0 && value < 1) {
      const displayValue = value * 1000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" g" thousandSeparator="," decimalScale={decimalScale} />;
    } else {
      return <NumberFormat displayType="text" value={value} suffix=" kg" thousandSeparator="," decimalScale={decimalScale} />;
    }
  },
  formatWeightInG: ({ value, decimalScale = 2, dynamic = true }) => {
    if (dynamic && value > 1000) {
      const displayValue = value / 1000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" kg" thousandSeparator="," decimalScale={decimalScale} />;
    } else if (dynamic && value > 1000000) {
      const displayValue = value / 1000 / 1000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" t" thousandSeparator="," decimalScale={decimalScale} />;
    } else {
      return <NumberFormat displayType="text" value={value} suffix=" g" thousandSeparator="," decimalScale={decimalScale} />;
    }
  },
  formatAreaInM2: ({ value, decimalScale = 2, dynamic = true }) => {
    if (dynamic && value > 1000) {
      const displayValue = value / 10000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" Ha." thousandSeparator="," decimalScale={decimalScale} />;
    } else {
      return <NumberFormat displayType="text" value={value} suffix=" m2" thousandSeparator="," decimalScale={decimalScale} />;
    }
  },
  formatAreaInHa: ({ value, decimalScale = 2, dynamic = true }) => {
    if (dynamic && value < 0.1) {
      const displayValue = value * 10000;
      return <NumberFormat displayType="text" value={displayValue} suffix=" m2" thousandSeparator="," decimalScale={decimalScale} />;
    } else {
      return <NumberFormat displayType="text" value={value} suffix=" ha." thousandSeparator="," decimalScale={decimalScale} />;
    }
  },
  formatBiomass: ({ value }) => {
    const display = value ? numeral(value).format("0,0") : "-";
    return display;
  },
  formatBiomassInTons: ({ value }) => {
    const display = value ? numeral(value / 1000).format("0,0") + "t" : "-";
    return display;
  },
  formatDollar: ({ value, decimalScale = 0, symbol = "$", currencyCode = "USD" }) => {
    const displaySymbole = currencyCode ? currencies?.[currencyCode]?.symbol_native : symbol;
    const format = "0,0" + (!decimalScale ? "" : "." + "0".repeat(decimalScale));
    const display = symbol + (value ? numeral(value).format(format) : "-");
    return display;
  },
  formatCurrency: ({ value, decimalScale = 0, currencyCode = "USD" }) => {
    const displaySymbol = currencies?.[currencyCode]?.symbol_native;
    const format = "0,0" + (!decimalScale ? "" : "." + "0".repeat(decimalScale));
    const display = displaySymbol + " " + (value ? numeral(value).format(format) : "-");
    return display;
  },
  formatCurrencySymbol: ({ value = "USD" }) => {
    const displaySymbol = currencies?.[value]?.symbol_native;
    return displaySymbol;
  },
  formatFarmCurrency: ({ value, decimalScale = 0, farm }) => {
    const currencyCode = farm?.settings?.currency || "USD";
    return NumericFormatters.formatCurrency({ value, decimalScale, currencyCode });
  },
  formatTemperature: ({ value, decimalScale, unit = " °C" }) => {
    return NumericFormatters.format({ value, decimalScale, suffix: unit });
  },
  formatDollarValueWithDifference: ({ value, diff, posColor, negColor }) => {
    const posC = posColor || colors.green[800];
    const negC = negColor || colors.red[800];
    const color = diff > 0 ? posC : diff < 0 ? negC : null;
    return (
      <span
        style={{
          color: color,
        }}
      >
        <NumberFormat displayType="text" value={value} prefix="$" decimalScale={0} thousandSeparator="," />{" "}
        {diff ? (
          <small>
            <NumberFormat displayType="text" value={diff} prefix={diff > 0 ? "+$" : "$"} decimalScale={0} thousandSeparator="," />
          </small>
        ) : (
          ""
        )}
      </span>
    );
  },
};

export function formatValueByFieldId(val, fieldId) {
  if (!val) return "";
  if (fieldId === "weight") {
    return numeral(val).format("0,0.0") + "g";
  }
  if (fieldId === "weight_diff") {
    return numeral(val).format("0,0.0") + "g";
  }
  if (fieldId === "organisms") {
    return numeral(val).format("0,0");
  }
  if (fieldId === "current_density") {
    return numeral(val).format("0,0.0") + "/sqm";
  }
  if (fieldId === "seeded_density") {
    return numeral(val).format("0,0.0") + " /sqm";
  }
  if (fieldId === "biomass") {
    return numeral(val / 1000).format("0,0.0") + "t";
  }
  if (fieldId === "biomass_per_area") {
    return numeral(val).format("0,0.000") + "kg";
  }
  if (fieldId === "biomass_per_area_t") {
    return numeral(val).format("0,0.000") + "kg";
  }
  if (fieldId === "biomass_per_pl") {
    return numeral(val).format("0,0.000") + "kg";
  }
  if (fieldId === "biomass_per_pl_t") {
    return numeral(val).format("0,0.000") + "kg";
  }
  if (fieldId === "feed_daily") {
    return numeral(val).format("0,0.0") + "t";
  }
  if (fieldId === "feed_accumulated") {
    return numeral(val).format("0,0.0") + "kg";
  }
  if (fieldId === "survival_rate") {
    return numeral(val).format("0") + "%";
  }

  if (fieldId === "sgr") {
    return numeral(val).format("0,0.00") + "%";
  }
  if (fieldId === "mortality") {
    return numeral(val).format("0") + "%";
  }
  if (fieldId === "mortality_rate") {
    return numeral(val).format("0,0.000") + "%";
  }
  // WQ
  if (fieldId === "water_temp") {
    return numeral(val).format("0,0.0") + "°C";
  }
  if (val < 1) {
    return numeral(val).format("0,0.000");
  }
  if (val > 10000) {
    return numeral(val).format("0,0");
  }
  return numeral(val).format("0,0.0");
}

export default NumericFormatters;
