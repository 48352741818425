import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import _ from "lodash";
import { RefreshIcon } from "components/Icons/MaterialIcons";
import { commonInitMaps } from "components/map/googlemapHelper";
import { PondManagerServices } from "api/pondManagerServices";
import FeatureCard from "screens/Aquaculture/AquaExplorer/components/FeatureCard";
import { ShrimplIconOnlySvg, ShrimplLogoSvg, ShrimplLogoWhiteSvg } from "components/Images";
import { Fade } from "react-reveal";

const createMapOptions = (maps) => {
  return {
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: maps.MapTypeId.SATELLITE,
    minZoom: 5,
  };
};

const shapeStyles = {
  normal: {
    fillColor: "#00b7ff",
    fillOpacity: 0.4,
    strokeColor: "#e0f6ff",
    strokeWeight: 1,
  },
  selected: {
    fillColor: "#ffffff",
    fillOpacity: 0.7,
    strokeColor: "#ffffff",
    strokeWeight: 1,
  },
};

const AquaExplorer = () => {
  const [mapCenter, setMapCenter] = useState([-2.585671742540449, -80.01776784245965]);
  const [mapZoom, setMapZoom] = useState(13);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFeature, setFeature] = useState(null);

  const [query, setQuery] = useState({});

  const mapRef = useRef(null);

  const handleApiLoaded = async (map, maps) => {
    commonInitMaps(maps);
    setMap(map);
    setMaps(maps);
    // first load
    const bounds = map.getBounds().toJSON();
    const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
    const zoom = map.getZoom();
    setQuery({
      bbox: bbox,
      zoom: zoom,
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      Actions.loadData();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const Actions = {
    handleMapChange: (e) => {
      if (map) {
        const bounds = map.getBounds().toJSON();
        const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
        const zoom = map.getZoom();
        setQuery({
          bbox: bbox,
          zoom: zoom,
        });
      }
    },
    loadData: async () => {
      if (_.isEmpty(query)) return;
      // if (query?.zoom < 8) return;
      setLoading(true);
      const result = await PondManagerServices.fetchAquaExplorerResult(query);
      setLoading(false);
      if (map && maps) {
        maps.event.clearListeners(map.data, "click");
        map.data.forEach(function (feature) {
          map.data.remove(feature);
        });
        map.data.addGeoJson(result, {
          idPropertyName: "jsonData",
        });
        map.data.addListener("click", function (e) {
          e.feature.toGeoJson((e) => {
            setFeature(e);
          });
        });
        // set style
        map.data.setStyle(shapeStyles.normal);
      }
    },
    unselectFeature: () => {
      setFeature(null);
    },
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
      }}
      onKeyDown={Actions.handleMapKeyboardShortcuts}
    >
      <Box
        sx={{
          position: "absolute",
          left: 10,
          top: 10,
          height: 60,
          zIndex: 99,
        }}
      >
        <Fade top duration={500} distance={"20px"}>
          <Stack p={1} alignItems={"flex-end"}>
            <img src={ShrimplLogoWhiteSvg} alt="" width="120" />
            <Typography color="white" fontSize={13} fontWeight={800} sx={{ marginTop: -1 }}>
              AquaExplorer
            </Typography>
            {/* <Button size="large" variant="contained" onClick={Actions.loadData} startIcon={<RefreshIcon />}>
              Reload
            </Button> */}
          </Stack>
        </Fade>
      </Box>

      {selectedFeature && (
        <Box
          sx={{
            position: "absolute",
            left: "10%",
            right: "10%",
            bottom: 26,
            zIndex: 999,
            opacity: 1,
            overflow: "auto",
          }}
        >
          <FeatureCard feature={selectedFeature} actions={Actions} />
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 99,
        }}
      >
        {loading && <LinearProgress width="100%" color="secondary" sx={{ height: "2px" }} />}
      </Box>

      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        center={mapCenter}
        defaultZoom={mapZoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={Actions.handleMapChange}
      ></GoogleMapReact>
    </Box>
  );
};

export default AquaExplorer;
