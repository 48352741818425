import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SearchIcon } from "components/Icons/MaterialIcons";
import { useFormik } from "formik";

const ImageQueryForm = ({ filters, setFilters }) => {
  const formik = useFormik({
    initialValues: filters,
    onSubmit: (values) => setFilters(values),
  });
  const { values, handleSubmit, handleChange, setFieldValue } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2} px={2}>
        <Box width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="From"
              onChange={(newVal) => setFieldValue("dateFrom", newVal)}
              name={"dateFrom"}
              value={values.dateFrom}
              slotProps={{ textField: { variant: "standard", fullWidth: true } }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="To"
              onChange={(newVal) => setFieldValue("dateTo", newVal)}
              name={"dateTo"}
              value={values.dateTo}
              slotProps={{ textField: { variant: "standard", fullWidth: true } }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box width={"100%"}>
          <TextField label="Search by Tag" fullWidth variant="standard" name="tag" onChange={handleChange} value={values.tag}></TextField>
        </Box>
        <Box width={"100%"}>
          <TextField label="Search by Label" fullWidth variant="standard" name="label" onChange={handleChange} value={values.label}></TextField>
        </Box>
      </Stack>
      <Box mt={1} px={2}>
        <Button type="submit" fullWidth startIcon={<SearchIcon />}>
          Search
        </Button>
      </Box>
    </form>
  );
};

export default ImageQueryForm;
