import { Box, TextField, Typography, colors } from "@mui/material";
import React from "react";
import { isMobile } from "themes/helpers";

export const MuiBigTextFieldWrapper = ({ label, children }) => {
  const onMobile = isMobile();
  return (
    <Box
      p={2}
      borderRadius={2}
      bgcolor={"#FFF"}
      sx={{
        transition: "all .4s",
        "&:hover": onMobile
          ? {}
          : {
              bgcolor: "#FFF",
              boxShadow: 1,
            },
      }}
    >
      <Typography fontSize={12} fontWeight={800} color={colors.grey[800]}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const MuiBigTextField = ({ label, ...props }) => {
  return (
    <MuiBigTextFieldWrapper label={label}>
      <TextField
        placeholder="0.00"
        sx={{
          pt: 3,
          fontSize: 50,
          "&:after": {
            border: 0,
          },
        }}
        inputProps={{ style: { fontSize: 30 }, step: 0.01 }}
        variant="standard"
        {...props}
      ></TextField>
    </MuiBigTextFieldWrapper>
  );
};

export default MuiBigTextField;
