import { useState } from "react";
import { Stack, Box, LinearProgress } from "@mui/material";
import Text from "components/text/Text";
import PondBiologicalDashboard from "screens/Aquaculture/components/Dashboard/PondBiologicalDashboard";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import PondWaterQualityDashboard from "screens/Aquaculture/components/Dashboard/PondWaterQualityDashboard";
import PondSummaryDashboard from "screens/Aquaculture/components/Dashboard/PondSummaryDashboard";
import { Navigate } from "react-router-dom";
import PondFeedDashboard from "screens/Aquaculture/components/Dashboard/PondFeedDashboard";
import PondForecastDashboard from "screens/Aquaculture/components/Dashboard/PondForecastDashboard";
import PondSateyeDashboard from "screens/Aquaculture/components/Dashboard/PondSateyeDashboard";
import PondTreatmentDashboard from "screens/Aquaculture/components/Dashboard/PondTreatmentDashboard";
import PondAdditiveDashboard from "screens/Aquaculture/components/Dashboard/PondAdditiveDashboard";
import PondFinancialDashboard from "screens/Aquaculture/components/Dashboard/PondFinancialDashboard";
import { ListIcon } from "components/Icons/MaterialIcons";

const viewModes = {
  OVERVIEW: {
    name: <Text>interface.general.summary</Text>,
    value: 0,
  },
  BIOLOGICAL: {
    name: <Text>interface.general.biological</Text>,
    value: 1,
  },
  WATER_QUALITY: {
    name: <Text>interface.general.water-quality</Text>,
    value: 2,
  },
  FEED: {
    name: <Text>interface.general.feed</Text>,
    value: 3,
  },
  ADDITIVE: {
    name: <Text>interface.general.additive</Text>,
    value: 4,
  },
  TREATMENT: {
    name: <Text>interface.general.pond-treatment</Text>,
    value: 5,
  },
  FINANCIAL: {
    name: <Text>interface.general.financial</Text>,
    value: 6,
  },
  FORECAST: {
    name: <Text>interface.general.forecast</Text>,
    value: 7,
  },
  SATEYE: {
    name: <Text fallback={"SATEYE"}>interface.general.sateye</Text>,
    value: 8,
  },
};

/**
 * Pond Dashboard Index
 * - Pond biological dashboard
 * - Pond summary dashboard
 * - Pond water quality dashboard
 * - Pond feed dashboard
 * - Pond farming product dashboard
 *
 * @param {*} param0
 * @returns
 */
const PondDashboardView = ({ farm, pond, group, onSwitchToDataViewField }) => {
  // const [mode, setMode] = useState(viewModes.OVERVIEW.value);
  const [mode, setMode] = useState(viewModes.OVERVIEW.value);

  const initialPanelComponents = _.orderBy(_.values(viewModes), "value");

  const Pages = {
    PondSummaryDashboard: () => <PondSummaryDashboard pond={pond} farm={farm} onSwitchToDataViewField={onSwitchToDataViewField} />,
    PondBiologicalDashboard: <PondBiologicalDashboard pond={pond} farm={farm} />,
    PondWaterQualityDashboard: () => <PondWaterQualityDashboard pond={pond} farm={farm} />,
    PondFeedDashboard: () => <PondFeedDashboard pond={pond} farm={farm} />,
    PondForecastDashboard: () => <PondForecastDashboard pond={pond} farm={farm} />,
    PondSateyeDashboard: () => <PondSateyeDashboard pond={pond} farm={farm} />,
    PondTreatmentDashboard: () => <PondTreatmentDashboard pond={pond} farm={farm} />,
    PondAdditiveDashboard: () => <PondAdditiveDashboard pond={pond} farm={farm} />,
    PondFinancialDashboard: () => <PondFinancialDashboard key="PondFinancialDashboard" pond={pond} farm={farm} />,
  };

  return (
    <Box>
      <Box px={2}>
        <MuiTabs
          value={mode}
          onNewValue={(val) => setMode(val)}
          options={initialPanelComponents.map((ele) => ({
            label: ele.name,
            value: ele.value,
            icon: ele.icon,
          }))}
          theme={MuiTabsThemes.chip}
        />
      </Box>
      <Stack spacing={2}>
        {/* SUMMARY */}
        {mode === viewModes.OVERVIEW.value && <Pages.PondSummaryDashboard />}
        {/* BIOLOGICAL */}
        {mode === viewModes.BIOLOGICAL.value && Pages.PondBiologicalDashboard}
        {/* WATER QUALITY */}
        {mode === viewModes.WATER_QUALITY.value && <Pages.PondWaterQualityDashboard />}
        {/* FEED */}
        {mode === viewModes.FEED.value && <Pages.PondFeedDashboard />}
        {/* ADDITIVE */}
        {mode === viewModes.ADDITIVE.value && <Pages.PondAdditiveDashboard />}
        {/* TREATMENT */}
        {mode === viewModes.TREATMENT.value && <Pages.PondTreatmentDashboard />}
        {/* FINANCIAL */}
        {mode === viewModes.FINANCIAL.value && <PondFinancialDashboard key="PondFinancialDashboard" pond={pond} farm={farm} />}
        {/* FORECAST */}
        {mode === viewModes.FORECAST.value && <Pages.PondForecastDashboard />}
        {/* SATEYE */}
        {mode === viewModes.SATEYE.value && <Pages.PondSateyeDashboard />}
      </Stack>
    </Box>
  );
};

export default PondDashboardView;
