import { Box, Grid, Stack, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { RefreshIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Text, { getText } from "components/text/Text";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import FarmingProductRegistryTable from "screens/Aquaculture/components/Farm/components/FarmingProductRegistryTable";
import ProductFinder from "screens/Aquaculture/components/FarmingProduct/ProductFinder";

const FarmProductSettings = ({ farm }) => {
  const farmid = farm.farm_id;

  const [query, setQuery] = useState(null);
  const [registeredProducts, setRegisteredProducts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    Actions.onFetchRegisteredProducts();
  }, [farmid]);

  const Actions = {
    onFetchRegisteredProducts: async () => {
      const result = await PondManagerServices.fetchRegisteredProducts({ farmid: farmid });
      setRegisteredProducts(result.rows);
    },
    onRegisterProduct: async (product) => {
      const rsp = await PondManagerServices.registerFarmingProducts({ farmid, productid: product.id });
      if (rsp?.error) {
        alertsActions.addError(dispatch, { content: rsp.error });
      } else {
        Actions.onFetchRegisteredProducts();
        alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
      }
    },
    onDeregisterProduct: async (product) => {
      await PondManagerServices.deregisterFarmingProducts({ farmid, productid: product.id });
      Actions.onFetchRegisteredProducts();
      alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
    },
  };

  return (
    <Box
      p={2}
      sx={{
        boxSizing: "border-box",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          width={400}
          sx={{
            borderRight: "1px solid #EEE",
            pr: 1,
          }}
        >
          <Typography variant="cardtitle">
            <Text>interface.general.product-finder</Text>
          </Typography>
          <Box mt={2}>
            <ProductFinder registeredProducts={registeredProducts} actions={Actions} />
          </Box>
        </Grid>
        <Grid item xs>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="cardtitle">
              <Text>interface.general.registered-products</Text>
            </Typography>
            <MuiBorderIconButton icon={<RefreshIcon />} size={"sm"} onClick={Actions.onFetchRegisteredProducts} />
          </Stack>
          <Box mt={2}>
            <FarmingProductRegistryTable farm={farm} products={registeredProducts} actions={Actions} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FarmProductSettings;
