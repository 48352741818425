import { BuildCircleIcon, DashboardIcon, MultilineChartIcon, NewspaperIcon, SatelliteAltIcon, ViewTimelineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import MultiPondsCycleManager from "screens/Aquaculture/components/CycleManager/MultiPondsCycleManager";
import SideMenuLayout, { SideMenuLayoutThemes } from "screens/Aquaculture/components/Layout/SideMenuLayout";
import OrganisationWelcome from "screens/Aquaculture/components/Organisation/OrganisationWelcome";
import ProfitLossReport from "screens/Aquaculture/components/Report/ProfitLossReport";
import SRSReport from "screens/Aquaculture/components/Report/SRSReport";
import ScenarioBuilder from "screens/Aquaculture/components/ScenarioBuilder/ScenarioBuilder";
import GisLab from "screens/Aquaculture/components/Lab/GisLab";
import AccessWrapper from "components/Wrapper/AccessWrapper";
import PlatformAccessPolices from "constants/PlatformAccessPolicies";
import DashboardHome from "screens/Aquaculture/components/Dashboard/DashboardHome";
import ArcGisDashboard from "screens/Aquaculture/components/SatEye/ArcGisDashboard";
import PondForecastMk2 from "screens/Aquaculture/components/Forecast/PondForecastMk2";

/**
 *
 * Pond Management / Dashboard Home
 *
 */
const OrganisationHome = ({ pond, ponds, farm, actions }) => {
  // TODO: for future change
  const accessPolices = farm?.["org-access-test"];
  const accessAlways = accessPolices ? false : true;

  const MENULIST = [
    {
      label: <Text>interface.general.overview</Text>,
      description: "",
      value: 0,
      icon: <DashboardIcon />,
      component: <OrganisationWelcome />,
    },
    {
      label: <Text>interface.general.cycles</Text>,
      description: "",
      value: 1,
      icon: <ViewTimelineIcon />,
      component: <MultiPondsCycleManager ponds={ponds} farm={farm} disableEdit />,
    },
    {
      label: <Text>interface.general.dashboard</Text>,
      description: "",
      value: 2,
      icon: <DashboardIcon />,
      component: (
        <DashboardHome
          farm={farm}
          pond={pond}
          ponds={ponds}
          // pondGroupColorMapping={style.colorMapping}
          actions={{
            onSwitchToDataViewField: actions.onSwitchToDataViewField,
            toggleAlertPonds: actions.toggleAlertPonds,
          }}
        />
      ),
    },
    {
      label: <Text>interface.general.forecast</Text>,
      description: "",
      value: 3,
      icon: <MultilineChartIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.forecast.read}>
          {/* {pond ? <PondForecast pond={pond} /> : <FarmForecast farm={farm} />} */}
          {pond && <PondForecastMk2 pond={pond} farm={farm} />}
        </AccessWrapper>
      ),
    },
    {
      label: (
        <span>
          <Text>interface.general.srs-report</Text> (beta)
        </span>
      ),
      description: "",
      value: 4,
      icon: <NewspaperIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.srs.read}>
          <SRSReport />
        </AccessWrapper>
      ),
    },
    {
      label: getText("interface.general.profit-and-loss"),
      description: "",
      value: 5,
      icon: <NewspaperIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.finance.read}>
          <ProfitLossReport />
        </AccessWrapper>
      ),
    },
    {
      label: getText("interface.general.scenario-builder"),
      description: "",
      value: 6,
      icon: <BuildCircleIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.scenario_builder.read}>
          <ScenarioBuilder />
        </AccessWrapper>
      ),
    },
    {
      label: "GIS",
      description: "",
      value: 7,
      icon: <SatelliteAltIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.gis.read}>
          <GisLab farm={farm} pond={pond} />
        </AccessWrapper>
      ),
    },
    {
      label: "Biomass Dashboard",
      description: "",
      value: 8,
      icon: <SatelliteAltIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.gis.read}>
          <ArcGisDashboard key="biomass" farm={farm} />
        </AccessWrapper>
      ),
    },
    {
      label: "Desolved Oxygen Dashboard",
      description: "",
      value: 9,
      icon: <SatelliteAltIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.gis.read}>
          <ArcGisDashboard key="do" farm={farm} defaultView="do" />
        </AccessWrapper>
      ),
    },
    {
      label: "Water Temperature Dashboard",
      description: "",
      value: 10,
      icon: <SatelliteAltIcon />,
      component: (
        <AccessWrapper allowed={accessPolices} target={PlatformAccessPolices.gis.read}>
          <ArcGisDashboard key="temp" farm={farm} defaultView="temp" />
        </AccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout theme={SideMenuLayoutThemes.tabs} menulist={MENULIST} title={<Text>interface.general.organisations</Text>} defaultViewMode={0} />;
};

export default OrganisationHome;
