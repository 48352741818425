import { Box, Divider, Grid, IconButton, LinearProgress, Stack, Typography, colors } from "@mui/material";
import AppLocalStorage, { KEYMAPPING } from "helpers/LocalStorage";
import _ from "lodash";
import { useEffect, useState } from "react";

import { PondManagerServices } from "api/pondManagerServices";
import Text, { getText } from "components/text/Text";
import { getColorsBasedOnListOfText } from "helpers/ColorHelpers";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { modalIds, modalReducersActions } from "redux/modal";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import { pathologyManagementActions } from "redux/pondManagement/pathologyManagement";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import { ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import ClimateWidget from "screens/Aquaculture/components/Climate/ClimateWidget";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import NumericFormatters from "helpers/NumericFormatters";
import { ArrowBackIcon, CircleIcon } from "components/Icons/MaterialIcons";
import { ArrowBackIos } from "@mui/icons-material";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";
import FarmAccessControlButton from "screens/Aquaculture/components/common/FarmAccessControlButton";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import MobilePondManagerPondView from "screens/Mobile/PondManager/MobilePondManagerPondView";

const appStorage = new AppLocalStorage();

/**
 *
 * Ponds Management System Main
 *
 * @returns
 */

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.SATELLITE,
    // minZoom: 11,
    // maxZoom: 18,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const MobilePondManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ~~~ Redux ~~~
  const pondManagementStore = AppSelectors.pondManagementStore();
  const farms = pondManagementStore.farms;
  const fields = pondManagementStore.fields;
  const selectedGroup = pondManagementStore.selectedGroup;
  const pondid = pondManagementStore.pondid;

  const cycleStore = AppSelectors.cycleStore();
  const latestCycles = cycleStore.latestCycles;

  // fetch farmid and other parameters from the URL
  const { farmid } = useParams();

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [ponds, setPonds] = useState([]);
  const [pondsGeoJson, setPondsGeoJson] = useState(null);
  const [loading, setLoading] = useState(false);

  // tigger for redux
  useEffect(() => {
    const run = async () => {
      if (farmid) {
        setLoading(true);

        // load all farms
        await pondManagementActions.loadAllFarms(dispatch);

        // update selectedFarm in redux
        await pondManagementActions.selectFarm(dispatch, { farmId: farmid });

        // load all cycle
        await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farmid);

        // fetch all ponds
        await Actions.pond.fetchAllPonds(farmid);

        // init unit prices data
        await pondManagementActions.loadShrmipUnitPrices(dispatch, farmid);

        // init Pathology data
        await pathologyManagementActions.initData(dispatch);

        // fetch registered products
        await pondManagementActions.fetchRegistgeredProducts(dispatch, farmid);

        setLoading(false);
      }
    };

    run();
  }, [dispatch, farmid]);

  useEffect(() => {
    // validate farm ownership
    if (farms.length === 0) return;
    if (farmid) {
      const farm = _.find(farms, { farm_id: farmid });
      if (farm && farms) {
        setSelectedFarm(farm);
        return;
      } else {
        navigate(ROUTE_POND_MANAGER_HOME_PAGE);
      }
    } else {
      navigate(ROUTE_POND_MANAGER_HOME_PAGE);
    }
  }, [farms]);

  /** Call when new farm is selected */
  // useEffect(() => {
  //   const run = async () => {
  //     await getPonds(selectedFarm);
  //     await cycleManagementActions.loadAllCyclesByFarmId(dispatch, selectedFarm.farm_id);
  //   };
  //   selectedFarm && run();
  // }, [selectedFarm]);

  const getPonds = async (farm) => {
    const farmId = farm ? farm?.farm_id : selectedFarm.farm_id;
    if (!farmId) return;
    setLoading(true);
    // if farm is selected
    // TODO: update below to redux
    const pondsResult = await PondManagerServices.listPonds(farmId);
    const geojsonResult = await PondManagerServices.getPondsGeoJson(farmId);
    setPonds(
      pondsResult.sort((a, b) =>
        a?.label.localeCompare(b?.label, undefined, {
          numeric: true,
        })
      )
    );
    setPondsGeoJson(geojsonResult);
    // if saved group is not exist, update it to ALL
    const isSelectedGroupValid = _.includes(
      pondsResult.map((p) => p.group),
      selectedGroup
    );
    if (!isSelectedGroupValid) {
      pondManagementActions.selectGroup(dispatch, { group: null });
    }
    // automatically open pond creation page when there's no ponds created
    if (pondsResult && pondsResult.length < 1 && farm.access_level_write) {
      Actions.pond.onCreatePond();
    }
    setLoading(false);
  };

  const filteredPonds = ponds.filter((e) => (selectedGroup ? e.group === selectedGroup : true));

  const groupSelections = _.unionBy(ponds, "group")
    .map((e) => e.group)
    .filter((e) => e)
    .sort();

  const Actions = {
    pond: {
      fetchAllPonds: async (farmid) => {
        // if farm is selected
        // TODO: update below to redux
        const pondsResult = await PondManagerServices.listPonds(farmid);
        const geojsonResult = await PondManagerServices.getPondsGeoJson(farmid);
        setPonds(
          pondsResult.sort((a, b) =>
            a?.label.localeCompare(b?.label, undefined, {
              numeric: true,
            })
          )
        );
        setPondsGeoJson(geojsonResult);
        // if saved group is not exist, update it to ALL
        const isSelectedGroupValid = _.includes(
          pondsResult.map((p) => p.group),
          selectedGroup
        );
        if (!isSelectedGroupValid) {
          pondManagementActions.selectGroup(dispatch, { group: null });
        }
      },
      onCreatePond: async () => {
        setPondData({
          label: "New Pond",
          category: "Shrimp",
          farm_id: selectedFarm?.farm_id,
          lat: selectedFarm?.lat,
          lon: selectedFarm?.lon,
        });
      },
      onEditPond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        setPondData(data);
      },
      onClonePond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        const { id, ...dataWithoutId } = data;
        setPondData(dataWithoutId);
      },
      onDeletePond: async (pondId) => {
        await PondManagerServices.deletePond(pondId);
        await getPonds(selectedFarm);
        alertsActions.addInfo(dispatch, {
          content: "Pond has been removed",
        });
      },
      onSelectPond: async (pondid) => {
        pondManagementActions.selectPond(dispatch, { pondid: pondid });
        // navigate(`/aqua/pond-manager/${farmid}/${pondid}`);
      },
    },
    group: {
      onSelectGroup: async (group) => {
        pondManagementActions.selectGroup(dispatch, { group });
      },
    },
  };

  // Some Stats
  const pondCount = filteredPonds.length || 0;

  if (pondid) {
    return <MobilePondManagerPondView ponds={ponds} />;
  }

  return (
    <Box>
      <Box sx={{ height: "3px" }}>{loading && <LinearProgress sx={{ height: "100%" }} />}</Box>
      <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"} p={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {selectedFarm && (
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Box>
                <IconButton size="small" onClick={() => navigate(ROUTE_POND_MANAGER_HOME_PAGE)}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box>
                <CountryFlagSvgIcon countryCode={selectedFarm.country_code.toUpperCase()} />
              </Box>
              <Box>
                <Typography fontSize={13} fontWeight={800}>
                  {selectedFarm?.label}
                </Typography>
                <Box mt={-1}>
                  <Typography
                    variant="caption"
                    color="grey"
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "200px",
                      display: "inline-block",
                    }}
                  >
                    {[selectedFarm.region, selectedFarm.place].filter((e) => e).join(" • ")}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          )}
        </Stack>
        {/* TopBar > Summary */}
        <Stack direction="row" spacing={1}>
          <ClimateWidget farm={selectedFarm} />
          {/* <HeaderPondSummary /> */}
          {/* <FarmAccessControlButton farm={selectedFarm} /> */}
          {/* <OrgConnectButton farm={selectedFarm} /> */}
        </Stack>
      </Stack>

      <Grid container spacing={1} p={1}>
        <Grid item xs={4}>
          <MobileWidgetCard>
            <Typography textAlign={"center"} fontWeight={800} color="grey" fontSize={10}>
              <Text>interface.general.total-ponds</Text>
            </Typography>
            <Typography textAlign={"center"} fontSize={20} lineHeight={1} fontWeight={800}>
              {NumericFormatters.format({ value: selectedFarm?.total_ponds })}
            </Typography>
          </MobileWidgetCard>
        </Grid>
        <Grid item xs={4}>
          <MobileWidgetCard>
            <Typography textAlign={"center"} fontWeight={800} color="grey" fontSize={10}>
              <Text>interface.general.total-area</Text> <small>ha.</small>
            </Typography>
            <Typography textAlign={"center"} fontSize={20} lineHeight={1} fontWeight={800}>
              {NumericFormatters.format({ value: selectedFarm?.total_area })}
            </Typography>
          </MobileWidgetCard>
        </Grid>
        <Grid item xs={4}>
          <MobileWidgetCard>
            <Typography textAlign={"center"} fontWeight={800} color="grey" fontSize={10}>
              <Text>interface.general.currency</Text>
            </Typography>
            <Typography textAlign={"center"} fontSize={20} lineHeight={1} fontWeight={800}>
              {selectedFarm?.settings?.currency}
            </Typography>
          </MobileWidgetCard>
        </Grid>
        <Grid item xs={12}>
          <MobileWidgetCard title={<Text>interface.general.ponds</Text>}>
            {/* Group Select */}
            <Stack
              direction={"row"}
              sx={{
                overflowX: "auto",
              }}
            >
              <MuiTabs
                options={[{ label: <Text>interface.general.all</Text>, value: null }, ...groupSelections.map((g) => ({ label: g, value: g }))]}
                value={selectedGroup}
                onNewValue={Actions.group.onSelectGroup}
                theme={MuiTabsThemes.chip}
              />
            </Stack>
            <Stack my={1}>
              <Typography fontSize={10} color="grey">
                Pds No.: {pondCount}
              </Typography>
            </Stack>
            <Stack
              maxHeight={300}
              overflow={"auto"}
              sx={{
                p: 1,
                px: 2,
                bgcolor: colors.grey[100],
                borderRadius: 2,
              }}
              divider={<Divider />}
            >
              {filteredPonds.map((pd, idx) => {
                return (
                  <Stack
                    sx={{
                      ":active": {
                        bgcolor: "#FFF",
                      },
                    }}
                    onClick={() => latestCycles[pd.id] && Actions.pond.onSelectPond(pd.id)}
                  >
                    <Stack py={1} direction={"row"} justifyContent={"space-between"} key={idx}>
                      <Box>
                        <Typography fontWeight={"bold"} fontSize={pd.label.length > 5 ? 20 : 26} lineHeight={1}>
                          {pd.label}
                        </Typography>
                        {latestCycles[pd.id] ? (
                          <Stack direction={"row"} alignItems={"center"} px={1} bgcolor={colors.grey[300]} spacing={1} borderRadius={1} mt={0.5}>
                            <CircleIcon sx={{ fontSize: 12, color: latestCycles[pd.id].active ? "green" : "grey" }} />
                            <Typography fontSize={10}>{latestCycles[pd.id].label}:</Typography>
                            <Typography fontSize={10}>
                              {latestCycles[pd.id].start_date} ~ {latestCycles[pd.id].end_date}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack direction={"row"} alignItems={"center"} px={1} bgcolor={colors.grey[300]} spacing={1} borderRadius={1} mt={0.5}>
                            <Typography fontSize={10}>No Cycle</Typography>
                          </Stack>
                        )}
                      </Box>
                      <Box>
                        <Typography textAlign={"right"} fontSize={10}>
                          G: {pd.group}
                        </Typography>
                        <Typography textAlign={"right"} fontSize={10}>
                          {NumericFormatters.formatAreaInHa({ value: pd.area, dynamic: false, decimalScale: 1 })}
                        </Typography>
                        <Typography textAlign={"right"} fontSize={10}>
                          {pd.category}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </MobileWidgetCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobilePondManager;
