import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import ImageUpdateForm from "screens/Aquaculture/components/Files/components/ImageUpdateForm";

const ImageModel = ({ image, onSubmit, onDelete }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const tagList = image?.tags?.split(",");

  const Actions = {
    onChangeToEdit: () => {
      setEditMode(true);
    },
    onConfirmDeleteConfirm: () => {
      dialogReducerActions.openCommonDialog(dispatch, {
        id: CommonDialogIds.IMAGE_MODEL_DELETE_CONFIRM,
      });
    },
    postImageUpdateCanceled: () => {
      setEditMode(false);
    },
    postImageUpdateSubmitted: () => {
      setEditMode(false);
    },
  };

  return (
    <Box>
      <CommonDialog id={CommonDialogIds.IMAGE_MODEL_DELETE_CONFIRM} title={getText("interface.alert.alert-are-you-sure")} action={onDelete} />
      <Box
        sx={{
          height: "90%",
          width: "100%",
          img: {
            display: "inline-block",
            verticalAlign: "middle",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
          },
        }}
      >
        <Grid container p={4}>
          <Grid item xs>
            <Stack alignItems={"center"}>
              <Box width={"100%"}>
                <img src={image.url} />
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            width={{
              lg: 500,
              md: 300,
              sm: 100,
            }}
            pl={4}
          >
            {editMode ? (
              <ImageUpdateForm image={image} onSubmit={onSubmit} postSubmit={Actions.postImageUpdateSubmitted} postCancel={Actions.postImageUpdateCanceled} />
            ) : (
              <Stack spacing={3}>
                <Box>
                  <Typography variant="sectionTitle">{image?.label}</Typography>
                  <Typography fontSize={12}>{image?.file_name}</Typography>
                  <Typography fontSize={12}>{image?.datetime}</Typography>
                </Box>
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    {tagList.map((tag) => {
                      return <Chip label={`#${tag}`} color="secondary" size="small" />;
                    })}
                  </Stack>
                </Box>
                <Box>
                  <Typography fontSize={12}>NOTE:</Typography>
                  <Typography
                    fontSize={12}
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {image?.note}
                  </Typography>
                </Box>
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
                      <Text>interface.actions.edit</Text>
                    </Button>
                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={Actions.onConfirmDeleteConfirm}>
                      <Text>interface.actions.delete</Text>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImageModel;
