import { Box, Chip, IconButton, List, ListItemIcon, ListItemText, MenuItem, Stack, Tab, Tabs, Tooltip, Typography, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isMobile } from "themes/helpers";
import palette from "themes/palette";

export const MuiTabsThemes = {
  compact: "compact",
  chip: "chip",
  icon: "icon",
};

/**
 *
 * ## Props
 *

 *
 * ## exmaple:
 * ```
 * <MuiTabs
 *    options={[{label: "Tab 1", value: 1}]}
 *    value={1}
 *    onNewValue={(value) => { setValue(value)}}
 *    vertical
 *    withBorder
 *    theme={MuiTabsThemes.compact}
 * />
 * ```
 *
 * @param {object} props
 * @param {Array} props.options
 * @param {string|number} props.value
 * @param {function} props.onNewValue
 * @param {boolean} props.vertical
 * @param {boolean} props.withBorder
 * @param {string} props.them
 * @returns
 */
const MuiTabs = (props) => {
  const { options = [], value, onNewValue, vertical, withBorder, theme, ...otherProps } = props;

  const navigate = useNavigate();

  const mobileMode = isMobile();

  if (theme === MuiTabsThemes.icon) {
    return (
      <Stack direction={vertical ? "column" : "row"} alignItems={"center"} spacing={1} px={2}>
        {options.map((item, idx) => {
          return (
            <Tooltip key={item.value} placement={vertical ? "right" : "top"} title={item?.description || item?.label}>
              <IconButton
                sx={{
                  borderRadius: 2,
                  p: 0.8,
                  bgcolor: item.value === value ? palette.secondary.main : "transparent",
                  color: item.value === value ? palette.secondary.light : palette.primary.main,
                  "&:focus": {
                    bgcolor: item.value === value ? palette.secondary.main : "transparent",
                    color: item.value === value ? palette.secondary.light : palette.primary.main,
                  },
                  "&:hover": mobileMode
                    ? null
                    : {
                        bgcolor: palette.secondary.light,
                        color: palette.secondary.main,
                      },
                }}
                onClick={() => {
                  onNewValue(item.value);
                  item.path && navigate(item.path);
                }}
              >
                {item.icon}
              </IconButton>
            </Tooltip>
          );
        })}
      </Stack>
    );
  }

  if (theme === MuiTabsThemes.chip) {
    return (
      <Stack direction={vertical ? "column" : "row"} alignItems={"center"} spacing={1} py={1}>
        {options.map((item, idx) => {
          const selected = item.value === value;
          return (
            <Tooltip key={item.value} placement={vertical ? "right" : "top"} title={item?.description || item?.label}>
              <Chip
                sx={{
                  p: 0,
                  height: "unset",
                  px: 1.2,
                  py: 0.6,
                  borderRadius: 0.8,
                  bgcolor: selected ? palette.primary.main : palette.primary.light,
                  color: selected ? "#FFF" : palette.primary.main,
                  transition: "none",
                  "&:hover": {
                    filter: "brightness(1.02)",
                    bgcolor: selected ? palette.primary.main : palette.primary.light,
                    color: selected ? "#FFF" : palette.primary.main,
                    boxShadow: "none",
                  },
                  ".MuiChip-label": {
                    fontWeight: 800,
                    p: 0,
                    textTransform: "capitalize",
                  },
                }}
                onClick={() => {
                  onNewValue(item.value);
                  item.path && navigate(item.path);
                }}
                label={
                  <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                    {item.icon && (
                      <Stack direction={"row"} alignItems={"center"} sx={{ svg: { fontSize: 14 } }}>
                        {item?.icon}
                      </Stack>
                    )}
                    <Box>{item.label}</Box>
                  </Stack>
                }
              />
            </Tooltip>
          );
        })}
      </Stack>
    );
  }

  return (
    <Tabs
      value={value}
      onChange={(e, newValue) => {
        onNewValue(newValue);
      }}
      orientation={vertical && "vertical"}
      {...otherProps}
      sx={{
        borderBottom: withBorder ? "1px solid #ccc" : "none",
      }}
    >
      {options.map((item, idx) => (
        <Tab
          key={idx}
          value={item.value}
          onClick={() => {
            item.path && navigate(item.path);
          }}
          sx={{
            height: vertical ? "60px" : "0px",
          }}
          label={
            <Box>
              <Tooltip
                placement={vertical ? "right" : "bottom"}
                title={
                  item.subMenu ? (
                    <Box
                      className="sub-menu"
                      minWidth={"200px"}
                      onClick={(e) => {
                        return null;
                      }}
                    >
                      <List>
                        {item.subMenu.map((m) => (
                          <MenuItem
                            sx={{
                              borderRadius: 1,
                              svg: {
                                color: "#FFF",
                              },
                              "&:hover": {
                                bgcolor: theme.palette.primary.light,
                                color: theme.palette.primary.main,
                                svg: {
                                  color: theme.palette.primary.main,
                                },
                              },
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <ListItemIcon>{m.icon}</ListItemIcon>
                            <ListItemText>{m.label}</ListItemText>
                          </MenuItem>
                        ))}
                      </List>
                    </Box>
                  ) : (
                    item.label
                  )
                }
              >
                <Stack
                  direction={"column"}
                  spacing={0}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      svg: {
                        fontSize: 20,
                      },
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Typography fontSize={vertical ? 10 : 11} fontWeight={800} lineHeight={1.2} textTransform={"capitalize"}>
                    {item.label}
                  </Typography>
                </Stack>
              </Tooltip>
            </Box>
          }
        />
      ))}
    </Tabs>
  );
};

export default MuiTabs;
