import { colors } from "@mui/material";

import { typography } from "./typography";
import { IntelligonLogoImge, ShrimplIconOnlyGreySvg, ShrimplIconOnlySvg, ShrimplLogoSvg } from "components/Images";
import palette from "themes/palette";
import UserRoles from "constants/UserRoles";
import breakpoints from "themes/breakpoints";
import { Roles } from "configs/appConfigs";

export const ThemeLogos = {
  [Roles.INTERNAL]: IntelligonLogoImge,
  [Roles.SHRIMPSTAR_USER]: ShrimplLogoSvg,
  [Roles.SHRIMPL_USER]: ShrimplLogoSvg,
};

const SHRIMPL_THEMES = {
  colors: {
    main: "#0a2f3d",
    main2: "#ea6e5b",
  },
  logo: {
    main: ShrimplLogoSvg,
    alt1: ShrimplIconOnlySvg,
    light: ShrimplIconOnlyGreySvg,
  },
};

export const THEME = {
  palette: palette,
  typography,
  breakpoints: breakpoints,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { transition: "none !important" },
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        disableUnderline: true,
        inputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey[200],
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 30,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    /**
     * TABS
     *
     */
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          children: <span className="MuiTabs-indicatorSpan" />,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            height: "3px",
          },
          "& .MuiTabs-indicatorSpan": {
            width: "100%",
            backgroundColor: palette.secondary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: "0",
          padding: "6px 10px",
          color: colors.grey[600],
          transition: "all .1s",
          "&:hover": {
            color: palette.primary.main,
          },
          "&.Mui-selected": {
            fontWeight: 800,
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: "3px",
          border: "1px solid #ccc",
          backgroundColor: "#FFF",
          gridGap: "5px",
          borderRadius: "20px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px!important",
          transition: "all .2s",
          border: "none",
          "&:hover": {
            backgroundColor: colors.grey[700] + "!important",
            color: "#FFF",
          },
          "&.Mui-selected": {
            backgroundColor: palette.primary.main,
            color: "#FFF",
          },
          ":active": {
            transform: "scale(.98)",
          },
        },
      },
    },
    /**
     * TOOLTIP
     *
     */
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: palette.primary.light,
        },
        tooltip: {
          padding: "4px 8px",
          backgroundColor: palette.primary.light,
          color: palette.primary.main,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          fontSize: 12,
        },
      },
    },
    /**
     * Switch
     * https://mui.com/material-ui/react-switch/
     * https://mui.com/material-ui/api/switch/
     */
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: "5px",
          ".MuiSwitch-track": {
            borderRadius: "20px",
          },
        },
        sizeSmall: {
          padding: "1px",
        },
      },
    },
    /**
     * Slider
     * https://mui.com/material-ui/react-slider/
     * https://mui.com/material-ui/api/slider/
     */
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-thumb": {
            boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
            "&:focus, &:hover, &.Mui-active": {
              boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
              },
            },
            "&:before": {
              boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  shadows: [
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
  ],
  logo: SHRIMPL_THEMES.logo,
};
