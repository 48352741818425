import { PondManagerServices } from "api/pondManagerServices";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import OrgRow from "screens/Aquaculture/components/OrgConnect/components/OrgRow";
import { LinkOffIcon } from "components/Icons/MaterialIcons";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { dialogReducerActions } from "redux/dialog";
import { useDispatch } from "react-redux";

const ActiveOrgsList = ({ farm, list = [], actions }) => {
  const [orgToDisconnect, setOrgToDisconnect] = useState(null);

  const dispatch = useDispatch();

  const Actions = {
    onDisconnectConfirm: ({ orgid }) => {
      dialogReducerActions.openCommonDialog(dispatch, {
        id: CommonDialogIds.ORG_CONNECT_CONFIRM_DELETE,
      });
      setOrgToDisconnect(orgid);
    },
    onDisconnect: async () => {
      await PondManagerServices.removeConnectionToOrg({ orgid: orgToDisconnect, farmid: farm.farm_id });
      alertsActions.addInfo(dispatch, {
        content: "Organisation has been disconnect",
      });
      actions.onLoadConnectedOrgs();
    },
  };

  return (
    <Box>
      <CommonDialog id={CommonDialogIds.ORG_CONNECT_CONFIRM_DELETE} title={"Are you sure?"} content={"The organsation won't be access your farm after disconnecting"} action={Actions.onDisconnect} />
      <Box mb={3}>
        <Typography variant="cardtitle">Active</Typography>
      </Box>
      <Box>
        {list.length === 0 && <VerticalAlertBoxCollections.NothingShow />}
        {list.map((ele) => {
          return (
            <OrgRow
              data={ele}
              action={{
                action: () => {
                  Actions.onDisconnectConfirm({ orgid: ele.org_id });
                },
                icon: <LinkOffIcon />,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveOrgsList;
