import { Box, Stack, Typography } from "@mui/material";
import { AspectRatioIcon, AttachMoneyIcon, LocationOnIcon, TagIcon } from "components/Icons/MaterialIcons";
import OutlinedTag from "components/Tags/OutlinedTag";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import NumberFormat from "react-number-format";

const FarmDashboardHero = ({ farm }) => {
  const imageList = farm?.images || [];
  const imageFirst = _.first(imageList);
  const displayImage = !!imageFirst;

  return (
    <Box>
      <Stack direction={"row"} spacing={1}>
        {displayImage && (
          <Box
            width={100}
            height={50}
            borderRadius={2}
            sx={{
              backgroundImage: `url(${imageFirst?.compressed_url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        )}
        <Box>
          <Typography color="primary" sx={{ lineHeight: 0.5 }} fontSize={10} fontWeight={800}>
            <Text>interface.general.farm</Text>
          </Typography>
          <Typography color="primary" fontSize={18} fontWeight={800}>
            {farm?.label}
          </Typography>
          <Stack direction={"row"} spacing={0.5}>
            <OutlinedTag icon={<LocationOnIcon />} tooltip={getText("interface.general.location")} text={`${farm?.region}, ${farm?.country}`} />
            <OutlinedTag icon={<AspectRatioIcon />} tooltip={getText("interface.general.farm-area")} text={NumericFormatters.formatAreaInHa({ value: farm?.total_area })} />
            <OutlinedTag icon={<TagIcon />} tooltip={getText("interface.general.ponds")} text={<NumberFormat displayType="text" value={farm?.total_ponds} decimalScale={0} suffix=" Pds." />} />
            <OutlinedTag icon={<AttachMoneyIcon />} tooltip={getText("interface.general.currency")} text={farm?.settings?.currency} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default FarmDashboardHero;
