import { Container } from "@mui/material";
import React from "react";
import FarmImages from "screens/Aquaculture/components/Files/FarmImages";

const FarmImagesSettings = ({ farmId }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        mb: 10,
      }}
    >
      <FarmImages farmId={farmId} />
    </Container>
  );
};

export default FarmImagesSettings;
