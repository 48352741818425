import { AgGridReact } from "ag-grid-react";
import { Box } from "@mui/material";
import { AppSelectors } from "redux/AppReducers";
import _ from "lodash";
import { getText } from "components/text/Text";

// TODO: move this to Utils
const transformeRows = (rows) => {
  const uniqueFieldIds = _.uniqBy(rows, "field_id").map((item) => item.field_id);
  const columns = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      field: "datetime",
      headerName: getText("interface.general.datetime"),
    },
    ...uniqueFieldIds.map((ele) => ({
      field: ele,
      headerName: ele,
    })),
  ];
  let groupedRows = _.groupBy(rows, "datetime");
  groupedRows = _.keys(groupedRows).map((k) => {
    let newRow = {
      datetime: k,
    };
    groupedRows[k].forEach((item) => {
      newRow[item["field_id"]] = item["value"];
    });
    return newRow;
  });
  return {
    rows: groupedRows,
    columns: columns,
  };
};

const DataLogs = ({ rows, columns, onCellEdit }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const fieldsMap = pondManagementStore.fieldsMap;

  // transform data rows
  const reformatted = transformeRows(rows);

  const columnDefs = (columns || reformatted.columns).map((item) => ({
    ...item,
    flex: 1,
  }));

  return (
    <Box overflow={"hidden"} width="100%" height="600px" className="ag-theme-excel">
      <AgGridReact
        rowData={reformatted.rows}
        columnDefs={columnDefs}
        rowHeight={25}
        headerHeight={30}
        rowSelection="multiple"
        onSelectionChanged={(e) => {
          console.log(e.api.getSelectedRows());
        }}
        onCellEditingStopped={(e) => {
          if (onCellEdit && e.valueChanged) {
            const changes = [
              {
                datetime: e.data.datetime,
                field_id: e.colDef.field,
                value: e.value,
              },
            ];
            onCellEdit(changes);
          }
        }}
      ></AgGridReact>
    </Box>
  );
};

export default DataLogs;
