import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import TextInput from "./TextInput";
import { MessageRight, MessageLeft, MessageBox } from "./Message";
import moment from "moment";
import Filter from "./Filter";
import { NoMessageFound } from "./NoMessageFound";
import ActionBar from "./ActionBar";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorageItem,
  saveLocalStorageItem,
} from "helpers/LocalStorage";
import { AddIcon } from "components/Icons/MaterialIcons";
import { useDispatch, useSelector } from "react-redux";
import { isMenuCollapsedFunc } from "redux/chatSlice";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
`;

const MessagesBody = styled.div`
  width: calc(100% - 20px);
  margin: 10px;
  overflow-y: auto;
  flex-grow: 1;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const FLOATING_MESSAGE_CHAT_KEY = "FLOATING_MESSAGE_CHAT_KEY";

const ChatBoard = ({
  isPrivateChat = false,
  messages = [],
  selectedChat,
  isFloatingMessage,
  chatId,
  onLoadMessages,
}) => {
  const Actions = {
    onLoadMessages: () => {
      if (onLoadMessages) {
        onLoadMessages();
      }
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const floatingMessageOnStorage = getLocalStorageItem(
    FLOATING_MESSAGE_CHAT_KEY
  );

  const { isMenuCollapsed } = useSelector((state) => state.chat);

  const handleFloatingMessage = async () => {
    if (floatingMessageOnStorage) {
      await saveLocalStorageItem(FLOATING_MESSAGE_CHAT_KEY);
      navigate("/chat");
    } else {
      console.log("selectedChat?.chatId", selectedChat?.chatId);
      await saveLocalStorageItem(
        FLOATING_MESSAGE_CHAT_KEY,
        selectedChat?.chatId
      );
      navigate("/");
    }
  };

  const handleMenuToggle = () =>
    dispatch(isMenuCollapsedFunc(!isMenuCollapsed));

  const getCurrentTimeToDummy = "12/05/2023";

  // return (
  //   <Container>
  //     {!isPrivateChat && <Filter />}
  //     <StyledPaper
  //       elevation={2}
  //       style={
  //         isPrivateChat
  //           ? isFloatingMessage
  //             ? { height: "50vh" }
  //             : { height: "calc(100vh - 20px)" }
  //           : { height: "80vh", maxHeight: "650px" }
  //       }
  //     >
  //       {selectedChat?.length !== 0 && <ActionBar onClickFloating={handleFloatingMessage} onClickMenu={handleMenuToggle} /> }
  //       <MessagesBody>
  //         {selectedChat?.length === 0 && <NoMessageFound />}
  //         {/* <MessageLeft
  //           message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
  //           timestamp={getCurrentTimeToDummy}
  //           photoURL="https://example.com/avatar1.jpg"
  //           displayName="Claude"
  //           avatarDisp={true}
  //         />
  //         <MessageLeft
  //           message="It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
  //           timestamp={getCurrentTimeToDummy}
  //           photoURL=""
  //           displayName="Ciaron"
  //           avatarDisp={false}
  //         />
  //         <MessageRight
  //           message="It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  //           timestamp={getCurrentTimeToDummy}
  //           photoURL="https://example.com/avatar2.jpg"
  //           displayName="Sankeeth"
  //           avatarDisp={true}
  //         /> */}
  //         {/* <MessageRight
  //           message="More recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  //           timestamp={getCurrentTimeToDummy}
  //           photoURL="https://example.com/avatar2.jpg"
  //           displayName="Dianial"
  //           avatarDisp={false}
  //         />
  //         <MessageLeft
  //           message="It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
  //           timestamp={getCurrentTimeToDummy}
  //           photoURL=""
  //           displayName="Rizlan"
  //           avatarDisp={false}
  //         /> */}

  return (
    <Box
      sx={{
        height: "100%",
        p: 2,
        boxSizing: "border-box",
      }}
    >
      {/* {!isPrivateChat && <Filter />} */}
      <ActionBar
        onClick={handleFloatingMessage}
        onClickMenu={handleMenuToggle}
      />
      <Box>
        <Box mb={2}>
          <Button
            size="small"
            startIcon={<AddIcon />}
            fullWidth
            onClick={Actions.onLoadMessages}
          >
            Load More
          </Button>
        </Box>
        <Box
          sx={{
            maxHeight: "calc(100vh - 480px)",
            overflow: "auto",
          }}
          mb={1}
          px={2}
        >
          {messages.map((item) => {
            return <MessageBox key={item.id} {...item} />;
            // if (item.isMine) {
            //   return (
            //     <MessageRight
            //       key={item.id}
            //       timestamp={item.timestamp}
            //       message={item.body}
            //     />
            //   );
            // } else {
            //   return (
            //     <MessageLeft
            //       key={item.id}
            //       timestamp={item.timestamp}
            //       displayName={item.displayName}
            //       message={item.body}
            //     />
            //   );
            // }
          })}
        </Box>
      </Box>
      <TextInput isFloatingMessage={isFloatingMessage} />
    </Box>
    //     </MessagesBody>
    //     {selectedChat?.length !== 0 && <TextInput isFloatingMessage={isFloatingMessage} />}
    //   </StyledPaper>
    // </Container>
  );
};

export default ChatBoard;
