import { MyLocation } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { getText } from "components/text/Text";
import PondCategories, { PrettifyPondCategory } from "constants/PondCategories";
import PondSubCategories, { PrettifyPondSubCategory } from "constants/PondSubCategories";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import { useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { googleMapManagementActions } from "redux/pondManagement/googleMapManagement";

const PondList = ({ ponds }) => {
  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const Actions = {
    onSavePond: async (newData) => {
      const formData = {
        id: newData.id,
        boundary_geojson: JSON.stringify(newData.boundary_geojson),
        category: newData.category,
        farm_id: newData.farm_id,
        group: newData.group,
        label: newData.label,
        last_update: newData.last_update,
        lat: newData.lat,
        lon: newData.lon,
        area: newData.area,
        sub_category: newData.sub_category,
      };
      await PondManagerServices.savePond(formData);
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
    },
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      pinned: "left",
      width: 100,
      cellRenderer: (params) => (
        <Stack height={"100%"} direction="row" alignItems={"center"} justifyContent={"center"} spacing={0.5}>
          <MuiBorderIconButton size="sm" icon={<MyLocation />} onClick={() => googleMapManagementActions.highlightPond(dispatch, params.data)} />
        </Stack>
      ),
      cellStyle: { justifyContent: "center", alignItems: "center" },
    },
    {
      headerName: getText("interface.general.label"),
      field: "label",
      pinned: "left",
      editable: true,
    },
    {
      headerName: getText("interface.general.id", "ID"),
      field: "id",
      pinned: "left",
      cellRenderer: ({ value }) => {
        return (
          <code>
            <small>{value}</small>
          </code>
        );
      },
    },
    {
      headerName: getText("interface.general.group"),
      field: "group",
      editable: true,
    },
    {
      headerName: getText("interface.general.category"),
      field: "category",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellRenderer: ({ value }) => {
        return PrettifyPondCategory(value);
      },
      cellEditorParams: {
        cellRenderer: (params) => {
          const option = _.find(PondCategories, { value: params.value }) || {
            label: null,
            value: null,
          };
          if (!option.value) {
            return <span>-</span>;
          }
          return <span>{PrettifyPondCategory(option.value)}</span>;
        },
        values: [null, ...PondCategories.map((ele) => ele.value)],
      },
    },
    {
      headerName: getText("interface.general.sub-category"),
      field: "sub_category",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellRenderer: ({ value }) => {
        return PrettifyPondSubCategory(value);
      },
      cellEditorParams: {
        cellRenderer: (params) => {
          const option = _.find(PondSubCategories, { value: params.value }) || {
            label: null,
            value: null,
          };
          if (!option.value) {
            return <span>-</span>;
          }
          return <span>{PrettifyPondSubCategory(option.value)}</span>;
        },
        values: [null, ...PondSubCategories.map((ele) => ele.value)],
      },
    },
    {
      headerName: getText("interface.general.time-created"),
      field: "created_at",
      sortable: true,
      cellRenderer: ({ value }) => {
        return TimeFormatters.formatUtcDatetime(value);
      },
    },
    {
      headerName: getText("interface.general.pond-area"),
      field: "area",
      sortable: true,
      cellRenderer: (params) => {
        return NumericFormatters.formatAreaInM2({ value: params.value * 10000, dynamic: true });
      },
      editable: true,
    },
    {
      headerName: getText("interface.general.farm"),
      field: "farm_label",
      width: 400,
    },
  ]);

  const tableConfigs = {
    rowHeight: 30,
    headerHeight: 30,
    rowData: ponds,
    columnDefs: columnDefs,
    suppressScrollOnNewData: true,
    rowSelection: "multiple",
    enableRangeSelection: true,
    onCellValueChanged: (params) => {
      Actions.onSavePond(params.data);
    },
  };

  return (
    <Box>
      <Box p={2} width="100%" height="calc(100vh - 200px)" className="ag-theme-excel">
        <AgGridReact ref={tableRef} {...tableConfigs}></AgGridReact>
      </Box>
    </Box>
  );
};

export default PondList;
