import { api_water_broker } from "../../../helpers/WaterAPI";
import { COLORMAP } from "../../../config";
import { CSVLink } from "react-csv";
import { generateSeasonal, calculateChange } from "helpers/ChartFunctions";
import { LoadingBar } from "../../../helpers/SimpleComponents";
import MultiViewChart from "../../../components/chart/MultiViewChart";
import PriceChangeCard from "../../../components/card/PriceChangeCard";
import React, { Component } from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import LoadingBox from "components/Loading/LoadingBox";

const ChartLayout = styled.div`
  transition: all 0.1s;
  opacity: ${(props) => (props.isLoading ? 0.9 : 1)};

  .bottom_tools {
    display: flex;
    font-family: "roboto";
  }

  .bottom_tools .label,
  .bottom_tools .btn {
    margin-right: 5px;
  }
`;

export default class WaterMarketChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      data: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    const id = this.props.zoneId;
    const type = this.props.marketType;

    if (this.props.callBack) {
      this.props.callBack({});
    }
    this.setState({ isLoading: true }, () => {
      api_water_broker(id, type)
        .then((res) => {
          let content = res.data.content;

          let title = content.zoneName;

          let price = content.price;

          // Prepare Data for MultiFunctional Chart
          let priceSeries = {
            name: "PRICE",
            data: price.map((ele) => [Date.parse(ele["date"]), ele["price"]]),
            color: COLORMAP.minor,
            yAxis: 0,
            marker: {
              enabled: true,
            },
          };
          let volumeSeries = {
            name: "VOLUME",
            data: price.map((ele) => [Date.parse(ele["date"]), ele["volume"]]),
            type: "column",
            color: "rgb(203, 175, 135)",
            yAxis: 1,
          };
          // 1
          let lineSeries = [volumeSeries, priceSeries];
          // 2
          let seasonal = generateSeasonal(
            price.map((ele) => [ele["date"], ele["price"]]),
            false,
            7
          );
          // 3
          let tableData = {
            name: content.zoneName,
            data: price.slice().sort().reverse(),
            columns: ["date", "volume", "price"].map((key) => {
              return { Header: key.toUpperCase(), accessor: key };
            }),
          };
          let data = {
            line: lineSeries,
            seasonal: seasonal,
            table: tableData,
            monthly: content.monthly,
            weekly: content.weekly,
            transactions: content.transactions,
          };
          // Calculate Price Change
          let change = calculateChange(price.map((ele) => [ele["date"], ele["price"]]));
          if (this.props.callBack) {
            this.props.callBack(content);
          }
          this.setState({
            ...this.state,
            title,
            data,
            change,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            ...this.state,
            title: "No Data",
            data: {},
            change: {},
            isLoading: false,
          });
          console.log(error);
        });
    });
  }

  render() {
    const type = this.props.marketType;

    const toolbarItmes = [
      {
        label: "Download Monthly Data",
        filename: (this.props.zoneName || "data") + "_monthly.csv",
        headers: ["date", "price", "volume"],
        data: this.state.data.monthly || [],
      },
      {
        label: "Download Weekly Data",
        filename: (this.props.zoneName || "data") + "_weekly.csv",
        headers: ["date", "price", "volume"],
        data: this.state.data.weekly || [],
      },
      {
        label: "Download Transactions",
        filename: (this.props.zoneName || "data") + "_transactions.csv",
        headers:
          type === "allc"
            ? ["id", "date", "sort", "price", "volume", "not_current_year"]
            : ["id", "mtime", "sortdate", "agreed", "alloc_clause", "price", "volume", "licence_security", "zone", "shortdate", "chartdate", "not_current_year"],
        data: this.state.data.transactions || [],
      },
    ];

    return (
      <div>
        {this.state.isLoading ? (
          <LoadingBox />
        ) : (
          <ChartLayout isLoading={this.state.isLoading}>
            <PriceChangeCard change={this.state.change || {}} />
            <MultiViewChart data={this.state.data} height={350} hotReload={true} tableSize={10} />
            <hr />
            <div className="bottom_tools">
              {toolbarItmes.map((item, idx) => {
                return (
                  <div className="btn" key={idx}>
                    <Button>
                      <CSVLink className="DlBtn" data={item.data} filename={item.filename || "data.csv"} headers={item.headers}>
                        <i className="fas fa-download"></i> {item.label}
                      </CSVLink>
                    </Button>
                  </div>
                );
              })}
            </div>
          </ChartLayout>
        )}
      </div>
    );
  }
}
