import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Button, ButtonGroup, Container, Stack, Typography, colors } from "@mui/material";

import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { AddCircleOutlineIcon, DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import { OutlinedTagCollections } from "components/Tags/OutlinedTag";
import Text, { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";

import _ from "lodash";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { dialogReducerActions } from "redux/dialog";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import CycleManagementEditPanel from "screens/Aquaculture/components/CycleManager/CycleEditPanel";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";

const PondCycleManager = ({ pond, farm }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [cycle, setCycle] = useState(null);
  const [cycleToDelete, setCycleToDelete] = useState(null);

  useEffect(() => {
    if (cycle) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [cycle]);

  const dispatch = useDispatch();

  const cycleManagementStore = AppSelectors.cycleStore();
  const cycles = _.orderBy(cycleManagementStore.cycles[pond.id] || [], "start_date", "desc");
  const latestCycle = _.first(cycles);

  const Actions = {
    onLoadCycles: () => {
      cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm?.farm_id);
    },
    onEnterEdit: (cycle) => {
      // check if there's any active cycles
      const activeCycles = cycles.filter((ele) => !ele.ended);
      if (_.isEmpty(cycle) && activeCycles.length > 0) {
        alertsActions.addError(dispatch, {
          content: "You must end current Active cycle before you can create a new one",
        });
        return;
      }
      setCycle(cycle);
    },
    onExitEdit: () => {
      setShowEdit(false);
      setCycle(null);
    },
    onDeleteCycleConfirm: (cycle) => {
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.CYCLE_DELETE_CONFIRM });
      setCycleToDelete(cycle);
    },
    onDeleteCycle: async () => {
      if (cycleToDelete) {
        const cycle = cycleToDelete;
        await PondManagerServices.deleteCycle(pond.id, cycle.id);
        alertsActions.addInfo(dispatch, { content: "Cycle is removed" });
        await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm.farm_id);
      }
    },
  };

  return (
    <Box sx={PondManagerLayoutStyles.rightPanel}>
      <CommonDialog id={CommonDialogIds.CYCLE_DELETE_CONFIRM} title={getText("interface.alert.alert-are-you-sure")} action={Actions.onDeleteCycle} />
      <Stack direction="row" justifyContent="space-between">
        <Box>
          {!showEdit && farm?.access_level_write && (
            <Button variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={() => Actions.onEnterEdit({})}>
              <Text>interface.actions.create</Text>
            </Button>
          )}
        </Box>
      </Stack>

      {showEdit && <CycleManagementEditPanel {...{ cycle, pond, farm, onExit: Actions.onExitEdit }} />}

      {!showEdit &&
        (cycles.length === 0 ? (
          <Box my={2}>
            <VerticalAlertBoxCollections.NoCycle />
          </Box>
        ) : (
          <Box>
            <Container>
              <Timeline position="left">
                {cycles.map((ele, idx) => {
                  const ended = ele.ended;
                  return (
                    <TimelineItem
                      key={idx}
                      sx={{
                        border: "2px solid transparent",
                        borderRadius: 1,
                        "&:hover": {
                          border: "2px solid #eee",
                          background: "#eee",
                          "& .button-group": { visibility: "visible" },
                        },
                      }}
                    >
                      <TimelineOppositeContent color="text.secondary">
                        <Box>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Box>
                              {Formatters.toDateString(ele.start_date)} ~{" "}
                              {ended ? (
                                Formatters.toDateString(ele.end_date)
                              ) : (
                                <span
                                  style={{
                                    color: colors.green[600],
                                    fontWeight: 800,
                                  }}
                                >
                                  CURRENT
                                </span>
                              )}
                            </Box>
                            {farm?.access_level_write && (
                              <ButtonGroup size="small" className="button-group" sx={{ visibility: "hidden" }}>
                                <Button color="primary" startIcon={<EditIcon />} onClick={() => Actions.onEnterEdit(ele)}>
                                  <Text>interface.actions.edit</Text>
                                </Button>
                                <Button color="error" startIcon={<DeleteIcon />} onClick={() => Actions.onDeleteCycleConfirm(ele)}>
                                  <Text>interface.actions.delete</Text>
                                </Button>
                              </ButtonGroup>
                            )}
                          </Stack>
                        </Box>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot variant={idx > 0 ? "outlined" : "filled"} />
                        {idx < cycles.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="body1">
                          <Text>interface.general.cycle</Text>: <strong>{ele.label}</strong>
                        </Typography>
                        <Box>{ele.ended ? <OutlinedTagCollections.CycleInactiveTag /> : <OutlinedTagCollections.CycleActiveTag />}</Box>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Container>
          </Box>
        ))}
    </Box>
  );
};

export default PondCycleManager;
