import AuthServices from "api/authServices";
import { UserServies } from "api/userServices";
import AppLocalStorage, { KEYMAPPING } from "helpers/LocalStorage";
import { getHomepagePath } from "index/AppIndex";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { ROUTE_EMAIL_NOT_VERIFIED, ROUTE_LOGIN } from "routes/paths";
import { Roles } from "config";
import UserRoles from "constants/UserRoles";

const storage = new AppLocalStorage();

const initState = {
  userid: null,
  firstname: null,
  lastname: null,
  token: null,
  role: null,
  homepage: null,
  loginError: "",
  loginSucceed: false,
  isValid: false,
  isInternal: false,
};

export const userReducerTypes = {
  UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SIGNOUT: "SIGNOUT",
  VALIDATE_SESSION: "VALIDATE_SESSION",
};

const userReducers = (state = initState, action) => {
  switch (action.type) {
    case userReducerTypes.UPDATE_USER_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case userReducerTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginSucceed: true,
      };
    case userReducerTypes.LOGIN_FAILED:
      const { error } = action.payload;
      return {
        ...state,
        loginError: error,
      };
    case userReducerTypes.VALIDATE_SESSION:
      return {
        ...state,
        isValid: true,
      };
    default:
      return state;
  }
};

export const userActions = {
  /**
   *
   * Few thing to validate when Main interface is initiated
   * - is Token not expired
   * - is Token in localstorage not tempered
   * - is Role in localstorage not tempered
   * - is userId in localstorage not tempered
   *
   * @param {*} dispatch
   */
  validate: async (dispatch) => {
    const userToken = storage.get(KEYMAPPING.TOKEN);
    const userId = storage.get(KEYMAPPING.USERID);
    const userRole = storage.get(KEYMAPPING.ROLE);
    let userProfile = {};
    let validated = false;
    if (userToken) {
      try {
        const decoded = jwt_decode(userToken);
        const expired = decoded.exp * 1000;
        const tokenUserId = decoded.sub;
        const tokenValid = moment(expired) - moment() > 0;
        const userValid = userId === tokenUserId;
        userProfile = await UserServies.getProfile();
        const roleValid = userProfile.role === userRole;
        const emailVerified = userProfile.email_verified;
        if (!emailVerified) {
          window.location.replace(`${ROUTE_EMAIL_NOT_VERIFIED}?requestId=${userProfile.user_id}`);
          return;
        }
        if (tokenValid && userValid && roleValid) {
          validated = true;
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (validated) {
      const userProfileExtended = {
        ...userProfile,
        isInternal: userProfile?.role === UserRoles.INTERNAL,
      };
      dispatch({ type: userReducerTypes.VALIDATE_SESSION });
      dispatch({
        type: userReducerTypes.UPDATE_USER_EMAIL,
        payload: userProfileExtended,
      });
    } else {
      console.log("** SESSION VALIDATION FAILED **");
      storage.cleanup();
      window.location.replace(ROUTE_LOGIN);
    }
  },
  /**
   * Login
   *
   * @param {*} dispatch
   * @param {*} payload
   * @returns
   */
  login: async (dispatch, payload) => {
    dispatch({ type: userReducerTypes.LOGIN_FAILED, payload: { error: "" } });
    const { email, password, longLogin, microsoftIdToken, googleToken, oauthStep } = payload;

    let rsp = null;

    if (microsoftIdToken) {
      rsp = await AuthServices.oauthVerify({
        idToken: microsoftIdToken,
        platform: "microsoft",
        role: UserRoles.SHRIMPL_USER,
        step: oauthStep,
      });
    } else if (googleToken) {
      rsp = await AuthServices.oauthVerify({
        idToken: googleToken,
        platform: "google",
        role: UserRoles.SHRIMPL_USER,
        step: oauthStep,
      });
    } else {
      rsp = await AuthServices.login({ email, password, longLogin });
    }

    if (rsp.error) {
      dispatch({
        type: userReducerTypes.LOGIN_FAILED,
        payload: { error: rsp.error },
      });
      return;
    }
    if (rsp.response === "login_successful") {
      const { access_token, user_profile } = rsp;
      const { user_id, firstname, email, role, emailVerified } = user_profile;
      storage.save(KEYMAPPING.USERID, user_id);
      storage.save(KEYMAPPING.TOKEN, access_token);
      storage.save(KEYMAPPING.EMAIL, email);
      storage.save(KEYMAPPING.FIRSTNAME, firstname);
      storage.save(KEYMAPPING.ROLE, role);
      if (emailVerified) {
        dispatch({ type: userReducerTypes.LOGIN_SUCCESS });
        setTimeout(() => {
          window.location.replace(getHomepagePath(role));
        }, 1000);
      } else {
        setTimeout(() => {
          window.location.replace(`${ROUTE_EMAIL_NOT_VERIFIED}?requestId=${user_id}`);
        }, 100);
      }
    }
  },
  resendConfirmationEmail: async (dispatch, payload) => {
    const { requestId } = payload;
    const rsp = await AuthServices.resendEmailConfirmation(requestId);
    console.log(rsp);
  },
  forgotPassword: async (dispatch, payload) => {
    const { email } = payload;
    const rsp = await AuthServices.forgotPassword({ email });
    console.log(rsp);
  },
  resetPassword: async (dispatch, payload) => {
    const rsp = await AuthServices.resetPassword(payload);
    console.log(rsp);
  },
};

export default userReducers;
