import React, { useState } from "react";
import { GOOGLE_MAP_KEY } from "secrets";
import { Box, Button, colors } from "@mui/material";
import { RestartAltIcon } from "components/Icons/MaterialIcons";
import * as turf from "@turf/turf";
import GoogleMapReact from "google-map-react";
import { overlays } from "json/googlemap_overlays";
import Text from "components/text/Text";

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.HYBRID,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const MapShapeSelector = ({ center, handlePointAdded, handlePolygonAdded, initPolygon, coverLayer, coverLayerStyle, height = 500 }) => {
  const hasValidPolygon = initPolygon && initPolygon.length === 1;

  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [shape, setShape] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);

  const handleApiLoaded = async (map, maps) => {
    // :: add cover layer (other ponds)
    if (coverLayer) {
      // :: load data ::
      map.data.addGeoJson(coverLayer, {
        idPropertyName: "coverLayer",
      });
      // :: set style ::
      map.data.setStyle(function (feature) {
        var pond_group = feature.getProperty("group");
        return {
          fillColor: coverLayerStyle?.colorMapping?.[pond_group]?.color || "#333",
          strokeColor: coverLayerStyle?.colorMapping?.[pond_group]?.color || "#333",
          fillOpacity: 0.5,
          strokeWeight: 0,
        };
      });
    }

    maps.Polygon.prototype.enableCoordinatesChangedEvent = function () {
      var me = this,
        isBeingDragged = false,
        triggerCoordinatesChanged = function () {
          // Broadcast normalized event
          google.maps.event.trigger(me, "coordinates_changed");
        };

      // If  the overlay is being dragged, set_at gets called repeatedly,
      // so either we can debounce that or igore while dragging,
      // ignoring is more efficient
      google.maps.event.addListener(me, "dragstart", function () {
        isBeingDragged = true;
      });

      // If the overlay is dragged
      google.maps.event.addListener(me, "dragend", function () {
        triggerCoordinatesChanged();
        isBeingDragged = false;
      });

      // Or vertices are added to any of the possible paths, or deleted
      var paths = me.getPaths();
      paths.forEach(function (path, i) {
        google.maps.event.addListener(path, "insert_at", function () {
          triggerCoordinatesChanged();
        });
        google.maps.event.addListener(path, "set_at", function () {
          if (!isBeingDragged) {
            triggerCoordinatesChanged();
          }
        });
        google.maps.event.addListener(path, "remove_at", function () {
          triggerCoordinatesChanged();
        });
      });
    };

    maps.Polygon.prototype.convertToGeoJson = function () {
      let geoJSONGeometry = {
        type: "Polygon",
        coordinates: [],
      };
      let paths = this.getPaths().getArray();
      for (let path of paths) {
        let pathArray = [];
        let points = path.getArray();
        let firstPoint = false;
        for (let point of points) {
          if (firstPoint === false) {
            firstPoint = point;
          }
          pathArray.push([point.lng(), point.lat()]);
        }
        pathArray.push([firstPoint.lng(), firstPoint.lat()]);
        geoJSONGeometry.coordinates.push(pathArray);
      }
      const geoJSON = {
        type: "Feature",
        geometry: geoJSONGeometry,
        properties: {},
      };
      return geoJSON;
    };

    /** :: Init Drawing Manager :: */
    const drawing = await maps.importLibrary("drawing");
    const drawingManager = new drawing.DrawingManager({
      drawingMode: drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
        drawingModes: [drawing.OverlayType.POLYGON],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      polygonOptions: {
        fillColor: colors.orange[600],
        strokeColor: colors.orange[600],
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        draggable: true,
      },
    });
    drawingManager.setMap(map);

    /** :: Drawing Manager - After Shape Completed :: */
    maps.event.addListener(drawingManager, "polygoncomplete", (newPolygon) => {
      drawingManager.setOptions({
        drawingMode: null,
        drawingControl: false,
      });
      Actions.updatePolygon(newPolygon.convertToGeoJson());
      newPolygon.enableCoordinatesChangedEvent();
      maps.event.addListener(newPolygon, "coordinates_changed", () => {
        const newPolygonGeoJson = newPolygon.convertToGeoJson();
        Actions.updatePolygon(newPolygonGeoJson);
      });
      setShape(newPolygon);
    });

    setDrawingManager(drawingManager);

    // here if in "EDIT" mode
    if (hasValidPolygon) {
      const newShapeCoords = initPolygon[0].geometry.coordinates[0].map((ele) => ({
        lat: ele[1],
        lng: ele[0],
      }));
      // Construct the polygon.
      const newShape = new maps.Polygon({
        paths: newShapeCoords,
        fillColor: "#FFF",
        strokeColor: "#FFF",
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        draggable: true,
      });
      newShape.setMap(map);
      newShape.enableCoordinatesChangedEvent();
      maps.event.addListener(newShape, "coordinates_changed", () => {
        const newPolygonGeoJson = newShape.convertToGeoJson();
        Actions.updatePolygon(newPolygonGeoJson);
      });
      drawingManager.setOptions({
        drawingMode: null,
        drawingControl: false,
      });
      let bounds = new maps.LatLngBounds();
      newShape.getPath().forEach((element, index) => bounds.extend(element));
      map.fitBounds(bounds);
      setShape(newShape);
    }

    // :: append overlays ::
    let historicalOverlay;
    overlays.forEach((ol) => {
      let imageBounds = ol.bounds;
      historicalOverlay = new google.maps.GroundOverlay(ol.url, imageBounds);
      historicalOverlay.setMap(map);
    });

    setMap(map);
    setMaps(maps);
  };

  const Actions = {
    updatePolygon: (newPolygonGeoJson) => {
      const turfPolygon = turf.polygon(newPolygonGeoJson.geometry.coordinates);
      const turfArea = turf.area(turfPolygon) / 10000; // ha
      const turfCentroid = turf.centroid(turfPolygon).geometry.coordinates;
      handlePointAdded &&
        handlePointAdded({
          lng: turfCentroid[0],
          lat: turfCentroid[1],
        });
      handlePolygonAdded &&
        handlePolygonAdded({
          features: [newPolygonGeoJson],
          area: turfArea,
          centroid: {
            lng: turfCentroid[0],
            lat: turfCentroid[1],
          },
        });
    },
  };

  const MapActions = {
    redraw: () => {
      if (drawingManager && map) {
        shape && shape.setMap(null);
        drawingManager.setOptions({
          drawingControl: true,
          drawingMode: "polygon",
        });
      }
    },
  };

  return (
    <Box width={"100%"} height={height} position={"relative"}>
      <Box borderRadius={1} position={"absolute"} left={5} top={5} zIndex={99} p={1} bgcolor={"#ffffffd0"}>
        <Button variant="outlined" size="small" onClick={MapActions.redraw} startIcon={<RestartAltIcon />}>
          <Text>interface.actions.redraw</Text>
        </Button>
      </Box>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAP_KEY,
          libraries: "drawing",
        }}
        defaultCenter={[center[1], center[0]]}
        defaultZoom={zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </Box>
  );
};

export default MapShapeSelector;
