import { useEffect } from "react";
import styled from "styled-components";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "layout/SideBar";
import { appComponents } from "index/AppIndex";
import { useDispatch } from "react-redux";
import { userActions } from "redux/user";
import { AppSelectors } from "redux/AppReducers";
import NavBar from "layout/NavBar";
import _ from "lodash";
import { ROUTE_HOME } from "routes/paths";
import { getLocalStorageItem } from "helpers/LocalStorage";
import { FLOATING_MESSAGE_CHAT_KEY } from "components/ChatBoard/ChatBoard";
import { isMobile } from "themes/helpers";

const mobileMode = isMobile();

const InterfaceLayout = mobileMode
  ? styled.div`
      .app_wrapper {
        width: 100vw;
        height: 100lvh;
        display: grid;
        grid-template-columns: 100dvw;
        grid-template-rows: calc(100% - 80px) 80px;
        grid-template-areas:
          /* "navbar" */
          "mainboard"
          "sidebar";
      }
    `
  : styled.div`
      .app_wrapper {
        width: 100vw;
        height: 100lvh;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: 45px calc(100% - 45px);
        grid-template-areas:
          "sidebar navbar"
          "sidebar mainboard";
      }
    `;

const MainBoardLayout = styled.div`
  grid-area: mainboard;
  overflow: auto;
`;

const Interface = ({ title, requiredRoles, preload }) => {
  const key = getLocalStorageItem(FLOATING_MESSAGE_CHAT_KEY);
  const dispatch = useDispatch();

  const userStore = AppSelectors.userStore();
  const { isValid } = userStore;

  useEffect(() => {
    userActions.validate(dispatch);
    if (preload) {
      preload();
    }
  }, []);

  if (!isValid) return "";
  if (requiredRoles && !_.includes(requiredRoles, userStore.role)) {
    return <Navigate to={ROUTE_HOME} />;
  }

  return (
    <InterfaceLayout>
      <div className="app_wrapper">
        {/* {key ? <FloatingMessageBoard key={key} chatId={key} /> : null} */}
        {/* <FloatingMessageBoard key={key} chatId={key} /> */}
        <SideBar data={appComponents} />
        {!mobileMode && <NavBar title={title} />}
        <MainBoardLayout>
          <Outlet />
        </MainBoardLayout>
      </div>
    </InterfaceLayout>
  );
};

export default Interface;
