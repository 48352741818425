import { Box, Button, Grid, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import MapShapeSelector from "components/map/MapShapeSelector";
import { getText } from "components/text/Text";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";

const newFeature = {
  label: "",
  area: 0,
};

const FeatureEdit = ({ feature, postEditAction }) => {
  const [featureProperties, setFeatureProperties] = useState(feature?.properties);
  const [featureGeometry, setFeatureGeometry] = useState(feature?.geometry);

  const dispatch = useDispatch();

  const Actions = {
    onSubmit: async (values) => {
      const newFeature = {
        type: "Feature",
        properties: {
          ...featureProperties,
          ...values,
        },
        geometry: featureGeometry,
      };
      await PondManagerServices.updateAquaExplorerFeature(newFeature);
      postEditAction && postEditAction();
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
    },
    onDelete: async () => {
      const pondid = featureProperties?.id;
      await PondManagerServices.deleteAquaExplorerFeature({ pondid });
      postEditAction && postEditAction();
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
    },
    onMapAddedPolygon: (props) => {
      const newArea = props.area;
      const newLng = props.centroid.lng;
      const newLat = props.centroid.lat;
      setFeatureProperties({
        ...featureProperties,
        area: newArea,
        lng: newLng,
        lat: newLat,
      });
      setFeatureGeometry(props.features[0].geometry);
    },
  };

  const propertiesFormValues = featureProperties || newFeature;
  const initCoordinate = [featureProperties?.lng, featureProperties?.lat];
  const initPolygon = [feature];

  const formFields = [
    {
      name: "label",
      type: FormFieldTypes.text,
      label: "Label",
      span: 2,
      options: {
        required: true,
      },
    },
    {
      name: "group",
      type: FormFieldTypes.text,
      label: "Group",
      span: 2,
    },
    {
      name: "area",
      type: FormFieldTypes.numeric,
      label: "Area",
      span: 2,
    },
    {
      name: "lng",
      type: FormFieldTypes.text,
      label: "Lng",
      span: 1,
    },
    {
      name: "lat",
      type: FormFieldTypes.text,
      label: "Lat",
      span: 1,
    },
  ];

  return (
    <Grid container spacing={1} padding={1}>
      <Grid item xs={12} lg={4}>
        <Typography fontWeight={800}>Properties:</Typography>
        <CommonForm columns={2} initialValues={propertiesFormValues} fields={formFields} onSubmit={Actions.onSubmit} />
        <Box mt={1}>
          <Button variant="contained" color="error" fullWidth size="large" onClick={Actions.onDelete}>
            DELETE
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box borderRadius={2} overflow="hidden">
          <MapShapeSelector center={initCoordinate} initPolygon={initPolygon} handlePolygonAdded={Actions.onMapAddedPolygon} height={800} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FeatureEdit;
