import { Box, Stack, Typography, colors } from "@mui/material";
import { getText } from "components/text/Text";
import palette from "themes/palette";
import _ from "lodash";
import Formatters from "helpers/Formatters";
import NumberFormat from "react-number-format";

const PondMapInfoBox = ({ pond }) => {
  return (
    <Box
      borderRadius={1}
      bgcolor={pond?.isActive ? colors.green[50] : colors.grey[50]}
      sx={{
        minWidth: 200,
        position: "absolute",
        transform: "translate(-50%, -100%)",
      }}
    >
      <Stack gap={1}>
        <Stack borderRadius={1} direction="row" justifyContent={"space-between"} alignItems={"center"} p={1}>
          <Box>
            <Typography fontSize={14} fontWeight={"bold"}>
              {pond?.name}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={11} color={pond?.isActive ? colors.green[600] : colors.red[600]}>
              {pond?.isActive ? getText("interface.general.active") : getText("interface.general.inactive")}
            </Typography>
          </Box>
        </Stack>
        <Box p={1}>
          <Typography fontSize={11} color={colors.grey[700]}>
            {getText("interface.general.group")}: <strong>{pond.group}</strong>
          </Typography>
          <Typography fontSize={11} color={colors.grey[700]}>
            {getText("interface.general.category")}: <strong>{pond.category}</strong>
          </Typography>
          <Typography fontSize={11} color={colors.grey[700]}>
            {getText("interface.general.pond-area")}:{" "}
            <strong>
              <NumberFormat displayType="text" value={pond.area} decimalScale={1} suffix=" ha." />
            </strong>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default PondMapInfoBox;
